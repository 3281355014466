import React from "react";
import { sizeChart } from "../js/sizechart.js";

////// OLD COMPONENT FOR OLD PAGES


const ShopifyDiv = (props) => {

    return (
        <div id={`product-component-${props.id}`}>
            <div id="sizechartbutton" onClick={sizeChart}>
                <p>Size chart</p>
            </div>

        </div>
    )
}

export default ShopifyDiv;