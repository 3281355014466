import React from "react";

import { SS23 } from "../../../../js/database/database-ss23.js";

import ProductsContainer from "../../../../components/containers/ProductsContainer.js";
import { SwiperSlide } from "swiper/react";

const GatheredSleeveShirtWhite = () => {
  return (
    <ProductsContainer
      pageTitle={SS23.tops.GatheredSleeveShirtWhite.itemName}
      name={SS23.tops.GatheredSleeveShirtWhite.itemName}
      price={SS23.tops.GatheredSleeveShirtWhite.itemPrice}
      fabric={SS23.tops.GatheredSleeveShirtWhite.itemFabric}
      src={SS23.tops.GatheredSleeveShirtWhite.sizeChart}
      divId={SS23.tops.GatheredSleeveShirtWhite.shopifyDivId}
      mainId={SS23.tops.GatheredSleeveShirtWhite.shopifyId}
      buttonShouldHide={true}
    >
      {React.Children.toArray(
        SS23.tops.GatheredSleeveShirtWhite.pictures.map(({ srcJpeg }) => (
          <SwiperSlide>
            <img
              src={srcJpeg}
              alt={SS23.tops.GatheredSleeveShirtWhite.itemName}
              width={SS23.imgWidth}
              height={SS23.imgHeight}
              loading={SS23.loading}
            />
          </SwiperSlide>
        ))
      )}
    </ProductsContainer>
  );
};

export default GatheredSleeveShirtWhite;
