import React from "react";

import { AW23 } from "../../../../js/database/database-aw23-eshop.js";

import ProductsContainer from "../../../../components/containers/ProductsContainer.js";
import { SwiperSlide } from "swiper/react";

const GrippedArmJacket = () => {
  return (
    <ProductsContainer
      pageTitle={AW23.jackets.GrippedArmJacket.itemName}
      name={AW23.jackets.GrippedArmJacket.itemName}
      price={AW23.jackets.GrippedArmJacket.itemPrice}
      fabric={AW23.jackets.GrippedArmJacket.itemFabric}
      src={AW23.jackets.GrippedArmJacket.sizeChart}
      divId={AW23.jackets.GrippedArmJacket.shopifyDivId}
      mainId={AW23.jackets.GrippedArmJacket.shopifyId}
      buttonShouldHide={true}
    >
      {React.Children.toArray(
        AW23.jackets.GrippedArmJacket.pictures.map(({ srcJpeg }) => (
          <SwiperSlide>
            <img
              src={srcJpeg}
              alt={AW23.jackets.GrippedArmJacket.itemName}
              width={AW23.imgWidth}
              height={AW23.imgHeight}
              loading={AW23.loading}
            />
          </SwiperSlide>
        ))
      )}
    </ProductsContainer>
  );
};

export default GrippedArmJacket;
