
import React from "react";

import { NavLink } from "react-router-dom"

class Logo extends React.Component {

    render() {
        return (
            <div>
                <NavLink className="link" to="/">
                    {/*                     <img className="logo" src={'../content/other/ximonleelogo_white.png'} alt="Ximon Lee logo" />
 */}                    <img className="logo" src={'../content/other/XIMONLEE-logo-2023-low-white.png'} alt="Ximon Lee logo" />
                </NavLink>
            </div>
        )
    }
}

export default Logo;



