import React from "react";

import aw24CollectionImages from "../js/database/database-aw24-collection";

import setBodyColor from "../js/setBodyColor";

import { SwiperSlide } from "swiper/react";

import MainPagesContainer from "../components/containers/MainPagesContainer";

const alt = "AW24 collection";
const imgWidth = 884;
const imgHeight = 1250;

const Current = (props) => {
  setBodyColor({ color: "#222222" });
  // setFontColor({ color: "#5e5e5e" });

  const styles = {
    color: "#dddddd",
  };

  const handlePdfDownload = () => {
    const pdfUrl = "../../content/pdf/XIMONLEE_AW24_LOOKBOOK.pdf";
    const anchor = document.createElement("a");
    anchor.href = pdfUrl;
    anchor.download = "XIMONLEE_AW24_LOOKBOOK.pdf";
    anchor.click();
    console.log("clicked");
  };

  return (
    <MainPagesContainer
      pageTitle={alt}
      //title="Video"
      //pdf="pdf"
      // src={"../content/videos/Ximon_Lee_SS24_video_long.mp4"}
      style={styles.color}
      shouldHide={true}
      PDFshouldHide={false}
      handlePdfDownload={handlePdfDownload}
    >
      {React.Children.toArray(
        aw24CollectionImages.map(({ srcJpeg, srcWebp, loading }) => (
          <SwiperSlide>
            <picture>
              <source srcSet={srcWebp} type="image/webp" />
              <img
                src={srcJpeg}
                width={imgWidth}
                height={imgHeight}
                alt={alt}
                loading="eager"
              />
            </picture>
          </SwiperSlide>
        ))
      )}
    </MainPagesContainer>
  );
};

export default Current;
