const ss22CollectionImages = [
    {
        key: 1,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_1.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_1.webp",
        loading: "eager"
    },
    {
        key: 2,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_2.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_2.webp",
        loading: "eager"
    },
    {
        key: 3,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_3.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_3.webp",
        loading: "eager"
    },
    {
        key: 4,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_4.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_4.webp",
        loading: "lazy"
    },
    {
        key: 5,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_5.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_5.webp",
        loading: "lazy"
    },
    {
        key: 6,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_6.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_6.webp",
        loading: "lazy"
    },
    {
        key: 7,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_7.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_7.webp",
        loading: "lazy"
    },
    {
        key: 8,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_8.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_8.webp",
        loading: "lazy"
    },
    {
        key: 9,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_9.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_9.webp",
        loading: "lazy"
    },
    {
        key: 10,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_10.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_10.webp",
        loading: "lazy"
    },
    {
        key: 11,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_11.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_11.webp",
        loading: "lazy"
    },
    {
        key: 12,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_12.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_12.webp",
        loading: "lazy"
    },
    {
        key: 13,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_13.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_13.webp",
        loading: "lazy"
    },
    {
        key: 14,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_14.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_14.webp",
        loading: "lazy"
    },
    {
        key: 15,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_15.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_15.webp",
        loading: "lazy"
    },
    {
        key: 16,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_16.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_16.webp",
        loading: "lazy"
    },
    {
        key: 17,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_17.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_17.webp",
        loading: "lazy"
    },
    {
        key: 18,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_18.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_18.webp",
        loading: "lazy"
    },
    {
        key: 19,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_19.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_19.webp",
        loading: "lazy"
    },
    {
        key: 20,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_20.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_20.webp",
        loading: "lazy"
    },
    {
        key: 21,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_21.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_21.webp",
        loading: "lazy"
    },
    {
        key: 22,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_22.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_22.webp",
        loading: "lazy"
    },
    {
        key: 23,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_23.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_23.webp",
        loading: "lazy"
    },
    {
        key: 24,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_24.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_24.webp",
        loading: "lazy"
    },
    {
        key: 25,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_25.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_25.webp",
        loading: "lazy"
    },
    {
        key: 26,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_26.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_26.webp",
        loading: "lazy"
    },
    {
        key: 27,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_27.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_27.webp",
        loading: "lazy"
    },
    {
        key: 28,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_28.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_28.webp",
        loading: "lazy"
    },
    {
        key: 29,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_29.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_29.webp",
        loading: "lazy"
    },
    {
        key: 30,
        srcJpeg: "../../content/photos/collections/SS22/XL_SS22_LOOK_30.jpg",
        srcWebp: "../../content/photos/collections/SS22/webp/XL_SS22_LOOK_30.webp",
        loading: "lazy"
    },
];



export default ss22CollectionImages

