import parse from 'html-react-parser';

export const AW22 = {

    alt: " ",
    imgWidth: 1000,
    imgHeight: 1500,
    loading: "eager",

    jackets: {
        CharcoalLizardBlazer: {
            order: 1,
            itemName: parse('<s>Charcoal Lizard blazer</s>'),
            itemPrice: parse('<s>790€</s> 553€'),
            itemFabric: "body · cotton 100%/lining · polyester 50%  viscose 50%",
            sizeChart: "../content/photos/eshop/AW22/01_XL_AW22_Charcoal_Lizard_Blazer/01_XL_AW22_Charcoal_Lizard_Blazer_Size_Chart.jpg",

            soldOut: "Sold Out",


            shopifyDivId: "1667310605507",
            shopifyId: "8018449694984",
            link: "/charcoal-lizard-blazer",
            picture01: "../content/photos/eshop/AW22/01_XL_AW22_Charcoal_Lizard_Blazer/01_XL_AW22_Charcoal_Lizard_Blazer_01.jpg",
            picture02: "../content/photos/eshop/AW22/01_XL_AW22_Charcoal_Lizard_Blazer/01_XL_AW22_Charcoal_Lizard_Blazer_02.jpg",
            /* picture03: "../content/photos/eshop/AW22/01_XL_AW22_Charcoal_Lizard_Blazer/01_XL_AW22_Charcoal_Lizard_Blazer_03.jpg",
            picture04: "../content/photos/eshop/AW22/01_XL_AW22_Charcoal_Lizard_Blazer/01_XL_AW22_Charcoal_Lizard_Blazer_04.jpg",
            picture05: "../content/photos/eshop/AW22/01_XL_AW22_Charcoal_Lizard_Blazer/01_XL_AW22_Charcoal_Lizard_Blazer_05.jpg",
            picture06: "../content/photos/eshop/AW22/01_XL_AW22_Charcoal_Lizard_Blazer/01_XL_AW22_Charcoal_Lizard_Blazer_06.jpg", */

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/01_XL_AW22_Charcoal_Lizard_Blazer/01_XL_AW22_Charcoal_Lizard_Blazer_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/01_XL_AW22_Charcoal_Lizard_Blazer/01_XL_AW22_Charcoal_Lizard_Blazer_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/01_XL_AW22_Charcoal_Lizard_Blazer/01_XL_AW22_Charcoal_Lizard_Blazer_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW22/01_XL_AW22_Charcoal_Lizard_Blazer/01_XL_AW22_Charcoal_Lizard_Blazer_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/AW22/01_XL_AW22_Charcoal_Lizard_Blazer/01_XL_AW22_Charcoal_Lizard_Blazer_05.jpg",
                    loading: "eager"
                },
                {
                    key: 6,
                    srcJpeg: "../content/photos/eshop/AW22/01_XL_AW22_Charcoal_Lizard_Blazer/01_XL_AW22_Charcoal_Lizard_Blazer_06.jpg",
                    loading: "eager"
                },
            ],
        },


        DropShoulderPUCoat: {
            order: 2,
            itemName: parse('<s>Drop Shoulder PU coat</s>'),
            itemPrice: parse('<s>1090€</s> 763€'),
            itemFabric: "body · polyurethane 100%/lining · polyester 100%",
            sizeChart: "../content/photos/eshop/AW22/02_XL_AW22_Drop_Shoulder_PU_Coat/02_XL_AW22_Drop_Shoulder_PU_Coat_Size_Chart.jpg",
            soldOut: "Sold Out",

            shopifyDivId: "1667315288430",
            shopifyId: "8018434031880",
            link: "/drop-shoulder-pu-coat",
            picture01: "../content/photos/eshop/AW22/02_XL_AW22_Drop_Shoulder_PU_Coat/02_XL_AW22_Drop_Shoulder_PU_Coat_01.jpg",
            picture02: "../content/photos/eshop/AW22/02_XL_AW22_Drop_Shoulder_PU_Coat/02_XL_AW22_Drop_Shoulder_PU_Coat_02.jpg",
            /*     picture03: "../content/photos/eshop/AW22/02_XL_AW22_Drop_Shoulder_PU_Coat/02_XL_AW22_Drop_Shoulder_PU_Coat_03.jpg",
                picture04: "../content/photos/eshop/AW22/02_XL_AW22_Drop_Shoulder_PU_Coat/02_XL_AW22_Drop_Shoulder_PU_Coat_04.jpg",
                picture05: "../content/photos/eshop/AW22/02_XL_AW22_Drop_Shoulder_PU_Coat/02_XL_AW22_Drop_Shoulder_PU_Coat_05.jpg", */

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/02_XL_AW22_Drop_Shoulder_PU_Coat/02_XL_AW22_Drop_Shoulder_PU_Coat_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/02_XL_AW22_Drop_Shoulder_PU_Coat/02_XL_AW22_Drop_Shoulder_PU_Coat_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/02_XL_AW22_Drop_Shoulder_PU_Coat/02_XL_AW22_Drop_Shoulder_PU_Coat_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW22/02_XL_AW22_Drop_Shoulder_PU_Coat/02_XL_AW22_Drop_Shoulder_PU_Coat_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/AW22/02_XL_AW22_Drop_Shoulder_PU_Coat/02_XL_AW22_Drop_Shoulder_PU_Coat_05.jpg",
                    loading: "eager"
                },

            ],

        },
        BomberWithRestrainedArms: {
            order: 3,
            itemName: "Bomber With Restrained Arms",
            itemPrice: parse('<s>730€</s> 400€'),
            itemFabric: "body · polyester 100%/lining · polyester 50%  viscose 50%",
            sizeChart: "../content/photos/eshop/AW22/03_XL_AW22_Bomber_With_Restrained_Arms/03_XL_AW22_Bomber_With_Restrained_Arms_Size_Chart.jpg",

            shopifyDivId: "1667315546956",
            shopifyId: "8018452218120",
            link: "/bomber-with-restrained-arms",
            picture01: "../content/photos/eshop/AW22/03_XL_AW22_Bomber_With_Restrained_Arms/03_XL_AW22_Bomber_With_Restrained_Arms_01.jpg",
            picture02: "../content/photos/eshop/AW22/03_XL_AW22_Bomber_With_Restrained_Arms/03_XL_AW22_Bomber_With_Restrained_Arms_02.jpg",
            /*   picture03: "../content/photos/eshop/AW22/03_XL_AW22_Bomber_With_Restrained_Arms/03_XL_AW22_Bomber_With_Restrained_Arms_03.jpg",
              picture04: "../content/photos/eshop/AW22/03_XL_AW22_Bomber_With_Restrained_Arms/03_XL_AW22_Bomber_With_Restrained_Arms_04.jpg",
              picture05: "../content/photos/eshop/AW22/03_XL_AW22_Bomber_With_Restrained_Arms/03_XL_AW22_Bomber_With_Restrained_Arms_05.jpg",
              picture06: "../content/photos/eshop/AW22/03_XL_AW22_Bomber_With_Restrained_Arms/03_XL_AW22_Bomber_With_Restrained_Arms_06.jpg", */

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/03_XL_AW22_Bomber_With_Restrained_Arms/03_XL_AW22_Bomber_With_Restrained_Arms_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/03_XL_AW22_Bomber_With_Restrained_Arms/03_XL_AW22_Bomber_With_Restrained_Arms_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/03_XL_AW22_Bomber_With_Restrained_Arms/03_XL_AW22_Bomber_With_Restrained_Arms_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW22/03_XL_AW22_Bomber_With_Restrained_Arms/03_XL_AW22_Bomber_With_Restrained_Arms_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/AW22/03_XL_AW22_Bomber_With_Restrained_Arms/03_XL_AW22_Bomber_With_Restrained_Arms_05.jpg",
                    loading: "eager"
                },
                {
                    key: 6,
                    srcJpeg: "../content/photos/eshop/AW22/03_XL_AW22_Bomber_With_Restrained_Arms/03_XL_AW22_Bomber_With_Restrained_Arms_06.jpg",
                    loading: "eager"
                },
            ],

        },
        BikerJacketWithArmStraps: {
            order: 7,
            itemName: parse('<s>Biker Jacket With Arm Straps</s>'),
            itemPrice: parse('<s>910€</s> 637€'),
            itemFabric: "body · sheepskin 100%/lining · polyester 50%  viscose 50%",
            sizeChart: "../content/photos/eshop/AW22/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps_Size_Chart.jpg",

            soldOut: true,

            shopifyDivId: "1667316788069",
            shopifyId: "8018453070088",
            link: "/biker-jacket-with-arm-straps",
            picture01: "../content/photos/eshop/AW22/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps_01.jpg",
            picture02: "../content/photos/eshop/AW22/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps_02.jpg",
            /* picture03: "../content/photos/eshop/AW22/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps_03.jpg",
            picture04: "../content/photos/eshop/AW22/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps_04.jpg",
            picture05: "../content/photos/eshop/AW22/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps_05.jpg", */

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW22/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/AW22/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps/07_XL_AW22_Biker_Jacket_With_Bondage_Arm_Straps_05.jpg",
                    loading: "eager"
                },
            ],
        },

        LeatherBomberWithRestrainedArms: {
            order: 9,
            itemName: parse('<s>Leather Bomber With Rest-rained Arms</s>'),
            itemPrice: parse('<s>835€</s> 595€'),
            itemFabric: "body · leather 100%/lining · polyester 50%  viscose 50%",
            sizeChart: "../content/photos/eshop/AW22/09_XL_AW22_Leather_Bomber_With_Restrained_Arms/09_XL_AW22_Leather_Bomber_With_Restrained_Arms_Size_Chart.jpg",
            soldOut: "Sold Out",

            shopifyDivId: "1667317447730",
            shopifyId: "8018450907400",
            link: "/leather-bomber-with-restrained-arms",
            picture01: "../content/photos/eshop/AW22/09_XL_AW22_Leather_Bomber_With_Restrained_Arms/09_XL_AW22_Leather_Bomber_With_Restrained_Arms_01.jpg",
            picture02: "../content/photos/eshop/AW22/09_XL_AW22_Leather_Bomber_With_Restrained_Arms/09_XL_AW22_Leather_Bomber_With_Restrained_Arms_02.jpg",
            picture03: "../content/photos/eshop/AW22/09_XL_AW22_Leather_Bomber_With_Restrained_Arms/09_XL_AW22_Leather_Bomber_With_Restrained_Arms_03.jpg",
            picture04: "../content/photos/eshop/AW22/09_XL_AW22_Leather_Bomber_With_Restrained_Arms/09_XL_AW22_Leather_Bomber_With_Restrained_Arms_04.jpg",
            picture05: "../content/photos/eshop/AW22/09_XL_AW22_Leather_Bomber_With_Restrained_Arms/09_XL_AW22_Leather_Bomber_With_Restrained_Arms_05.jpg",
            picture06: "../content/photos/eshop/AW22/09_XL_AW22_Leather_Bomber_With_Restrained_Arms/09_XL_AW22_Leather_Bomber_With_Restrained_Arms_06.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/09_XL_AW22_Leather_Bomber_With_Restrained_Arms/09_XL_AW22_Leather_Bomber_With_Restrained_Arms_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/09_XL_AW22_Leather_Bomber_With_Restrained_Arms/09_XL_AW22_Leather_Bomber_With_Restrained_Arms_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/09_XL_AW22_Leather_Bomber_With_Restrained_Arms/09_XL_AW22_Leather_Bomber_With_Restrained_Arms_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW22/09_XL_AW22_Leather_Bomber_With_Restrained_Arms/09_XL_AW22_Leather_Bomber_With_Restrained_Arms_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/AW22/09_XL_AW22_Leather_Bomber_With_Restrained_Arms/09_XL_AW22_Leather_Bomber_With_Restrained_Arms_05.jpg",
                    loading: "eager"
                },
                {
                    key: 6,
                    srcJpeg: "../content/photos/eshop/AW22/09_XL_AW22_Leather_Bomber_With_Restrained_Arms/09_XL_AW22_Leather_Bomber_With_Restrained_Arms_06.jpg",
                    loading: "eager"
                },
            ],
        },

        PanelledMacCoat: {
            order: 14,
            itemName: parse('<s>Panelled Mac coat</s>'),
            itemPrice: parse('<s>740€</s> 518€'),
            itemFabric: "body · cotton 100%/lining · polyester 50%  viscose 50%",
            sizeChart: "../content/photos/eshop/AW22/14_XL_AW22_Panelled_Mac_Coat/14_XL_AW22_Panelled_Mac_Coat_Size_Chart.jpg",
            soldOut: "Sold Out",

            shopifyDivId: "1667319620030",
            shopifyId: "8018433147144",
            link: "/panelled-mac-coat",
            picture01: "../content/photos/eshop/AW22/14_XL_AW22_Panelled_Mac_Coat/14_XL_AW22_Panelled_Mac_Coat_01.jpg",
            picture02: "../content/photos/eshop/AW22/14_XL_AW22_Panelled_Mac_Coat/14_XL_AW22_Panelled_Mac_Coat_02.jpg",
            picture03: "../content/photos/eshop/AW22/14_XL_AW22_Panelled_Mac_Coat/14_XL_AW22_Panelled_Mac_Coat_03.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/14_XL_AW22_Panelled_Mac_Coat/14_XL_AW22_Panelled_Mac_Coat_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/14_XL_AW22_Panelled_Mac_Coat/14_XL_AW22_Panelled_Mac_Coat_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/14_XL_AW22_Panelled_Mac_Coat/14_XL_AW22_Panelled_Mac_Coat_03.jpg",
                    loading: "eager"
                },
            ],
        },
    },

    tops: {
        NightShoreSilkShirt: {
            order: 4,
            itemName: "Night Shore Silk shirt",
            itemPrice: parse('<s>450€</s> 225€'),
            itemFabric: "silk 100%",
            sizeChart: "../content/photos/eshop/AW22/04_XL_AW22_Night_Shore_Silk_Shirt/04_XL_AW22_Night_Shore_Silk_Shirt_Size_Chart.jpg",

            shopifyDivId: "1667315884048",
            shopifyId: "8018348409096",
            link: "/night-shore-silk-shirt",
            picture01: "../content/photos/eshop/AW22/04_XL_AW22_Night_Shore_Silk_Shirt/04_XL_AW22_Night_Shore_Silk_Shirt_01.jpg",
            picture02: "../content/photos/eshop/AW22/04_XL_AW22_Night_Shore_Silk_Shirt/04_XL_AW22_Night_Shore_Silk_Shirt_02.jpg",
            picture03: "../content/photos/eshop/AW22/04_XL_AW22_Night_Shore_Silk_Shirt/04_XL_AW22_Night_Shore_Silk_Shirt_03.jpg",
            picture04: "../content/photos/eshop/AW22/04_XL_AW22_Night_Shore_Silk_Shirt/04_XL_AW22_Night_Shore_Silk_Shirt_04.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/04_XL_AW22_Night_Shore_Silk_Shirt/04_XL_AW22_Night_Shore_Silk_Shirt_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/04_XL_AW22_Night_Shore_Silk_Shirt/04_XL_AW22_Night_Shore_Silk_Shirt_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/04_XL_AW22_Night_Shore_Silk_Shirt/04_XL_AW22_Night_Shore_Silk_Shirt_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW22/04_XL_AW22_Night_Shore_Silk_Shirt/04_XL_AW22_Night_Shore_Silk_Shirt_03.jpg",
                    loading: "eager"
                },
            ],
        },
        SnowWoodEmbroideredTee: {
            order: 5,
            itemName: "Snow Wood Embroidered tee",
            itemPrice: parse('<s>190€</s> 100€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW22/05_XL_AW22_Snow_Woods_Embroidered_Tee/05_XL_AW22_Snow_Woods_Embroidered_Tee_Size_Chart.jpg",

            shopifyDivId: "1667316192181",
            shopifyId: "8018422497544",
            link: "/snow-wood-embroidered-tee",
            picture01: "../content/photos/eshop/AW22/05_XL_AW22_Snow_Woods_Embroidered_Tee/05_XL_AW22_Snow_Woods_Embroidered_Tee_01.jpg",
            picture02: "../content/photos/eshop/AW22/05_XL_AW22_Snow_Woods_Embroidered_Tee/05_XL_AW22_Snow_Woods_Embroidered_Tee_02.jpg",
            picture03: "../content/photos/eshop/AW22/05_XL_AW22_Snow_Woods_Embroidered_Tee/05_XL_AW22_Snow_Woods_Embroidered_Tee_03.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/05_XL_AW22_Snow_Woods_Embroidered_Tee/05_XL_AW22_Snow_Woods_Embroidered_Tee_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/05_XL_AW22_Snow_Woods_Embroidered_Tee/05_XL_AW22_Snow_Woods_Embroidered_Tee_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/05_XL_AW22_Snow_Woods_Embroidered_Tee/05_XL_AW22_Snow_Woods_Embroidered_Tee_03.jpg",
                    loading: "eager"
                },
            ],
        },
        NightShoreEmbroideredTee: {
            order: 8,
            itemName: "Night Shore Embroidered tee",
            itemPrice: parse('<s>140€</s> 80€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW22/08_XL_AW22_Night_Shore_Embroidered_Tee/08_XL_AW22_Night_Shore_Embroidered_Tee_Size_Chart.jpg",

            shopifyDivId: "1667317162510",
            shopifyId: "8018360303880",
            link: "/night-shore-embroidered-tee",
            picture01: "../content/photos/eshop/AW22/08_XL_AW22_Night_Shore_Embroidered_Tee/08_XL_AW22_Night_Shore_Embroidered_Tee_01.jpg",
            picture02: "../content/photos/eshop/AW22/08_XL_AW22_Night_Shore_Embroidered_Tee/08_XL_AW22_Night_Shore_Embroidered_Tee_02.jpg",
            picture03: "../content/photos/eshop/AW22/08_XL_AW22_Night_Shore_Embroidered_Tee/08_XL_AW22_Night_Shore_Embroidered_Tee_03.jpg",
            picture04: "../content/photos/eshop/AW22/08_XL_AW22_Night_Shore_Embroidered_Tee/08_XL_AW22_Night_Shore_Embroidered_Tee_04.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/08_XL_AW22_Night_Shore_Embroidered_Tee/08_XL_AW22_Night_Shore_Embroidered_Tee_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/08_XL_AW22_Night_Shore_Embroidered_Tee/08_XL_AW22_Night_Shore_Embroidered_Tee_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/08_XL_AW22_Night_Shore_Embroidered_Tee/08_XL_AW22_Night_Shore_Embroidered_Tee_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW22/08_XL_AW22_Night_Shore_Embroidered_Tee/08_XL_AW22_Night_Shore_Embroidered_Tee_03.jpg",
                    loading: "eager"
                },
            ],
        },
        CamouflageZipUpWorkShirt: {
            order: 10,
            itemName: parse('<s>Camouflage Zip Up Work shirt</s>'),
            itemPrice: parse('<s>890€</s> 623€'),
            itemFabric: "leather 100%",
            sizeChart: "../content/photos/eshop/AW22/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt_Size_Chart.jpg",
            soldOut: "Sold Out",

            shopifyDivId: "1667317943490",
            shopifyId: "8019000361224",
            link: "/camouflage-zip-up-work-shirt",
            picture01: "../content/photos/eshop/AW22/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt_01.jpg",
            picture02: "../content/photos/eshop/AW22/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt_02.jpg",
            picture03: "../content/photos/eshop/AW22/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt_03.jpg",
            picture04: "../content/photos/eshop/AW22/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt_04.jpg",
            picture05: "../content/photos/eshop/AW22/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt_05.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW22/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/AW22/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt/10_XL_AW22_Camouflage_Zip_Up_Work_Shirt_05.jpg",
                    loading: "eager"
                },
            ],
        },
        MudRootCompressionTop: {
            order: 12,
            itemName: "Mud Root Compression top",
            itemPrice: parse('<s>270€</s> 150€'),
            itemFabric: "polyester 100%",
            sizeChart: "../content/photos/eshop/AW22/12_XL_AW22_Mud_Root_Compression_Top/12_XL_AW22_Mud_Root_Compression_Top_Size_Chart.jpg",

            shopifyDivId: "1667318565191",
            shopifyId: "8018359025928",
            link: "/mud-root-compression-top",
            picture01: "../content/photos/eshop/AW22/12_XL_AW22_Mud_Root_Compression_Top/12_XL_AW22_Mud_Root_Compression_Top_01.jpg",
            picture02: "../content/photos/eshop/AW22/12_XL_AW22_Mud_Root_Compression_Top/12_XL_AW22_Mud_Root_Compression_Top_02.jpg",
            picture03: "../content/photos/eshop/AW22/12_XL_AW22_Mud_Root_Compression_Top/12_XL_AW22_Mud_Root_Compression_Top_03.jpg",
            picture04: "../content/photos/eshop/AW22/12_XL_AW22_Mud_Root_Compression_Top/12_XL_AW22_Mud_Root_Compression_Top_04.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/12_XL_AW22_Mud_Root_Compression_Top/12_XL_AW22_Mud_Root_Compression_Top_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/12_XL_AW22_Mud_Root_Compression_Top/12_XL_AW22_Mud_Root_Compression_Top_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/12_XL_AW22_Mud_Root_Compression_Top/12_XL_AW22_Mud_Root_Compression_Top_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW22/12_XL_AW22_Mud_Root_Compression_Top/12_XL_AW22_Mud_Root_Compression_Top_04.jpg",
                    loading: "eager"
                },

            ],
        },
        MixedRibZipUpCardigan: {
            order: 13,
            itemName: parse('<s>Mixed Rib Zip Up cardigan</s>'),
            itemPrice: parse('<s>540€</s> 378€'),
            itemFabric: "cotton 60% nylon 30% merino 10%",
            sizeChart: "../content/photos/eshop/AW22/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan_Size_Chart.jpg",

            soldOut: true,


            shopifyDivId: "1667319170730",
            shopifyId: "8018457329928",
            link: "/mixed-rib-zip-up-cardigan",
            picture01: "../content/photos/eshop/AW22/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan_01.jpg",
            picture02: "../content/photos/eshop/AW22/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan_02.jpg",
            picture03: "../content/photos/eshop/AW22/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan_03.jpg",
            picture04: "../content/photos/eshop/AW22/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan_04.jpg",
            picture05: "../content/photos/eshop/AW22/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan_05.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW22/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/AW22/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan/13_XL_AW22_Mixed_Rib_Zip_Up_Cardigan_05.jpg",
                    loading: "eager"
                },
            ],
        },
        /*  AdjustableDrawstringTopGrey: {
             order: 15,
             itemName: parse('<s>Adjustable Drawstring top</s>'),
             itemPrice: parse('<s>630€</s> 441€'),
             itemFabric: "wool 100% ",
             sizeChart: "../content/photos/eshop/AW22/15_XL_AW22_Adjustable_Drawstring_Top_Concrete_Grey/15_XL_AW22_Adjustable_Drawstring_Top_Grey_Size_Chart.jpg",
             soldOut: "Sold Out",
 
             shopifyDivId: "1667319899086",
             shopifyId: "8019000426760",
             link: "/adjustable-drawstring-top-grey",
             picture01: "../content/photos/eshop/AW22/15_XL_AW22_Adjustable_Drawstring_Top_Concrete_Grey/15_XL_AW22_Adjustable_Drawstring_Top_Grey_01.jpg",
             picture02: "../content/photos/eshop/AW22/15_XL_AW22_Adjustable_Drawstring_Top_Concrete_Grey/15_XL_AW22_Adjustable_Drawstring_Top_Grey_02.jpg",
 
 
             pictures: [
                 {
                     key: 1,
                     srcJpeg: "../content/photos/eshop/AW22/15_XL_AW22_Adjustable_Drawstring_Top_Concrete_Grey/15_XL_AW22_Adjustable_Drawstring_Top_Grey_01.jpg",
                     loading: "eager"
                 },
                 {
                     key: 2,
                     srcJpeg: "../content/photos/eshop/AW22/15_XL_AW22_Adjustable_Drawstring_Top_Concrete_Grey/15_XL_AW22_Adjustable_Drawstring_Top_Grey_02.jpg",
                     loading: "eager"
                 },
                 {
                     key: 3,
                     srcJpeg: "../content/photos/eshop/AW22/15_XL_AW22_Adjustable_Drawstring_Top_Concrete_Grey/15_XL_AW22_Adjustable_Drawstring_Top_Grey_03.jpg",
                     loading: "eager"
                 },
                 {
                     key: 4,
                     srcJpeg: "../content/photos/eshop/AW22/15_XL_AW22_Adjustable_Drawstring_Top_Concrete_Grey/15_XL_AW22_Adjustable_Drawstring_Top_Grey_04.jpg",
                     loading: "eager"
                 },
                 {
                     key: 5,
                     srcJpeg: "../content/photos/eshop/AW22/15_XL_AW22_Adjustable_Drawstring_Top_Concrete_Grey/15_XL_AW22_Adjustable_Drawstring_Top_Grey_05.jpg",
                     loading: "eager"
                 },
             ],
         }, */
        AdjustableDrawstringTopBeige: {
            order: 20,
            itemName: parse('<s>Adjustable Drawstring top</s>'),
            itemPrice: parse('<s>630€</s> 441€'),
            itemFabric: "terylene 80% viscose 20%",
            sizeChart: "../content/photos/eshop/AW22/15_XL_AW22_Adjustable_Drawstring_Top_Concrete_Grey/15_XL_AW22_Adjustable_Drawstring_Top_Grey_Size_Chart.jpg",
            soldOut: "Sold Out",

            shopifyDivId: "1667321584183",
            shopifyId: "8019000623368",
            link: "/adjustable-drawstring-top-beige",
            picture01: "../content/photos/eshop/AW22/20_XL_AW22_Adjustable_Drawstring_Top_Beige/20_XL_AW22_Adjustable_Drawstring_Top_Beige_01.jpg",
            picture02: "../content/photos/eshop/AW22/20_XL_AW22_Adjustable_Drawstring_Top_Beige/20_XL_AW22_Adjustable_Drawstring_Top_Beige_02.jpg",
            picture03: "../content/photos/eshop/AW22/20_XL_AW22_Adjustable_Drawstring_Top_Beige/20_XL_AW22_Adjustable_Drawstring_Top_Beige_03.jpg",
            picture04: "../content/photos/eshop/AW22/20_XL_AW22_Adjustable_Drawstring_Top_Beige/20_XL_AW22_Adjustable_Drawstring_Top_Beige_04.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/20_XL_AW22_Adjustable_Drawstring_Top_Beige/20_XL_AW22_Adjustable_Drawstring_Top_Beige_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/20_XL_AW22_Adjustable_Drawstring_Top_Beige/20_XL_AW22_Adjustable_Drawstring_Top_Beige_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/20_XL_AW22_Adjustable_Drawstring_Top_Beige/20_XL_AW22_Adjustable_Drawstring_Top_Beige_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW22/20_XL_AW22_Adjustable_Drawstring_Top_Beige/20_XL_AW22_Adjustable_Drawstring_Top_Beige_04.jpg",
                    loading: "eager"
                },
            ],

        },
        NightShoreCompressionTop: {
            order: 17,
            itemName: "Night Shore Compression top",
            itemPrice: parse('<s>270€</s> 189€'),
            itemFabric: "polyester 100%",
            sizeChart: "../content/photos/eshop/AW22/17_XL_AW22_Night_Shore_Compression_Top/17_XL_AW22_Night_Shore_Compression_Top_Size_Chart.jpg",

            shopifyDivId: "1667320380103",
            shopifyId: "8018358665480",
            link: "/night-shore-compression-top",
            picture01: "../content/photos/eshop/AW22/17_XL_AW22_Night_Shore_Compression_Top/17_XL_AW22_Night_Shore_Compression_Top_01.jpg",
            picture02: "../content/photos/eshop/AW22/17_XL_AW22_Night_Shore_Compression_Top/17_XL_AW22_Night_Shore_Compression_Top_02.jpg",
            picture03: "../content/photos/eshop/AW22/17_XL_AW22_Night_Shore_Compression_Top/17_XL_AW22_Night_Shore_Compression_Top_03.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/17_XL_AW22_Night_Shore_Compression_Top/17_XL_AW22_Night_Shore_Compression_Top_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/17_XL_AW22_Night_Shore_Compression_Top/17_XL_AW22_Night_Shore_Compression_Top_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/17_XL_AW22_Night_Shore_Compression_Top/17_XL_AW22_Night_Shore_Compression_Top_03.jpg",
                    loading: "eager"
                },
            ],
        },
        KnittedTankWithShoulderCap: {
            order: 19,
            itemName: parse('<s>Knitted Tank With Shoulder Cap</s>'),
            itemPrice: parse('<s>330€</s> 231€'),
            itemFabric: "cotton 60% nylon 30% merino 10%",
            sizeChart: "../content/photos/eshop/AW22/19_XL_AW22_Knitted_Tank_With_Shoulder_Cap/19_XL_AW22_Knitted_Tank_With_Shoulder_Cap_Size_Chart.jpg",

            soldOut: "Sold Out",

            shopifyDivId: "1667320904994",
            shopifyId: "8018456084744",
            link: "/knitted-tank-with-shoulder-cap",
            picture01: "../content/photos/eshop/AW22/19_XL_AW22_Knitted_Tank_With_Shoulder_Cap/19_XL_AW22_Knitted_Tank_With_Shoulder_Cap_01.jpg",
            picture02: "../content/photos/eshop/AW22/19_XL_AW22_Knitted_Tank_With_Shoulder_Cap/19_XL_AW22_Knitted_Tank_With_Shoulder_Cap_02.jpg",
            picture03: "../content/photos/eshop/AW22/19_XL_AW22_Knitted_Tank_With_Shoulder_Cap/19_XL_AW22_Knitted_Tank_With_Shoulder_Cap_03.jpg",
            picture04: "../content/photos/eshop/AW22/19_XL_AW22_Knitted_Tank_With_Shoulder_Cap/19_XL_AW22_Knitted_Tank_With_Shoulder_Cap_04.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/19_XL_AW22_Knitted_Tank_With_Shoulder_Cap/19_XL_AW22_Knitted_Tank_With_Shoulder_Cap_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/19_XL_AW22_Knitted_Tank_With_Shoulder_Cap/19_XL_AW22_Knitted_Tank_With_Shoulder_Cap_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/19_XL_AW22_Knitted_Tank_With_Shoulder_Cap/19_XL_AW22_Knitted_Tank_With_Shoulder_Cap_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW22/19_XL_AW22_Knitted_Tank_With_Shoulder_Cap/19_XL_AW22_Knitted_Tank_With_Shoulder_Cap_04.jpg",
                    loading: "eager"
                },
            ],

        },
        MudRootEmbroideredTee: {
            order: 21,
            itemName: "Mud Root Embroidered tee",
            itemPrice: parse('<s>240€</s> 100€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW22/21_XL_AW22_Mud_Root_Embroidered_Tee/21_XL_AW22_Mud_Root_Embroidered_Tee_Size_Chart.jpg",

            shopifyDivId: "1667321194030",
            shopifyId: "8018429870344",
            link: "/mud-root-embroidered-tee",
            picture01: "../content/photos/eshop/AW22/21_XL_AW22_Mud_Root_Embroidered_Tee/21_XL_AW22_Mud_Root_Embroidered_Tee_01.jpg",
            picture02: "../content/photos/eshop/AW22/21_XL_AW22_Mud_Root_Embroidered_Tee/21_XL_AW22_Mud_Root_Embroidered_Tee_02.jpg",
            picture03: "../content/photos/eshop/AW22/21_XL_AW22_Mud_Root_Embroidered_Tee/21_XL_AW22_Mud_Root_Embroidered_Tee_03.jpg",
            picture04: "../content/photos/eshop/AW22/21_XL_AW22_Mud_Root_Embroidered_Tee/21_XL_AW22_Mud_Root_Embroidered_Tee_04.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/21_XL_AW22_Mud_Root_Embroidered_Tee/21_XL_AW22_Mud_Root_Embroidered_Tee_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/21_XL_AW22_Mud_Root_Embroidered_Tee/21_XL_AW22_Mud_Root_Embroidered_Tee_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/21_XL_AW22_Mud_Root_Embroidered_Tee/21_XL_AW22_Mud_Root_Embroidered_Tee_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW22/21_XL_AW22_Mud_Root_Embroidered_Tee/21_XL_AW22_Mud_Root_Embroidered_Tee_04.jpg",
                    loading: "eager"
                },
            ],
        },



    },

    pants: {
        AdjustableDrawstringPants: {
            order: 6,
            itemName: parse('<s>Adjustable Drawstring pants</s>'),
            itemPrice: parse('<s>765€</s> 535€'),
            itemFabric: "body · PU 10% polyester 90%/lining · polyester 50% viscose 50%",
            sizeChart: "../content/photos/eshop/AW22/06_XL_AW22_Adjustable_Drawstring_Pants/06_XL_AW22_Adjustable_Drawstring_Pants_Size_Chart.jpg",
            soldOut: "Sold Out",

            shopifyDivId: "1667316545374",
            shopifyId: "8018999410952",
            link: "/adjustable-drawstring-pants",
            picture01: "../content/photos/eshop/AW22/06_XL_AW22_Adjustable_Drawstring_Pants/06_XL_AW22_Adjustable_Drawstring_Pants_01.jpg",
            picture02: "../content/photos/eshop/AW22/06_XL_AW22_Adjustable_Drawstring_Pants/06_XL_AW22_Adjustable_Drawstring_Pants_02.jpg",
            picture03: "../content/photos/eshop/AW22/06_XL_AW22_Adjustable_Drawstring_Pants/06_XL_AW22_Adjustable_Drawstring_Pants_03.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/06_XL_AW22_Adjustable_Drawstring_Pants/06_XL_AW22_Adjustable_Drawstring_Pants_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/06_XL_AW22_Adjustable_Drawstring_Pants/06_XL_AW22_Adjustable_Drawstring_Pants_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/06_XL_AW22_Adjustable_Drawstring_Pants/06_XL_AW22_Adjustable_Drawstring_Pants_03.jpg",
                    loading: "eager"
                },
            ],

        },
        MudRootPrintedJeans: {
            order: 11,
            itemName: "Mud Root Printed jeans",
            itemPrice: parse('<s>300€</s> 180€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW22/11_XL_AW22_Mud_Root_Printed_Jeans/11_XL_AW22_Mud_Root_Printed_Jeans_Size_Chart.jpg",

            shopifyDivId: "1667318199953",
            shopifyId: "8018454020360",
            link: "/mud-root-printed-jeans",
            picture01: "../content/photos/eshop/AW22/11_XL_AW22_Mud_Root_Printed_Jeans/11_XL_AW22_Mud_Root_Printed_Jeans_01.jpg",
            picture02: "../content/photos/eshop/AW22/11_XL_AW22_Mud_Root_Printed_Jeans/11_XL_AW22_Mud_Root_Printed_Jeans_02.jpg",
            picture03: "../content/photos/eshop/AW22/11_XL_AW22_Mud_Root_Printed_Jeans/11_XL_AW22_Mud_Root_Printed_Jeans_03.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/11_XL_AW22_Mud_Root_Printed_Jeans/11_XL_AW22_Mud_Root_Printed_Jeans_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/11_XL_AW22_Mud_Root_Printed_Jeans/11_XL_AW22_Mud_Root_Printed_Jeans_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/11_XL_AW22_Mud_Root_Printed_Jeans/11_XL_AW22_Mud_Root_Printed_Jeans_03.jpg",
                    loading: "eager"
                },
            ],
        },
        LeatherBikerPants: {
            order: 16,
            itemName: parse('<s>Leather Biker pants</s>'),
            itemPrice: parse('<s>750€</s> 525€'),
            itemFabric: "body · sheepskin 100%/lining · polyester 50% viscose 50%",
            sizeChart: "../content/photos/eshop/AW22/16_XL_AW22_Leather_Biker_Pants/16_XL_AW22_Leather_Biker_Pants_Size_Chart.jpg",
            soldOut: "Sold Out",

            shopifyDivId: "1667320177789",
            shopifyId: "8018454937864",
            link: "/leather-biker-pants",
            picture01: "../content/photos/eshop/AW22/16_XL_AW22_Leather_Biker_Pants/16_XL_AW22_Leather_Biker_Pants_01.jpg",
            picture02: "../content/photos/eshop/AW22/16_XL_AW22_Leather_Biker_Pants/16_XL_AW22_Leather_Biker_Pants_02.jpg",
            picture03: "../content/photos/eshop/AW22/16_XL_AW22_Leather_Biker_Pants/16_XL_AW22_Leather_Biker_Pants_03.jpg",
            picture04: "../content/photos/eshop/AW22/16_XL_AW22_Leather_Biker_Pants/16_XL_AW22_Leather_Biker_Pants_04.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/16_XL_AW22_Leather_Biker_Pants/16_XL_AW22_Leather_Biker_Pants_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/16_XL_AW22_Leather_Biker_Pants/16_XL_AW22_Leather_Biker_Pants_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/16_XL_AW22_Leather_Biker_Pants/16_XL_AW22_Leather_Biker_Pants_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW22/16_XL_AW22_Leather_Biker_Pants/16_XL_AW22_Leather_Biker_Pants_04.jpg",
                    loading: "eager"
                },
            ],
        },
        CamouflageCowhidePants: {
            order: 18,
            itemName: parse('<s>Camouflage Cowhide pants</s>'),
            itemPrice: parse('<s>1300€</s> 910€'),
            itemFabric: "body · leather 100%/lining · polyester 50% viscose 50%",
            sizeChart: "../content/photos/eshop/AW22/18_XL_AW22_Camouflage_Cowhide_Pants/18_XL_AW22_Camouflage_Cowhide_Pants_Size_Chart.jpg",
            soldOut: "Sold Out",

            shopifyDivId: "1667320641429",
            shopifyId: "8019000721672",
            link: "/camouflage-cowhide-pants",
            picture01: "../content/photos/eshop/AW22/18_XL_AW22_Camouflage_Cowhide_Pants/18_XL_AW22_Camouflage_Cowhide_Pants_01.jpg",
            picture02: "../content/photos/eshop/AW22/18_XL_AW22_Camouflage_Cowhide_Pants/18_XL_AW22_Camouflage_Cowhide_Pants_02.jpg",


            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW22/18_XL_AW22_Camouflage_Cowhide_Pants/18_XL_AW22_Camouflage_Cowhide_Pants_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW22/18_XL_AW22_Camouflage_Cowhide_Pants/18_XL_AW22_Camouflage_Cowhide_Pants_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW22/18_XL_AW22_Camouflage_Cowhide_Pants/18_XL_AW22_Camouflage_Cowhide_Pants_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW22/18_XL_AW22_Camouflage_Cowhide_Pants/18_XL_AW22_Camouflage_Cowhide_Pants_04.jpg",
                    loading: "eager"
                },
            ],
        },


    },

}