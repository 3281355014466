import React from "react";
import TopMenu from "../topmenu";
import VideoCollection from "../VideoCollection";

import GlobalMetaTags from "../globalMetaTags";


import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Navigation, Keyboard, FreeMode } from "swiper";
import SwiperButtonsPrevNext from "../SwiperButtonsPrevNext";

import LoaderSlider from "../LoaderSlider";
import LoaderFake from "../LoaderFake";

import BottomMenu from "../MenuBottom";

const MainPagesContainer = (props) => {
    const { pageTitle, removeLoader, title, src, videoDisplay, style, topMenuDisplay, collectionsClassName, children, shouldHide, PDFshouldHide, pdf, handlePdfDownload } = props;

    return (
        <main>
            {/*         <LoaderFake />
 */}
            <GlobalMetaTags pageTitle={pageTitle} />
            <LoaderSlider removeLoader={removeLoader} />
            <VideoCollection title={title} src={src} videoDisplay={videoDisplay} shouldHide={shouldHide} PDFshouldHide={PDFshouldHide} pdf={pdf} onPdfClick={handlePdfDownload} />
            <TopMenu style={style} topMenuDisplay={topMenuDisplay} />
            <BottomMenu collectionsClassName={collectionsClassName} />
            <Swiper
                modules={[Mousewheel, Navigation, Keyboard, FreeMode]}
                spaceBetween={0}
                freeMode={true}
                slidesPerView={"auto"}
                grabCursor={true}
                loop={true}
                mousewheel={true}
                centeredSlides={true}

                keyboard={{
                    enabled: true,
                    onlyInViewport: false,
                }}
                navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                }}
            >
                {children}
                <SwiperButtonsPrevNext />
            </Swiper>
        </main>
    );
};
export default MainPagesContainer;