// import React from "react";

// import { AW22 } from "../../../../js/database/database-aw22.js";

// import TopMenuProducts from "../../../../components/topmenuproducts";
// import ButtonsPrevNext from "../../../../components/buttonsprevnext";
// import InfoItem from "../../../../components/infoItem";
// import Loader from "../../../../components/loader";
// import ShopifyDiv from "../../../../components/shopify-product-component";
// import SizeChart from "../../../../components/sizeChart.js";

// class AdjustableDrawstringTopGrey extends React.Component {
//   componentDidMount() {
//     (function () {
//       var scriptURL = "../../../../js/shopifybuystorefront.js";
//       if (window.ShopifyBuy) {
//         if (window.ShopifyBuy.UI) {
//           ShopifyBuyInit();
//         } else {
//           loadScript();
//         }
//       } else {
//         loadScript();
//       }
//       function loadScript() {
//         var script = document.createElement("script");
//         script.async = true;
//         script.src = scriptURL;
//         (
//           document.getElementsByTagName("head")[0] ||
//           document.getElementsByTagName("body")[0]
//         ).appendChild(script);
//         script.onload = ShopifyBuyInit;
//       }
//       function ShopifyBuyInit() {
//         var client = window.ShopifyBuy.buildClient({
//           domain: "ximonleestore.myshopify.com",
//           storefrontAccessToken: "2f2501adacfa85056fcce3e5398a3ee5",
//         });
//         window.ShopifyBuy.UI.onReady(client).then(function (ui) {
//           ui.createComponent("product", {
//             id: `${AW22.tops.AdjustableDrawstringTopGrey.shopifyId}`,
//             node: document.getElementById(
//               `product-component-${AW22.tops.AdjustableDrawstringTopGrey.shopifyDivId}`
//             ),

//             moneyFormat: "%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D",
//             options: {
//               product: {
//                 styles: {
//                   product: {
//                     "@media (min-width: 601px)": {
//                       "max-width": "calc(25% - 20px)",
//                       "margin-left": "20px",
//                       "margin-bottom": "50px",
//                     },
//                   },

//                   button: {
//                     "font-size": "13px",
//                     padding: "7px",

//                     "background-color": "transparent",
//                     /*  'padding-left': '10px',
//                                          'padding-right': '10px',
//                                          'padding-top': '10px',
//                                          'padding-bottom': '10px',  */
//                     "font-family": "Arial",

//                     /*    'border': '1px solid #e0e0e0', */
//                     "background-color": "#000000",
//                     color: "#ffffff",
//                     "border-radius": "5px",

//                     ":hover": {
//                       "background-color": "#000000",

//                       filter: "blur(2px)" /*
//                               'color': 'transparent', */,
//                     },

//                     ":focus": {
//                       "background-color": "#c3c3c3",
//                     },
//                   },
//                   quantityInput: {
//                     "font-size": "13px",
//                     "padding-top": "14.5px",
//                     "padding-bottom": "14.5px",
//                   },
//                 },
//                 contents: {
//                   img: false,
//                   title: false,
//                   price: false,
//                 },
//                 text: {
//                   button: "Add to cart",
//                 },
//               },
//               productSet: {
//                 styles: {
//                   products: {
//                     "@media (min-width: 601px)": {
//                       "margin-left": "-20px",
//                     },
//                   },
//                 },
//               },
//               modalProduct: {
//                 contents: {
//                   img: false,
//                   imgWithCarousel: true,
//                   button: false,
//                   buttonWithQuantity: true,
//                 },
//                 styles: {
//                   product: {
//                     "@media (min-width: 601px)": {
//                       "max-width": "100%",
//                       "margin-left": "0px",
//                       "margin-bottom": "0px",
//                     },
//                   },
//                   button: {
//                     "font-size": "13px",
//                     "padding-top": "14.5px",
//                     "padding-bottom": "14.5px",
//                     ":hover": {
//                       "background-color": "#cacaca",
//                     },
//                     "background-color": "#e0e0e0",
//                     ":focus": {
//                       "background-color": "#cacaca",
//                     },
//                     "border-radius": "0px",
//                   },
//                   quantityInput: {
//                     "font-size": "13px",
//                     "padding-top": "14.5px",
//                     "padding-bottom": "14.5px",
//                   },
//                 },
//                 text: {
//                   button: "Add to cart",
//                 },
//               },

//               option: {
//                 styles: {
//                   wrapper: {
//                     "border-radius": "5px",
//                     "background-color": "#000000",
//                   },
//                   select: {
//                     color: "#ffffff",
//                   },
//                 },
//               },

//               cart: {
//                 styles: {
//                   button: {
//                     "font-size": "13px",
//                     "padding-top": "14.5px",
//                     "padding-bottom": "14.5px",
//                     ":hover": {
//                       "background-color": "#cacaca",
//                     },
//                     "background-color": "#7a7a7a",
//                     ":focus": {
//                       "background-color": "#cacaca",
//                     },
//                     "border-radius": "0px",
//                   },
//                 },
//                 text: {
//                   total: "Subtotal",
//                   button: "Checkout",
//                 },
//               },
//               toggle: {
//                 styles: {
//                   toggle: {
//                     "background-color": "#000000",
//                     ":hover": {
//                       "background-color": "#cacaca",
//                     },
//                     ":focus": {
//                       "background-color": "#cacaca",
//                     },
//                   },
//                   count: {
//                     "font-size": "13px",
//                   },
//                 },
//               },
//             },
//           });
//         });
//       }
//     })();
//   }

//   render() {
//     return (
//       <div>
//         <Loader />
//         <TopMenuProducts
//           name={AW22.tops.AdjustableDrawstringTopGrey.itemName}
//         />

//         <InfoItem
//           price={AW22.tops.AdjustableDrawstringTopGrey.itemPrice}
//           fabric={AW22.tops.AdjustableDrawstringTopGrey.itemFabric}
//         />

//         <SizeChart src={AW22.tops.AdjustableDrawstringTopGrey.sizeChart} />

//         {/*   <img
//           className="sizechart"
//           src={AW22.tops.AdjustableDrawstringTopGrey.sizeChart}
//           loading="lazy"
//           alt="Size chart"
//         />
//  */}
//         <div className="swiper-container" id="swiper">
//           <div className="swiper-wrapper">
//             <div className="swiper-slide">
//               <img
//                 src={AW22.tops.AdjustableDrawstringTopGrey.picture01}
//                 alt={AW22.alt}
//                 loading="lazy"
//               />
//             </div>
//             <div className="swiper-slide">
//               <img
//                 src={AW22.tops.AdjustableDrawstringTopGrey.picture02}
//                 alt={AW22.alt}
//                 loading="lazy"
//               />
//             </div>
//             <div className="swiper-slide">
//               <img
//                 src={AW22.tops.AdjustableDrawstringTopGrey.picture03}
//                 alt={AW22.alt}
//                 loading="lazy"
//               />
//             </div>
//             <div className="swiper-slide">
//               <img
//                 src={AW22.tops.AdjustableDrawstringTopGrey.picture04}
//                 alt={AW22.alt}
//                 loading="lazy"
//               />
//             </div>
//             <div className="swiper-slide">
//               <img
//                 src={AW22.tops.AdjustableDrawstringTopGrey.picture05}
//                 alt={AW22.alt}
//                 loading="lazy"
//               />
//             </div>
//           </div>
//         </div>

//         <ShopifyDiv id={AW22.tops.AdjustableDrawstringTopGrey.shopifyDivId} />
//         <ButtonsPrevNext />
//       </div>
//     );
//   }
// }

// export default AdjustableDrawstringTopGrey;

import React from "react";

import { AW22 } from "../../../../js/database/database-aw22.js";

import ProductsContainer from "../../../../components/containers/ProductsContainer.js";
import { SwiperSlide } from "swiper/react";

const AdjustableDrawstringTopGrey = () => {
  return (
    <ProductsContainer
      pageTitle={AW22.tops.AdjustableDrawstringTopGrey.itemName}
      name={AW22.tops.AdjustableDrawstringTopGrey.itemName}
      price={AW22.tops.AdjustableDrawstringTopGrey.itemPrice}
      fabric={AW22.tops.AdjustableDrawstringTopGrey.itemFabric}
      src={AW22.tops.AdjustableDrawstringTopGrey.sizeChart}
      divId={AW22.tops.AdjustableDrawstringTopGrey.shopifyDivId}
      mainId={AW22.tops.AdjustableDrawstringTopGrey.shopifyId}
    >
      {React.Children.toArray(
        AW22.tops.AdjustableDrawstringTopGrey.pictures.map(({ srcJpeg }) => (
          <SwiperSlide>
            <img
              src={srcJpeg}
              alt={AW22.tops.AdjustableDrawstringTopGrey.itemName}
              width={AW22.imgWidth}
              height={AW22.imgHeight}
              loading={AW22.loading}
            />
          </SwiperSlide>
        ))
      )}
    </ProductsContainer>
  );
};

export default AdjustableDrawstringTopGrey;
