import React from "react";

const SwiperButtonsPrevNext = (props) => {
    return <div>
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
    </div>
}

export default SwiperButtonsPrevNext;
