import React from "react";
import { NavLink } from "react-router-dom";

import GlobalMetaTags from "../components/globalMetaTags";

import setBodyColor from "../js/setBodyColor";
import setFontColor from "../js/setFontColor";

class PageNotFound extends React.Component {
  render() {
    setBodyColor({ color: "#000000" });
    setFontColor({ color: "#c3c3c3" });

    return (
      <main>
        <GlobalMetaTags pageTitle={"Page not found"} />

        <NavLink className="link" to="/">
          <div className="pagenotfound">page not found</div>
        </NavLink>
      </main>
    );
  }
}

export default PageNotFound;
