import React from "react";


const InfoItem = (props) => {
    return <p className="textshop serif">
        {props.fabric}<br />
        {props.price}
    </p>

}


export default InfoItem;