import React from "react";

import { AW21 } from "../../../../js/database/database-aw21.js";

import TopMenuProducts from "../../../../components/topmenuproducts";
import SwiperInit from "../../../../components/SwiperInit";
import InfoItem from "../../../../components/infoItem";
import Loader from "../../../../components/loader";

import ShopifyMainComponent from "../../../../components/ShopifyMainComponent";
import GlobalMetaTags from "../../../../components/globalMetaTags";
import SizeChart from "../../../../components/sizeChart.js";

class JigsawPlantSweater extends React.Component {

    render() {
        return (
            <div>
                <GlobalMetaTags
                    pageTitle={AW21.tops.JigsawPlantSweater.itemName}
                />
                <Loader />
                <TopMenuProducts
                    name={AW21.tops.JigsawPlantSweater.itemName}
                />
                <InfoItem
                    price={AW21.tops.JigsawPlantSweater.itemPrice}
                    fabric={AW21.tops.JigsawPlantSweater.itemFabric}
                />
                <SizeChart src={AW21.tops.JigsawPlantSweater.sizeChart} />
                <div className="swiper-container" id="swiper">
                    <div className="swiper-wrapper">
                        <div className="swiper-slide">
                            <img
                                src={AW21.tops.JigsawPlantSweater.picture01}
                                alt={AW21.alt}
                                width={AW21.imgWidth}
                                height={AW21.imgHeight}
                                loading="lazy"
                            />
                        </div>
                        <div className="swiper-slide">
                            <img
                                src={AW21.tops.JigsawPlantSweater.picture02}
                                alt={AW21.alt}
                                width={AW21.imgWidth}
                                height={AW21.imgHeight}
                                loading="lazy"
                            />
                        </div>
                        <div className="swiper-slide">
                            <img
                                src={AW21.tops.JigsawPlantSweater.picture03}
                                alt={AW21.alt}
                                width={AW21.imgWidth}
                                height={AW21.imgHeight}
                                loading="lazy"
                            />
                        </div>
                        <div className="swiper-slide">
                            <img
                                src={AW21.tops.JigsawPlantSweater.picture04}
                                alt={AW21.alt}
                                width={AW21.imgWidth}
                                height={AW21.imgHeight}
                                loading="lazy"
                            />
                        </div>
                        <div className="swiper-slide">
                            <img
                                src={AW21.tops.JigsawPlantSweater.picture05}
                                alt={AW21.alt}
                                width={AW21.imgWidth}
                                height={AW21.imgHeight}
                                loading="lazy"
                            />
                        </div>
                        <div className="swiper-slide">
                            <img
                                src={AW21.tops.JigsawPlantSweater.picture06}
                                alt={AW21.alt}
                                width={AW21.imgWidth}
                                height={AW21.imgHeight}
                                loading="lazy"
                            />
                        </div>

                    </div>
                </div>
                <ShopifyMainComponent
                    divId={AW21.tops.JigsawPlantSweater.shopifyDivId}
                    mainId={AW21.tops.JigsawPlantSweater.shopifyId}
                />
                <SwiperInit />
            </div>
        );
    }
}

export default JigsawPlantSweater;
