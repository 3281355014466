export function videoOpening() {
    const a = document.querySelector(".videoscollection");
    /*        const b = document.querySelector("#sizechartbutton a");
        const c = document.querySelector(".backdropblur"); */

    if (a.style.display === "block") {
        a.style.display = "none";
        /*     b.classList.remove("circle");
            c.style.display = "none"; */

    } else {
        a.style.display = "block";
        /*  b.classList.add("circle");
         c.style.display = "block"; */
    }
}
