import React, { useEffect } from "react";

import ss24CollectionImages from "../js/database/database-ss24-collection";

import setBodyColor from "../js/setBodyColor";
import setFontColor from "../js/setFontColor";

import { SwiperSlide } from "swiper/react";

import MainPagesContainer from "../components/containers/MainPagesContainer";
import { sliderLoader } from "../js/sliderLoader";

const alt = "SS24 collection";
const imgWidth = 832;
const imgHeight = 1250;

const SS24 = (props) => {
  setBodyColor({ color: "#222222" });

  const styles = {
    color: "#dddddd",
  };

  const handlePdfDownload = () => {
    const pdfUrl = "../../content/pdf/XIMONLEE_SS24_LOOKBOOK.pdf";
    const anchor = document.createElement("a");
    anchor.href = pdfUrl;
    anchor.download = "XIMONLEE_SS24_LOOKBOOK.pdf";
    anchor.click();
    console.log("clicked");
  };

  return (
    <MainPagesContainer
      pageTitle={alt}
      //title="Video"
      src={"../content/videos/Ximon_Lee_SS24_video_long.mp4"}
      style={styles.color}
      collectionsClassName={"circle"}
      PDFshouldHide={false}
      handlePdfDownload={handlePdfDownload}
    >
      {React.Children.toArray(
        ss24CollectionImages.map(({ srcJpeg, srcWebp, loading }) => (
          <SwiperSlide>
            <picture>
              <source srcSet={srcWebp} type="image/webp" />
              <img
                src={srcJpeg}
                width={imgWidth}
                height={imgHeight}
                alt={alt}
                loading="eager"
              />
            </picture>
          </SwiperSlide>
        ))
      )}
    </MainPagesContainer>
  );
};

export default SS24;
