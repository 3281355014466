const ss23CollectionImages = [
    {
        key: 1,
        srcJpeg: "../../content/photos/collections/SS23/XIMONLEE_SS23_LOOK01.jpg",
        srcWebp: "../../content/photos/collections/SS23/webp/XIMONLEE_SS23_LOOK01.webp",
        loading: "eager"
    },
    {
        key: 2,
        srcJpeg: "../../content/photos/collections/SS23/XIMONLEE_SS23_LOOK02.jpg",
        srcWebp: "../../content/photos/collections/SS23/webp/XIMONLEE_SS23_LOOK02.webp",
        loading: "eager"
    },
    {
        key: 3,
        srcJpeg: "../../content/photos/collections/SS23/XIMONLEE_SS23_LOOK03.jpg",
        srcWebp: "../../content/photos/collections/SS23/webp/XIMONLEE_SS23_LOOK03.webp",
        loading: "eager"
    }, {
        key: 4,
        srcJpeg: "../../content/photos/collections/SS23/XIMONLEE_SS23_LOOK04.jpg",
        srcWebp: "../../content/photos/collections/SS23/webp/XIMONLEE_SS23_LOOK04.webp",
        loading: "lazy"
    },
    {
        key: 5,
        srcJpeg: "../../content/photos/collections/SS23/XIMONLEE_SS23_LOOK05.jpg",
        srcWebp: "../../content/photos/collections/SS23/webp/XIMONLEE_SS23_LOOK05.webp",
        loading: "lazy"
    },
    {
        key: 6,
        srcJpeg: "../../content/photos/collections/SS23/XIMONLEE_SS23_LOOK06.jpg",
        srcWebp: "../../content/photos/collections/SS23/webp/XIMONLEE_SS23_LOOK06.webp",
        loading: "lazy"
    },
    {
        key: 7,
        srcJpeg: "../../content/photos/collections/SS23/XIMONLEE_SS23_LOOK07.jpg",
        srcWebp: "../../content/photos/collections/SS23/webp/XIMONLEE_SS23_LOOK07.webp",
        loading: "lazy"
    },
    {
        key: 8,
        srcJpeg: "../../content/photos/collections/SS23/XIMONLEE_SS23_LOOK08.jpg",
        srcWebp: "../../content/photos/collections/SS23/webp/XIMONLEE_SS23_LOOK08.webp",
        loading: "lazy"
    },
    {
        key: 9,
        srcJpeg: "../../content/photos/collections/SS23/XIMONLEE_SS23_LOOK09.jpg",
        srcWebp: "../../content/photos/collections/SS23/webp/XIMONLEE_SS23_LOOK09.webp",
        loading: "lazy"
    }, {
        key: 10,
        srcJpeg: "../../content/photos/collections/SS23/XIMONLEE_SS23_LOOK10.jpg",
        srcWebp: "../../content/photos/collections/SS23/webp/XIMONLEE_SS23_LOOK10.webp",
        loading: "lazy"
    },
    {
        key: 11,
        srcJpeg: "../../content/photos/collections/SS23/XIMONLEE_SS23_LOOK11.jpg",
        srcWebp: "../../content/photos/collections/SS23/webp/XIMONLEE_SS23_LOOK11.webp",
        loading: "lazy"
    },
    {
        key: 12,
        srcJpeg: "../../content/photos/collections/SS23/XIMONLEE_SS23_LOOK12.jpg",
        srcWebp: "../../content/photos/collections/SS23/webp/XIMONLEE_SS23_LOOK12.webp",
        loading: "lazy"
    },
    {
        key: 13,
        srcJpeg: "../../content/photos/collections/SS23/XIMONLEE_SS23_LOOK13.jpg",
        srcWebp: "../../content/photos/collections/SS23/webp/XIMONLEE_SS23_LOOK13.webp",
        loading: "lazy"
    },
    {
        key: 14,
        srcJpeg: "../../content/photos/collections/SS23/XIMONLEE_SS23_LOOK14.jpg",
        srcWebp: "../../content/photos/collections/SS23/webp/XIMONLEE_SS23_LOOK14.webp",
        loading: "lazy"
    },
    {
        key: 15,
        srcJpeg: "../../content/photos/collections/SS23/XIMONLEE_SS23_LOOK15.jpg",
        srcWebp: "../../content/photos/collections/SS23/webp/XIMONLEE_SS23_LOOK15.webp",
        loading: "lazy"
    },

];


export default ss23CollectionImages;
