const aw23CollectionImages = [
    {
        key: 1,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_01.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_01.webp",
        loading: "eager"
    },
    {
        key: 2,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_02.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_02.webp",
        loading: "eager"
    },
    {
        key: 3,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_03.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_03.webp",
        loading: "eager"
    },
    {
        key: 4,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_04.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_04.webp",
        loading: "lazy"
    },
    {
        key: 5,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_05.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_05.webp",
        loading: "lazy"
    },
    {
        key: 6,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_06.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_06.webp",
        loading: "lazy"
    },
    {
        key: 7,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_07.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_07.webp",
        loading: "lazy"
    },
    {
        key: 8,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_08.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_08.webp",
        loading: "lazy"
    },
    {
        key: 9,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_09.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_09.webp",
        loading: "lazy"
    }, {
        key: 10,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_10.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_10.webp",
        loading: "lazy"
    },
    {
        key: 11,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_11.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_11.webp",
        loading: "lazy"
    },
    {
        key: 12,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_12.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_12.webp",
        loading: "lazy"
    },
    {
        key: 13,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_13.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_13.webp",
        loading: "lazy"
    },
    {
        key: 14,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_14.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_14.webp",
        loading: "lazy"
    },
    {
        key: 15,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_15.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_15.webp",
        loading: "lazy"
    },
    {
        key: 16,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_16.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_16.webp",
        loading: "lazy"
    },
    {
        key: 17,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_17.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_17.webp",
        loading: "lazy"
    },
    {
        key: 18,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_18.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_18.webp",
        loading: "lazy"
    },
    {
        key: 19,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_19.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_19.webp",
        loading: "lazy"
    },
    {
        key: 20,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_20.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_20.webp",
        loading: "lazy"
    },
    {
        key: 21,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_21.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_21.webp",
        loading: "lazy"
    },
    {
        key: 22,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_22.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_22.webp",
        loading: "lazy"
    },
    {
        key: 23,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_23.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_23.webp",
        loading: "lazy"
    },
    {
        key: 24,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_24.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_24.webp",
        loading: "lazy"
    },
    {
        key: 25,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_25.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_25.webp",
        loading: "lazy"
    },
    {
        key: 26,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_26.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_26.webp",
        loading: "lazy"
    },
    {
        key: 27,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_27.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_27.webp",
        loading: "lazy"
    },
    {
        key: 28,
        srcJpeg: "../../content/photos/collections/AW23/XIMONLEE_AW23_28.jpg",
        srcWebp: "../../content/photos/collections/AW23/webp/XIMONLEE_AW23_28.webp",
        loading: "lazy"
    },

];


export default aw23CollectionImages;
