import React, { useEffect } from "react";
import aw21CollectionImages from "../js/database/database-aw21-collection";

import { SwiperSlide } from "swiper/react";
import MainPagesContainer from "../components/containers/MainPagesContainer";

import setBodyColor from "../js/setBodyColor";
import setFontColor from "../js/setFontColor";

const alt = "AW21 runway";
const imgWidth = "500";
const imgHeight = "750";

const AW21 = (props) => {
  setBodyColor({ color: "#222222" });
  //  setFontColor({ color: "#c3c3c3" });

  /*  useEffect(() => {
    const images = document.querySelectorAll("img[data-src]");
    const options = {
      root: null,
      threshold: 0,
      rootMargin: "0px 0px 0px 0px",
    };

    const observer = new IntersectionObserver(function (entries, observer) {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        }
        const img = entry.target;
        img.setAttribute("src", img.getAttribute("data-src"));
        img.removeAttribute("data-src");
        img.style.opacity = 0;
        img.style.filter = "blur(5px)";
        img.addEventListener("load", () => {
          setTimeout(() => {
            img.style.transition =
              "opacity 0.5s ease-in-out, filter 0.5s ease-in-out";
            img.style.opacity = 1;
            img.style.filter = "blur(0px)";
          }, 0);
        });
        observer.unobserve(img);
      });
    }, options);

    images.forEach((img) => {
      observer.observe(img);
    });
  }, []); */

  return (
    <MainPagesContainer
      pageTitle={alt}
      title="Video"
      src={"../content/videos/Ximon_Lee_AUTUMN-WINTER_2021_full.mp4"}
      collectionsClassName={"circle"}
      PDFshouldHide={true}
    >
      {React.Children.toArray(
        aw21CollectionImages.map(({ srcJpeg, loading }) => (
          <SwiperSlide>
            <img
              src={srcJpeg}
              width={imgWidth}
              height={imgHeight}
              alt={alt}
              loading="eager"
            />
          </SwiperSlide>
        ))
      )}
    </MainPagesContainer>
  );
};
export default AW21;
