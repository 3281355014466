import React from "react";

import Swiper, {
    Navigation,
    Pagination,
    Mousewheel,
    Keyboard,
    FreeMode,
    Lazy,
    Zoom,
} from "swiper";

Swiper.use([Mousewheel, Pagination, Navigation, Keyboard, FreeMode, Lazy, Zoom]);

class ButtonsPrevNext extends React.Component {

    componentDidMount() {

        const swiper = new Swiper('.swiper-container', {
            zoom: {
                maxRatio: 1.5,
                minRatio: 1
            },

            slidesPerView: 'auto',
            freeMode: true,
            grabCursor: true,
            loop: true,
            mousewheel: {
                invert: true,
            },
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
        });
    }

    render() {
        return (
            <div>
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
            </div>
        )
    }
}

export default ButtonsPrevNext;