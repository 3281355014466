import React, { useEffect } from "react";

import ss23CollectionImages from "../js/database/database-ss23-collection";

import setBodyColor from "../js/setBodyColor";
import setFontColor from "../js/setFontColor";

import { SwiperSlide } from "swiper/react";

import MainPagesContainer from "../components/containers/MainPagesContainer";

/* import { sliderLoader } from "../js/sliderLoader";
 */
const alt = "SS23 collection";
const imgWidth = 850;
const imgHeight = 1275;

const SS23 = (props) => {
  setBodyColor({ color: "#222222" });
  //setFontColor({ color: "#c3c3c3" });

  const styles = {
    color: "#dddddd",
  };
  /* 
  useEffect(() => {
    const images = document.querySelectorAll("img[data-src]");
    const options = {
      root: null,
      threshold: 0,
      rootMargin: "0px 0px 0px 0px",
    };

    const observer = new IntersectionObserver(function (entries, observer) {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        }
        const img = entry.target;
        img.setAttribute("src", img.getAttribute("data-src"));
        img.removeAttribute("data-src");
        img.style.opacity = 0;
        img.style.filter = "blur(5px)";
        img.addEventListener("load", () => {
          setTimeout(() => {
            img.style.transition =
              "opacity 0.5s ease-in-out, filter 0.5s ease-in-out";
            img.style.opacity = 1;
            img.style.filter = "blur(0px)";
          }, 0);
        });
        observer.unobserve(img);
      });
    }, options);

    images.forEach((img) => {
      observer.observe(img);
    });
  }, []);
 */
  return (
    <MainPagesContainer
      pageTitle={alt}
      title="Video"
      src={"../content/videos/Ximon_Lee_SS23_video_compressed.mp4"}
      style={styles.color}
      collectionsClassName={"circle"}
      PDFshouldHide={true}
    >
      {React.Children.toArray(
        ss23CollectionImages.map(({ srcJpeg, srcWebp, loading }) => (
          <SwiperSlide>
            <picture>
              <source srcSet={srcWebp} type="image/webp" />

              <img
                src={srcJpeg}
                width={imgWidth}
                height={imgHeight}
                alt={alt}
                loading="eager"
              />
            </picture>
          </SwiperSlide>
        ))
      )}
    </MainPagesContainer>
  );
};

export default SS23;
