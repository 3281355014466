import React, { useEffect } from "react";

const Loader = () => {

    useEffect(() => {


        const imgTop = document.querySelectorAll(".img-top");
        /*  console.log(imgTop); */


        /*  const allImages = document.querySelectorAll("img");
        console.log(allImages);
*/
        const loader = document.querySelector(".loader");

        let totalImages = imgTop.length;
        let imagesLoaded = 0;

        function onImageLoad() {
            imagesLoaded++;
            //console.log(imagesLoaded);
            if (imagesLoaded === totalImages) {
                loader.classList.add("hidden");
                console.log("all images loaded");
            }
        }

        
        /*  for (let i = 0; i < imgTop.length; i++) {
             imgTop[i].addEventListener('load', onImageLoad);
         }
  */

        imgTop.forEach((img) => {
            img.addEventListener('load', onImageLoad);
        });

        // Cleanup function
        return () => {
            imgTop.forEach((img) => {
                img.removeEventListener('load', onImageLoad);
            });
        };

    }, []);




    return (<div>
        <div className="loader"><div className="textloader flicker-loader">loading</div></div>
    </div>)



}

export default Loader;