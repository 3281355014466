const ss24CollectionImages = [
    {
        key: 1,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK1.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK1.webp",
        loading: "eager"
    },
    {
        key: 2,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK2.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK2.webp",
        loading: "eager"
    },
    {
        key: 3,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK3.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK3.webp",
        loading: "eager"
    },

    {
        key: 23,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK3B.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK3B.webp",
        loading: "eager"
    },

    {
        key: 4,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK4.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK4.webp",
        loading: "lazy"
    },
    {
        key: 5,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK5.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK5.webp",
        loading: "lazy"
    },
    {
        key: 6,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK6.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK6.webp",
        loading: "lazy"
    },
    {
        key: 7,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK7.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK7.webp",
        loading: "lazy"
    },
    {
        key: 8,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK8.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK8.webp",
        loading: "lazy"
    },
    {
        key: 9,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK9.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK9.webp",
        loading: "lazy"
    },

    {
        key: 13,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK9B.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK9B.webp",
        loading: "lazy"
    },


    {
        key: 10,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK10.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK10.webp",
        loading: "lazy"
    },
    {
        key: 11,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK11.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK11.webp",
        loading: "lazy"
    },
    {
        key: 12,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK12.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK12.webp",
        loading: "lazy"
    },
    /*     {
            key: 13,
            srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK12B.jpg",
            srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK12B.webp",
            loading: "lazy"
        }, */
    {
        key: 14,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK13.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK13.webp",
        loading: "lazy"
    },
    {
        key: 15,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK14.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK14.webp",
        loading: "lazy"
    },
    {
        key: 16,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK15.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK15.webp",
        loading: "lazy"
    },
    {
        key: 17,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK16.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK16.webp",
        loading: "lazy"
    },
    {
        key: 18,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK17.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK17.webp",
        loading: "lazy"
    },
    {
        key: 19,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK18.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK18.webp",
        loading: "lazy"
    },
    {
        key: 20,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK19.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK19.webp",
        loading: "lazy"
    },
    {
        key: 21,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK20.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK20.webp",
        loading: "lazy"
    },
    {
        key: 22,
        srcJpeg: "../../content/photos/collections/SS24/XIMONLEE_SS24_LOOK21.jpg",
        srcWebp: "../../content/photos/collections/SS24/webp/XIMONLEE_SS24_LOOK21.webp",
        loading: "lazy"
    },




];


export default ss24CollectionImages;
