import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { sizeChart } from "../js/sizechart.js";

const TopMenuProducts = (props) => {

  const history = useHistory();

  useEffect(() => {
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'auto';
    }
  }, [history]);

  const handleBackButtonClick = () => {
    history.goBack();
  };

  return <ul id="menutop">
    <li className="circle">
      {props.name}
    </li>

  </ul>
}

export default TopMenuProducts; 