export function clothingDetailsOpening() {
    const clothingContainer = document.querySelector("#detailsClothingContainer");
    const backdropBlur = document.querySelector(".backdropblur");
    const backdropBlur02 = document.querySelector(".backdropblur02");
    const sizeChartPicture = document.querySelector(".sizechart");

    if (clothingContainer.style.display === "flex") {
        clothingContainer.style.display = "none";
        backdropBlur.style.display = "none";

    } else {
        clothingContainer.style.display = "flex";
        backdropBlur.style.display = "block";
        sizeChartPicture.style.display = "none";
        backdropBlur02.style.display = "none";
    }
}

