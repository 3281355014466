import React, { useEffect, useState } from "react";

import GlobalMetaTags from "../globalMetaTags";
import InfoItem from "../infoItem";

import LoaderSlider from "../LoaderSlider";
import ShopifyMainComponent from "../ShopifyMainComponent";
import SizeChart from "../sizeChart";
import SwiperInit from "../SwiperInit";
import TopMenuProducts from "../topmenuproducts";

import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Navigation, Keyboard, FreeMode } from "swiper";
import SwiperButtonsPrevNext from "../SwiperButtonsPrevNext";
import BottomMenu from "../MenuBottom";
import DetailsClothingPopUp from "../DetailsClothingPopUp";
import ButtonClothingDetails from "../ButtonClothingDetails";


const ProductsContainer = (props) => {

    /*     const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    
        useEffect(() => {
            const handleResize = () => {
                setIsMobile(window.innerWidth < 768);
            };
    
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);
     */

    return <main>
        <GlobalMetaTags pageTitle={props.pageTitle} />

        <LoaderSlider />

        <TopMenuProducts name={props.name} />
        <InfoItem price={props.price} fabric={props.fabric} />
        <SizeChart src={props.src} />
        <BottomMenu />

        <DetailsClothingPopUp
            textcontent={props.detailsclothingtextcontent}
            buttonShouldHide={props.buttonShouldHide}
        />

        {/*         <ButtonClothingDetails buttonShouldHide={props.buttonShouldHide} />
 */}
        <Swiper modules={[Mousewheel, Navigation, Keyboard, FreeMode]}
            spaceBetween={0}
            freeMode={true}
            slidesPerView={"auto"}
            grabCursor={true}
            loop={true}
            mousewheel={true}
            centeredSlides={true}
            /*      direction={isMobile ? "vertical" : "horizontal"} */

            keyboard={{
                enabled: true,
                onlyInViewport: false,
            }}
            navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            }}>

            {props.children}

            <SwiperButtonsPrevNext />
        </Swiper>
        <ShopifyMainComponent divId={props.divId} mainId={props.mainId} />

    </main>

}

export default ProductsContainer;