import React from "react";

import { AW21 } from "../../../../js/database/database-aw21.js";

import ProductsContainer from "../../../../components/containers/ProductsContainer.js";

import TopMenuProducts from "../../../../components/topmenuproducts";
import SwiperInit from "../../../../components/SwiperInit";
import InfoItem from "../../../../components/infoItem";
import Loader from "../../../../components/loader";

import ShopifyMainComponent from "../../../../components/ShopifyMainComponent";
import GlobalMetaTags from "../../../../components/globalMetaTags";
import SizeChart from "../../../../components/sizeChart.js";


class BodyTankBlack extends React.Component {

    render() {
        return (

            <ProductsContainer
                pageTitle={AW21.tops.BodyTankBlack.itemName}
                name={AW21.tops.BodyTankBlack.itemName}
                price={AW21.tops.BodyTankBlack.itemPrice}
                fabric={AW21.tops.BodyTankBlack.itemFabric}
                src={AW21.tops.BodyTankBlack.sizeChart}
                divId={AW21.tops.BodyTankBlack.shopifyDivId}
                mainId={AW21.tops.BodyTankBlack.shopifyId}>

                {/*    <GlobalMetaTags
                    pageTitle={AW21.tops.BodyTankBlack.itemName}
                />
                <Loader /> 
                <TopMenuProducts
                    name={AW21.tops.BodyTankBlack.itemName}
                />


                <InfoItem
                    price={AW21.tops.BodyTankBlack.itemPrice}
                    fabric={AW21.tops.BodyTankBlack.itemFabric}
                />

                <SizeChart src={AW21.tops.BodyTankBlack.sizeChart} />*/}
                {/*     <div className="swiper-container" id="swiper">
                    <div className="swiper-wrapper"> */}
                <div className="swiper-slide">
                    <img
                        src={AW21.tops.BodyTankBlack.picture01}
                        alt={AW21.alt}
                        width={AW21.imgWidth}
                        height={AW21.imgHeight}
                        loading="lazy"
                    />
                </div>
                <div className="swiper-slide">
                    <img
                        src={AW21.tops.BodyTankBlack.picture02}
                        alt={AW21.alt}
                        width={AW21.imgWidth}
                        height={AW21.imgHeight}
                        loading="lazy"
                    />
                </div>
                <div className="swiper-slide">
                    <img
                        src={AW21.tops.BodyTankBlack.picture03}
                        alt={AW21.alt}
                        width={AW21.imgWidth}
                        height={AW21.imgHeight}
                        loading="lazy"
                    />
                </div>
                <div className="swiper-slide">
                    <img
                        src={AW21.tops.BodyTankBlack.picture04}
                        alt={AW21.alt}
                        width={AW21.imgWidth}
                        height={AW21.imgHeight}
                        loading="lazy"
                    />
                </div>

                {/*      </div>
                </div> */}
                {/*   <ShopifyMainComponent
                    divId={AW21.tops.BodyTankBlack.shopifyDivId}
                    mainId={AW21.tops.BodyTankBlack.shopifyId}
                /> 
                <SwiperInit />*/}
            </ProductsContainer>

        );
    }
}

export default BodyTankBlack;
