import React from "react";
import MetaTags from 'react-meta-tags';

const GlobalMetaTags = (props) => {

    return (

        <MetaTags>
            <title>{props.pageTitle}</title>
        </MetaTags>


        /*  <div id={`product-component-${props.id}`}>
             <div id="sizechartbutton" onClick={sizeChart}>
                 size chart
             </div>
         </div> */
    )
}

export default GlobalMetaTags;