const pre23CollectionImages = [
    {
        key: 1,
        srcJpeg: "../../content/photos/collections/PRE23/XimonLee_PRE23_LOOK_1.jpg",
        srcWebp: "../../content/photos/collections/PRE23/webp/XimonLee_PRE23_LOOK_1.webp",
        loading: "eager"
    },
    {
        key: 2,
        srcJpeg: "../../content/photos/collections/PRE23/XimonLee_PRE23_LOOK_2.jpg",
        srcWebp: "../../content/photos/collections/PRE23/webp/XimonLee_PRE23_LOOK_2.webp",
        loading: "eager"
    },
    {
        key: 3,
        srcJpeg: "../../content/photos/collections/PRE23/XimonLee_PRE23_LOOK_3.jpg",
        srcWebp: "../../content/photos/collections/PRE23/webp/XimonLee_PRE23_LOOK_3.webp",
        loading: "eager"
    },
    {
        key: 4,
        srcJpeg: "../../content/photos/collections/PRE23/XimonLee_PRE23_LOOK_4.jpg",
        srcWebp: "../../content/photos/collections/PRE23/webp/XimonLee_PRE23_LOOK_4.webp",
        loading: "lazy"
    },
    {
        key: 5,
        srcJpeg: "../../content/photos/collections/PRE23/XimonLee_PRE23_LOOK_5.jpg",
        srcWebp: "../../content/photos/collections/PRE23/webp/XimonLee_PRE23_LOOK_5.webp",
        loading: "lazy"
    },
    {
        key: 6,
        srcJpeg: "../../content/photos/collections/PRE23/XimonLee_PRE23_LOOK_6.jpg",
        srcWebp: "../../content/photos/collections/PRE23/webp/XimonLee_PRE23_LOOK_6.webp",
        loading: "lazy"
    },
    {
        key: 7,
        srcJpeg: "../../content/photos/collections/PRE23/XimonLee_PRE23_LOOK_7.jpg",
        srcWebp: "../../content/photos/collections/PRE23/webp/XimonLee_PRE23_LOOK_7.webp",
        loading: "lazy"
    },
    {
        key: 8,
        srcJpeg: "../../content/photos/collections/PRE23/XimonLee_PRE23_LOOK_8.jpg",
        srcWebp: "../../content/photos/collections/PRE23/webp/XimonLee_PRE23_LOOK_8.webp",
        loading: "lazy"
    },
    {
        key: 9,
        srcJpeg: "../../content/photos/collections/PRE23/XimonLee_PRE23_LOOK_9.jpg",
        srcWebp: "../../content/photos/collections/PRE23/webp/XimonLee_PRE23_LOOK_9.webp",
        loading: "lazy"
    },
    {
        key: 10,
        srcJpeg: "../../content/photos/collections/PRE23/XimonLee_PRE23_LOOK_10.jpg",
        srcWebp: "../../content/photos/collections/PRE23/webp/XimonLee_PRE23_LOOK_10.webp",
        loading: "lazy"
    },
];


export default pre23CollectionImages