const aw24CollectionImages = [
    {
        key: 1,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK1.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK1.webp",
        loading: "eager"
    },
    {
        key: 2,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK2.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK2.webp",
        loading: "eager"
    },
    {
        key: 3,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK3.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK3.webp",
        loading: "eager"
    },

    {
        key: 4,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK4.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK4.webp",
        loading: "lazy"
    },
    {
        key: 5,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK5.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK5.webp",
        loading: "lazy"
    },
    {
        key: 6,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK6.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK6.webp",
        loading: "lazy"
    },
    {
        key: 7,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK7.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK7.webp",
        loading: "lazy"
    },
    {
        key: 8,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK8.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK8.webp",
        loading: "lazy"
    },
    {
        key: 9,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK9.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK9.webp",
        loading: "lazy"
    },




    {
        key: 10,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK10.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK10.webp",
        loading: "lazy"
    },
    {
        key: 11,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK11.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK11.webp",
        loading: "lazy"
    },
    {
        key: 12,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK12.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK12.webp",
        loading: "lazy"
    },

    {
        key: 14,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK13.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK13.webp",
        loading: "lazy"
    },
    {
        key: 15,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK14.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK14.webp",
        loading: "lazy"
    },
    {
        key: 16,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK15.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK15.webp",
        loading: "lazy"
    },
    {
        key: 17,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK16.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK16.webp",
        loading: "lazy"
    },
    {
        key: 18,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK17.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK17.webp",
        loading: "lazy"
    },
    {
        key: 19,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK18.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK18.webp",
        loading: "lazy"
    },
    {
        key: 20,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK19.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK19.webp",
        loading: "lazy"
    },
    {
        key: 21,
        srcJpeg: "../../content/photos/collections/AW24/jpg/XIMONLEE-AW24-LOOK20.jpg",
        srcWebp: "../../content/photos/collections/AW24/webp/XIMONLEE-AW24-LOOK20.webp",
        loading: "lazy"
    },





];


export default aw24CollectionImages;
