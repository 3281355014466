import parse from 'html-react-parser';

export const SS23 = {

    alt: "Ximon Lee SS23 Collection",
    imgWidth: "833",
    imgHeight: "1249",

    jackets: {
        PanelledCoat: {
            order: 2,
            itemName: parse('Panelled coat'),
            itemPrice: parse('<s>680€</s> 475€'),

            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/SS23/02_XL_SS23_Panelled_Coat/XL_SS23_Panelled_Coat_Size_Chart.jpg",

            soldOut: false,


            shopifyDivId: "1673345094901",
            shopifyId: "8089388941576",
            link: "/panelled-coat",
            picture01: "../content/photos/eshop/SS23/02_XL_SS23_Panelled_Coat/XL_SS23_Panelled_Coat_01.jpg",
            picture02: "../content/photos/eshop/SS23/02_XL_SS23_Panelled_Coat/XL_SS23_Panelled_Coat_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/02_XL_SS23_Panelled_Coat/XL_SS23_Panelled_Coat_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/02_XL_SS23_Panelled_Coat/XL_SS23_Panelled_Coat_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/02_XL_SS23_Panelled_Coat/XL_SS23_Panelled_Coat_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/02_XL_SS23_Panelled_Coat/XL_SS23_Panelled_Coat_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS23/02_XL_SS23_Panelled_Coat/XL_SS23_Panelled_Coat_05.jpg",
                    loading: "eager"
                },
                {
                    key: 6,
                    srcJpeg: "../content/photos/eshop/SS23/02_XL_SS23_Panelled_Coat/XL_SS23_Panelled_Coat_06.jpg",
                    loading: "eager"
                },
            ],
        },
        ChainCuffLeatherJacket: {
            order: 3,
            itemName: parse('<s>Chain Cuff Leather jacket</s>'),
            itemPrice: parse('<s>870€</s> 653€'),
            itemFabric: "body · leather 100%/lining · polyester 48% viscose 52%",
            sizeChart: "../content/photos/eshop/SS23/03_XL_SS23_Chain_Cuff_Leather_Jacket/XL_SS23_Chain_Cuff_Leather_Jacket_Size_Chart.jpg",


            soldOut: true,


            shopifyDivId: "1673344798019",
            shopifyId: "8086599467272",
            link: "/chain-cuff-leather-jacket",
            picture01: "../content/photos/eshop/SS23/03_XL_SS23_Chain_Cuff_Leather_Jacket/XL_SS23_Chain_Cuff_Leather_Jacket_01.jpg",
            picture02: "../content/photos/eshop/SS23/03_XL_SS23_Chain_Cuff_Leather_Jacket/XL_SS23_Chain_Cuff_Leather_Jacket_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/03_XL_SS23_Chain_Cuff_Leather_Jacket/XL_SS23_Chain_Cuff_Leather_Jacket_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/03_XL_SS23_Chain_Cuff_Leather_Jacket/XL_SS23_Chain_Cuff_Leather_Jacket_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/03_XL_SS23_Chain_Cuff_Leather_Jacket/XL_SS23_Chain_Cuff_Leather_Jacket_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/03_XL_SS23_Chain_Cuff_Leather_Jacket/XL_SS23_Chain_Cuff_Leather_Jacket_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS23/03_XL_SS23_Chain_Cuff_Leather_Jacket/XL_SS23_Chain_Cuff_Leather_Jacket_05.jpg",
                    loading: "eager"
                },
                {
                    key: 6,
                    srcJpeg: "../content/photos/eshop/SS23/03_XL_SS23_Chain_Cuff_Leather_Jacket/XL_SS23_Chain_Cuff_Leather_Jacket_06.jpg",
                    loading: "eager"
                },
            ],
        },
        PanelledLeatherCoat: {
            order: 8,
            itemName: parse('Panelled Leather coat'),
            itemPrice: parse('<s>920€</s> 690€'),
            itemFabric: "leather 100%",
            sizeChart: "../content/photos/eshop/SS23/08_XL_SS23_Panelled_Leather_Coat/XL_SS23_Panelled_Leather_Coat_Size_Chart.jpg",

            soldOut: false,
            shopifyDivId: "1673345356619",
            shopifyId: "8089638895880",
            link: "/panelled-leather-coat",
            picture01: "../content/photos/eshop/SS23/08_XL_SS23_Panelled_Leather_Coat/XL_SS23_Panelled_Leather_Coat_01.jpg",
            picture02: "../content/photos/eshop/SS23/08_XL_SS23_Panelled_Leather_Coat/XL_SS23_Panelled_Leather_Coat_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/08_XL_SS23_Panelled_Leather_Coat/XL_SS23_Panelled_Leather_Coat_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/08_XL_SS23_Panelled_Leather_Coat/XL_SS23_Panelled_Leather_Coat_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/08_XL_SS23_Panelled_Leather_Coat/XL_SS23_Panelled_Leather_Coat_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/08_XL_SS23_Panelled_Leather_Coat/XL_SS23_Panelled_Leather_Coat_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS23/08_XL_SS23_Panelled_Leather_Coat/XL_SS23_Panelled_Leather_Coat_05.jpg",
                    loading: "eager"
                },
                {
                    key: 6,
                    srcJpeg: "../content/photos/eshop/SS23/08_XL_SS23_Panelled_Leather_Coat/XL_SS23_Panelled_Leather_Coat_06.jpg",
                    loading: "eager"
                },
            ],
        },
        NylonBomberVest: {
            order: "?",
            itemName: parse('<s>Nylon Bomber vest</s>'),
            itemPrice: parse('530€'),
            itemFabric: "nylon 100%",
            sizeChart: "../content/photos/eshop/SS23/XL_SS23_Nylon_Bomber_Vest/XL_SS23_Nylon_Bomber_Vest_Size_Chart.jpg",

            soldOut: true,

            shopifyDivId: "1673691771462",
            shopifyId: "8089725731080",
            link: "/nylon-bomber-vest",
            picture01: "../content/photos/eshop/SS23/XL_SS23_Nylon_Bomber_Vest/XL_SS23_Nylon_Bomber_Vest_01.jpg",
            picture02: "../content/photos/eshop/SS23/XL_SS23_Nylon_Bomber_Vest/XL_SS23_Nylon_Bomber_Vest_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/XL_SS23_Nylon_Bomber_Vest/XL_SS23_Nylon_Bomber_Vest_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/XL_SS23_Nylon_Bomber_Vest/XL_SS23_Nylon_Bomber_Vest_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/XL_SS23_Nylon_Bomber_Vest/XL_SS23_Nylon_Bomber_Vest_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/XL_SS23_Nylon_Bomber_Vest/XL_SS23_Nylon_Bomber_Vest_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS23/XL_SS23_Nylon_Bomber_Vest/XL_SS23_Nylon_Bomber_Vest_05.jpg",
                    loading: "eager"
                },
            ],
        },


    },
    tops: {
        SleazyNetKnitGreen: {
            order: 1,
            itemName: parse('<s>Sleazy Net knit</s>'),
            itemPrice: parse('390€'),
            itemFabric: "mohair 100%",
            sizeChart: "../content/photos/eshop/SS23/01_XL_SS23_Sleazy_Net_Knit_Green/XL_SS23_Sleazy_Net_Knit_Green_Size_Chart.jpg",

            soldOut: true,

            shopifyDivId: "1673345668558",
            shopifyId: "8089382519048",
            link: "/sleazy-net-knit-green",
            picture01: "../content/photos/eshop/SS23/01_XL_SS23_Sleazy_Net_Knit_Green/XL_SS23_Sleazy_Net_Knit_Green_01.jpg",
            picture02: "../content/photos/eshop/SS23/01_XL_SS23_Sleazy_Net_Knit_Green/XL_SS23_Sleazy_Net_Knit_Green_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/01_XL_SS23_Sleazy_Net_Knit_Green/XL_SS23_Sleazy_Net_Knit_Green_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/01_XL_SS23_Sleazy_Net_Knit_Green/XL_SS23_Sleazy_Net_Knit_Green_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/01_XL_SS23_Sleazy_Net_Knit_Green/XL_SS23_Sleazy_Net_Knit_Green_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/01_XL_SS23_Sleazy_Net_Knit_Green/XL_SS23_Sleazy_Net_Knit_Green_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS23/01_XL_SS23_Sleazy_Net_Knit_Green/XL_SS23_Sleazy_Net_Knit_Green_05.jpg",
                    loading: "eager"
                },
            ],
        },
        LeatherBondedCableKnitVest: {
            order: 4,
            itemName: parse('<s>Leather Bonded Cable Knit vest</s>'),
            itemPrice: parse('<s>890€</s> 668€'),
            itemFabric: "body · leather 100%/knit · 100% cotton",
            sizeChart: "../content/photos/eshop/SS23/04_XL_SS23_Leather_Bonded_Cable_Knit_Vest/XL_SS23_Leather_Bonded_Cable_Knit_Vest_Size_Chart.jpg",

            soldOut: true,
            shopifyDivId: "1673346069324",
            shopifyId: "8089452216584",
            link: "/leather-bonded-cable-knit-vest",
            picture01: "../content/photos/eshop/SS23/04_XL_SS23_Leather_Bonded_Cable_Knit_Vest/XL_SS23_Leather_Bonded_Cable_Knit_Vest_01.jpg",
            picture02: "../content/photos/eshop/SS23/04_XL_SS23_Leather_Bonded_Cable_Knit_Vest/XL_SS23_Leather_Bonded_Cable_Knit_Vest_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/04_XL_SS23_Leather_Bonded_Cable_Knit_Vest/XL_SS23_Leather_Bonded_Cable_Knit_Vest_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/04_XL_SS23_Leather_Bonded_Cable_Knit_Vest/XL_SS23_Leather_Bonded_Cable_Knit_Vest_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/04_XL_SS23_Leather_Bonded_Cable_Knit_Vest/XL_SS23_Leather_Bonded_Cable_Knit_Vest_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/04_XL_SS23_Leather_Bonded_Cable_Knit_Vest/XL_SS23_Leather_Bonded_Cable_Knit_Vest_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS23/04_XL_SS23_Leather_Bonded_Cable_Knit_Vest/XL_SS23_Leather_Bonded_Cable_Knit_Vest_05.jpg",
                    loading: "eager"
                },
            ],
        },
        LeatherVest: {
            order: 6,
            itemName: parse('<s>Leather vest</s>'),
            itemPrice: parse('475€'),
            itemFabric: "leather 100%",
            sizeChart: "../content/photos/eshop/SS23/06_XL_SS23_Leather_Vest/XL_SS23_Leather_Vest_Size_Chart.jpg",

            soldOut: true,

            shopifyDivId: "1673346238052",
            shopifyId: "8089624805640",
            link: "/leather-vest",
            picture01: "../content/photos/eshop/SS23/06_XL_SS23_Leather_Vest/XL_SS23_Leather_Vest_01.jpg",
            picture02: "../content/photos/eshop/SS23/06_XL_SS23_Leather_Vest/XL_SS23_Leather_Vest_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/06_XL_SS23_Leather_Vest/XL_SS23_Leather_Vest_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/06_XL_SS23_Leather_Vest/XL_SS23_Leather_Vest_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/06_XL_SS23_Leather_Vest/XL_SS23_Leather_Vest_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/06_XL_SS23_Leather_Vest/XL_SS23_Leather_Vest_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS23/06_XL_SS23_Leather_Vest/XL_SS23_Leather_Vest_05.jpg",
                    loading: "eager"
                },
            ],
        },
        GatheredSleeveShirtRedBrown: {
            order: 10,
            itemName: parse('Gathered Sleeve shirt'),
            itemPrice: parse('<s>490€</s> 245€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/SS23/10_XL_SS23_Gathered_Sleeve_Shirt_Red_Brown/XL_SS23_Gathered_Sleeve_Shirt_Red_Brown_Size_Chart.jpg",

            soldOut: false,

            shopifyDivId: "1673346451929",
            shopifyId: "8089646235912",
            link: "/gathered-sleeve-shirt-red-brown",
            picture01: "../content/photos/eshop/SS23/10_XL_SS23_Gathered_Sleeve_Shirt_Red_Brown/XL_SS23_Gathered_Sleeve_Shirt_Red_Brown_01.jpg",
            picture02: "../content/photos/eshop/SS23/10_XL_SS23_Gathered_Sleeve_Shirt_Red_Brown/XL_SS23_Gathered_Sleeve_Shirt_Red_Brown_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/10_XL_SS23_Gathered_Sleeve_Shirt_Red_Brown/XL_SS23_Gathered_Sleeve_Shirt_Red_Brown_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/10_XL_SS23_Gathered_Sleeve_Shirt_Red_Brown/XL_SS23_Gathered_Sleeve_Shirt_Red_Brown_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/10_XL_SS23_Gathered_Sleeve_Shirt_Red_Brown/XL_SS23_Gathered_Sleeve_Shirt_Red_Brown_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/10_XL_SS23_Gathered_Sleeve_Shirt_Red_Brown/XL_SS23_Gathered_Sleeve_Shirt_Red_Brown_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS23/10_XL_SS23_Gathered_Sleeve_Shirt_Red_Brown/XL_SS23_Gathered_Sleeve_Shirt_Red_Brown_05.jpg",
                    loading: "eager"
                },
            ],
        },
        RibKnitVest: {
            order: 12,
            itemName: "Rib Knit vest",
            itemPrice: parse('<s>330€</s> 200€'),
            itemFabric: "tencell 100%",
            sizeChart: "../content/photos/eshop/SS23/12_XL_SS23_Rib_Knit_Vest/XL_SS23_Rib_Knit_Vest_Size_Chart.jpg",

            shopifyDivId: "1673346722311",
            shopifyId: "8089652068616",
            link: "/rib-knit-vest",
            picture01: "../content/photos/eshop/SS23/12_XL_SS23_Rib_Knit_Vest/XL_SS23_Rib_Knit_Vest_01.jpg",
            picture02: "../content/photos/eshop/SS23/12_XL_SS23_Rib_Knit_Vest/XL_SS23_Rib_Knit_Vest_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/12_XL_SS23_Rib_Knit_Vest/XL_SS23_Rib_Knit_Vest_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/12_XL_SS23_Rib_Knit_Vest/XL_SS23_Rib_Knit_Vest_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/12_XL_SS23_Rib_Knit_Vest/XL_SS23_Rib_Knit_Vest_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/12_XL_SS23_Rib_Knit_Vest/XL_SS23_Rib_Knit_Vest_04.jpg",
                    loading: "eager"
                },

            ],
        },
        SleazyNetKnitApricot: {
            order: 13,
            itemName: parse('<s>Sleazy Net Knit</s>'),
            itemPrice: parse('390€'),
            itemFabric: "mohair 100%",
            sizeChart: "../content/photos/eshop/SS23/13_XL_SS23_Sleazy_Net_Knit_Apricot/XL_SS23_Sleazy_Net_Knit_Apricot_Size_Chart.jpg",

            soldOut: true,

            shopifyDivId: "1673346992154",
            shopifyId: "8089654788360",
            link: "/sleazy-net-knit-apricot",
            picture01: "../content/photos/eshop/SS23/13_XL_SS23_Sleazy_Net_Knit_Apricot/XL_SS23_Sleazy_Net_Knit_Apricot_01.jpg",
            picture02: "../content/photos/eshop/SS23/13_XL_SS23_Sleazy_Net_Knit_Apricot/XL_SS23_Sleazy_Net_Knit_Apricot_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/13_XL_SS23_Sleazy_Net_Knit_Apricot/XL_SS23_Sleazy_Net_Knit_Apricot_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/13_XL_SS23_Sleazy_Net_Knit_Apricot/XL_SS23_Sleazy_Net_Knit_Apricot_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/13_XL_SS23_Sleazy_Net_Knit_Apricot/XL_SS23_Sleazy_Net_Knit_Apricot_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/13_XL_SS23_Sleazy_Net_Knit_Apricot/XL_SS23_Sleazy_Net_Knit_Apricot_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS23/13_XL_SS23_Sleazy_Net_Knit_Apricot/XL_SS23_Sleazy_Net_Knit_Apricot_05.jpg",
                    loading: "eager"
                },
            ],
        },


        GatheredSleeveShirtWhite: {
            order: 15,
            itemName: "Gathered Sleeve Shirt",
            itemPrice: parse('<s>490€</s> 300€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/SS23/15_XL_SS23_Gathered_Sleeve_Shirt_White/XL_SS23_Gathered_Sleeve_Shirt_White_Size_Chart.jpg",

            shopifyDivId: "1673353951788",
            shopifyId: "8089660489992",
            link: "/gathered-sleeve-shirt-white",
            picture01: "../content/photos/eshop/SS23/15_XL_SS23_Gathered_Sleeve_Shirt_White/XL_SS23_Gathered_Sleeve_Shirt_White_01.jpg",
            picture02: "../content/photos/eshop/SS23/15_XL_SS23_Gathered_Sleeve_Shirt_White/XL_SS23_Gathered_Sleeve_Shirt_White_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/15_XL_SS23_Gathered_Sleeve_Shirt_White/XL_SS23_Gathered_Sleeve_Shirt_White_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/15_XL_SS23_Gathered_Sleeve_Shirt_White/XL_SS23_Gathered_Sleeve_Shirt_White_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/15_XL_SS23_Gathered_Sleeve_Shirt_White/XL_SS23_Gathered_Sleeve_Shirt_White_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/15_XL_SS23_Gathered_Sleeve_Shirt_White/XL_SS23_Gathered_Sleeve_Shirt_White_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS23/15_XL_SS23_Gathered_Sleeve_Shirt_White/XL_SS23_Gathered_Sleeve_Shirt_White_05.jpg",
                    loading: "eager"
                },
                {
                    key: 6,
                    srcJpeg: "../content/photos/eshop/SS23/15_XL_SS23_Gathered_Sleeve_Shirt_White/XL_SS23_Gathered_Sleeve_Shirt_White_06.jpg",
                    loading: "eager"
                },
            ],
        },
        VestDress: {
            order: 17,
            itemName: parse('<s>Vest dress</s>'),
            itemPrice: parse('<s>460€</s> 230€'),
            itemFabric: "wool 60% · mohair 40%",
            sizeChart: "../content/photos/eshop/SS23/17_XL_SS23_Vest_Dress/XL_SS23_Vest_Dress_Size_Chart.jpg",

            soldOut: true,

            shopifyDivId: "1673354119696",
            shopifyId: "8089695879432",
            link: "/vest-dress",
            picture01: "../content/photos/eshop/SS23/17_XL_SS23_Vest_Dress/XL_SS23_Vest_Dress_01.jpg",
            picture02: "../content/photos/eshop/SS23/17_XL_SS23_Vest_Dress/XL_SS23_Vest_Dress_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/17_XL_SS23_Vest_Dress/XL_SS23_Vest_Dress_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/17_XL_SS23_Vest_Dress/XL_SS23_Vest_Dress_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/17_XL_SS23_Vest_Dress/XL_SS23_Vest_Dress_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/17_XL_SS23_Vest_Dress/XL_SS23_Vest_Dress_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS23/17_XL_SS23_Vest_Dress/XL_SS23_Vest_Dress_05.jpg",
                    loading: "eager"
                },
                {
                    key: 6,
                    srcJpeg: "../content/photos/eshop/SS23/17_XL_SS23_Vest_Dress/XL_SS23_Vest_Dress_06.jpg",
                    loading: "eager"
                },
            ],
        },
        PhotographicPrintTee: {
            order: 18,
            itemName: parse('Photographic Print tee'),
            itemPrice: parse('185€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/SS23/18_XL_SS23_Photographic_Print_Tee/XL_SS23_Photographic_Print_Tee_Size_Chart.jpg",

            soldOut: true,

            shopifyDivId: "1673354325396",
            shopifyId: "8089707938056",
            link: "/photographic-print-tee",
            picture01: "../content/photos/eshop/SS23/18_XL_SS23_Photographic_Print_Tee/XL_SS23_Photographic_Print_Tee_01.jpg",
            picture02: "../content/photos/eshop/SS23/18_XL_SS23_Photographic_Print_Tee/XL_SS23_Photographic_Print_Tee_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/18_XL_SS23_Photographic_Print_Tee/XL_SS23_Photographic_Print_Tee_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/18_XL_SS23_Photographic_Print_Tee/XL_SS23_Photographic_Print_Tee_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/18_XL_SS23_Photographic_Print_Tee/XL_SS23_Photographic_Print_Tee_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/18_XL_SS23_Photographic_Print_Tee/XL_SS23_Photographic_Print_Tee_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS23/18_XL_SS23_Photographic_Print_Tee/XL_SS23_Photographic_Print_Tee_05.jpg",
                    loading: "eager"
                },
            ],
        },
        PanelledTee: {
            order: 19,
            itemName: "Panelled tee",
            itemPrice: parse('<s>185€</s> 100€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/SS23/19_XL_SS23_Panelled_Tee/XL_SS23_Panelled_Tee_Size_Chart.jpg",

            shopifyDivId: "1673354529413",
            shopifyId: "8089713869064",
            link: "/panelled-tee",
            picture01: "../content/photos/eshop/SS23/19_XL_SS23_Panelled_Tee/XL_SS23_Panelled_Tee_01.jpg",
            picture02: "../content/photos/eshop/SS23/19_XL_SS23_Panelled_Tee/XL_SS23_Panelled_Tee_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/19_XL_SS23_Panelled_Tee/XL_SS23_Panelled_Tee_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/19_XL_SS23_Panelled_Tee/XL_SS23_Panelled_Tee_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/19_XL_SS23_Panelled_Tee/XL_SS23_Panelled_Tee_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/19_XL_SS23_Panelled_Tee/XL_SS23_Panelled_Tee_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS23/19_XL_SS23_Panelled_Tee/XL_SS23_Panelled_Tee_05.jpg",
                    loading: "eager"
                },
            ],
        },
        GatheredSleeveShirtDarkBrown: {
            order: 20,
            itemName: "Gathered Sleeve Shirt",
            itemPrice: parse('<s>490€</s> 300€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/SS23/20_XL_SS23_Gathered_Sleeve_Shirt_Dark_Brown/XL_SS23_Gathered_Sleeve_Shirt_Dark_Brown_Size_Chart.jpg",

            shopifyDivId: "1673354712912",
            shopifyId: "8089716818184",
            link: "/gathered-sleeve-shirt-dark-brown",
            picture01: "../content/photos/eshop/SS23/20_XL_SS23_Gathered_Sleeve_Shirt_Dark_Brown/XL_SS23_Gathered_Sleeve_Shirt_Dark_Brown_01.jpg",
            picture02: "../content/photos/eshop/SS23/20_XL_SS23_Gathered_Sleeve_Shirt_Dark_Brown/XL_SS23_Gathered_Sleeve_Shirt_Dark_Brown_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/20_XL_SS23_Gathered_Sleeve_Shirt_Dark_Brown/XL_SS23_Gathered_Sleeve_Shirt_Dark_Brown_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/20_XL_SS23_Gathered_Sleeve_Shirt_Dark_Brown/XL_SS23_Gathered_Sleeve_Shirt_Dark_Brown_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/20_XL_SS23_Gathered_Sleeve_Shirt_Dark_Brown/XL_SS23_Gathered_Sleeve_Shirt_Dark_Brown_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/20_XL_SS23_Gathered_Sleeve_Shirt_Dark_Brown/XL_SS23_Gathered_Sleeve_Shirt_Dark_Brown_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS23/20_XL_SS23_Gathered_Sleeve_Shirt_Dark_Brown/XL_SS23_Gathered_Sleeve_Shirt_Dark_Brown_05.jpg",
                    loading: "eager"
                },
            ],
        },
        SleazyNetKnitBlack: {
            order: 21,
            itemName: parse('<s>Sleazy Net knit</s>'),
            itemPrice: parse('390€'),
            itemFabric: "mohair 100%",
            sizeChart: "../content/photos/eshop/SS23/21_XL_SS23_Sleazy_Net_Knit_Black/XL_SS23_Sleazy_Net_Knit_Black_Size_Chart.jpg",

            soldOut: true,

            shopifyDivId: "1673354891832",
            shopifyId: "8089718817032",
            link: "/sleazy-net-knit-black",
            picture01: "../content/photos/eshop/SS23/21_XL_SS23_Sleazy_Net_Knit_Black/XL_SS23_Sleazy_Net_Knit_Black_01.jpg",
            picture02: "../content/photos/eshop/SS23/21_XL_SS23_Sleazy_Net_Knit_Black/XL_SS23_Sleazy_Net_Knit_Black_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/21_XL_SS23_Sleazy_Net_Knit_Black/XL_SS23_Sleazy_Net_Knit_Black_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/21_XL_SS23_Sleazy_Net_Knit_Black/XL_SS23_Sleazy_Net_Knit_Black_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/21_XL_SS23_Sleazy_Net_Knit_Black/XL_SS23_Sleazy_Net_Knit_Black_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/21_XL_SS23_Sleazy_Net_Knit_Black/XL_SS23_Sleazy_Net_Knit_Black_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS23/21_XL_SS23_Sleazy_Net_Knit_Black/XL_SS23_Sleazy_Net_Knit_Black_05.jpg",
                    loading: "eager"
                },
            ],
        },
        CowhideVest: {
            order: "?",
            itemName: parse('<s>Cowhide Vest</s>'),
            itemPrice: parse('<s>495€</s> 250€'),
            itemFabric: "leather 100%",
            sizeChart: "../content/photos/eshop/SS23/XL_SS23_Cowhide_Vest/XL_SS23_Cowhide_Vest_Size_Chart.jpg",

            soldOut: true,

            shopifyDivId: "1673691429794",
            shopifyId: "8089723699464",
            link: "/cowhide-vest",
            picture01: "../content/photos/eshop/SS23/XL_SS23_Cowhide_Vest/XL_SS23_Cowhide_Vest_01.jpg",
            picture02: "../content/photos/eshop/SS23/XL_SS23_Cowhide_Vest/XL_SS23_Cowhide_Vest_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/XL_SS23_Cowhide_Vest/XL_SS23_Cowhide_Vest_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/XL_SS23_Cowhide_Vest/XL_SS23_Cowhide_Vest_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/XL_SS23_Cowhide_Vest/XL_SS23_Cowhide_Vest_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/XL_SS23_Cowhide_Vest/XL_SS23_Cowhide_Vest_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS23/XL_SS23_Cowhide_Vest/XL_SS23_Cowhide_Vest_05.jpg",
                    loading: "eager"
                },
            ],
        },

    },
    pants: {
        ChainlockBikerPants: {
            order: 5,
            itemName: "Chainlock Biker pants",
            itemPrice: parse('<s>465€</s> 280€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/SS23/05_XL_SS23_Chainlock_Biker_Pants/XL_SS23_Chainlock_Biker_Pants_Size_Chart.jpg",


            shopifyDivId: "1673356716747",
            shopifyId: "8089454772488",
            link: "/chainlock-biker-pants",
            picture01: "../content/photos/eshop/SS23/05_XL_SS23_Chainlock_Biker_Pants/XL_SS23_Chainlock_Biker_Pants_01.jpg",
            picture02: "../content/photos/eshop/SS23/05_XL_SS23_Chainlock_Biker_Pants/XL_SS23_Chainlock_Biker_Pants_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/05_XL_SS23_Chainlock_Biker_Pants/XL_SS23_Chainlock_Biker_Pants_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/05_XL_SS23_Chainlock_Biker_Pants/XL_SS23_Chainlock_Biker_Pants_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/05_XL_SS23_Chainlock_Biker_Pants/XL_SS23_Chainlock_Biker_Pants_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/05_XL_SS23_Chainlock_Biker_Pants/XL_SS23_Chainlock_Biker_Pants_04.jpg",
                    loading: "eager"
                },

            ],
        },
        TyreSkirt: {
            order: 7,
            itemName: parse('<s>Tyre skirt</s>'),
            itemPrice: parse('380€'),
            itemFabric: "body · leather 100%/lining · polyester 48% viscose 52%",
            sizeChart: "../content/photos/eshop/SS23/07_XL_SS23_Tyre_Skirt/XL_SS23_Tyre_Skirt_Size_Chart.jpg",

            soldOut: true,


            shopifyDivId: "1673356936274",
            shopifyId: "8089636700424",
            link: "/tyre-skirt",
            picture01: "../content/photos/eshop/SS23/07_XL_SS23_Tyre_Skirt/XL_SS23_Tyre_Skirt_01.jpg",
            picture02: "../content/photos/eshop/SS23/07_XL_SS23_Tyre_Skirt/XL_SS23_Tyre_Skirt_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/07_XL_SS23_Tyre_Skirt/XL_SS23_Tyre_Skirt_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/07_XL_SS23_Tyre_Skirt/XL_SS23_Tyre_Skirt_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/07_XL_SS23_Tyre_Skirt/XL_SS23_Tyre_Skirt_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/07_XL_SS23_Tyre_Skirt/XL_SS23_Tyre_Skirt_04.jpg",
                    loading: "eager"
                },

            ],
        },
        GatheredLeatherPants: {
            order: 9,
            itemName: "Gathered Leather pants",
            itemPrice: parse('<s>470€</s> 290€'),
            itemFabric: "leather 100%",
            sizeChart: "../content/photos/eshop/SS23/09_XL_SS23_Gathered_Leather_Pants/XL_SS23_Gathered_Leather_Pants_Size_Chart.jpg",

            shopifyDivId: "1673357167109",
            shopifyId: "8089641845000",
            link: "/gathered-leather-pants",
            picture01: "../content/photos/eshop/SS23/09_XL_SS23_Gathered_Leather_Pants/XL_SS23_Gathered_Leather_Pants_01.jpg",
            picture02: "../content/photos/eshop/SS23/09_XL_SS23_Gathered_Leather_Pants/XL_SS23_Gathered_Leather_Pants_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/09_XL_SS23_Gathered_Leather_Pants/XL_SS23_Gathered_Leather_Pants_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/09_XL_SS23_Gathered_Leather_Pants/XL_SS23_Gathered_Leather_Pants_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/09_XL_SS23_Gathered_Leather_Pants/XL_SS23_Gathered_Leather_Pants_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/09_XL_SS23_Gathered_Leather_Pants/XL_SS23_Gathered_Leather_Pants_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS23/09_XL_SS23_Gathered_Leather_Pants/XL_SS23_Gathered_Leather_Pants_05.jpg",
                    loading: "eager"
                },
                {
                    key: 6,
                    srcJpeg: "../content/photos/eshop/SS23/09_XL_SS23_Gathered_Leather_Pants/XL_SS23_Gathered_Leather_Pants_06.jpg",
                    loading: "eager"
                },

            ],
        },
        ChainlockLeatherBikerPants: {
            order: 11,
            itemName: parse('<s>Chainlock Leather Biker pants</s>'),
            itemPrice: parse('650€'),
            itemFabric: "leather 100%",
            sizeChart: "../content/photos/eshop/SS23/11_XL_SS23_Chainlock_Leather_Biker_Pants/XL_SS23_Chainlock_Leather_Biker_Pants_Size_Chart.jpg",

            soldOut: true,

            shopifyDivId: "1673357523481",
            shopifyId: "8089649545480",
            link: "/chainlock-leather-biker-pants",
            picture01: "../content/photos/eshop/SS23/11_XL_SS23_Chainlock_Leather_Biker_Pants/XL_SS23_Chainlock_Leather_Biker_Pants_01.jpg",
            picture02: "../content/photos/eshop/SS23/11_XL_SS23_Chainlock_Leather_Biker_Pants/XL_SS23_Chainlock_Leather_Biker_Pants_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/11_XL_SS23_Chainlock_Leather_Biker_Pants/XL_SS23_Chainlock_Leather_Biker_Pants_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/11_XL_SS23_Chainlock_Leather_Biker_Pants/XL_SS23_Chainlock_Leather_Biker_Pants_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/11_XL_SS23_Chainlock_Leather_Biker_Pants/XL_SS23_Chainlock_Leather_Biker_Pants_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/11_XL_SS23_Chainlock_Leather_Biker_Pants/XL_SS23_Chainlock_Leather_Biker_Pants_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS23/11_XL_SS23_Chainlock_Leather_Biker_Pants/XL_SS23_Chainlock_Leather_Biker_Pants_05.jpg",
                    loading: "eager"
                },
                {
                    key: 6,
                    srcJpeg: "../content/photos/eshop/SS23/11_XL_SS23_Chainlock_Leather_Biker_Pants/XL_SS23_Chainlock_Leather_Biker_Pants_06.jpg",
                    loading: "eager"
                },

            ],
        },
        CowhidePencilSkirt: {
            order: 14,
            itemName: parse('<s>Cowhide Pencil skirt</s>'),
            itemPrice: parse('<s>450€</s> 225€'),
            itemFabric: "leather 100%",
            sizeChart: "../content/photos/eshop/SS23/14_XL_SS23_Cowhide_Pencil_Skirt/XL_SS23_Cowhide_Pencil_Skirt_Size_Chart.jpg",

            soldOut: true,

            shopifyDivId: "1673357749508",
            shopifyId: "8089656557832",
            link: "/cowhide-pencil-skirt",
            picture01: "../content/photos/eshop/SS23/14_XL_SS23_Cowhide_Pencil_Skirt/XL_SS23_Cowhide_Pencil_Skirt_01.jpg",
            picture02: "../content/photos/eshop/SS23/14_XL_SS23_Cowhide_Pencil_Skirt/XL_SS23_Cowhide_Pencil_Skirt_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/14_XL_SS23_Cowhide_Pencil_Skirt/XL_SS23_Cowhide_Pencil_Skirt_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/14_XL_SS23_Cowhide_Pencil_Skirt/XL_SS23_Cowhide_Pencil_Skirt_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/14_XL_SS23_Cowhide_Pencil_Skirt/XL_SS23_Cowhide_Pencil_Skirt_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/14_XL_SS23_Cowhide_Pencil_Skirt/XL_SS23_Cowhide_Pencil_Skirt_04.jpg",
                    loading: "eager"
                },

            ],
        },
        GatheredPants: {
            order: 16,
            itemName: "Gathered pants",
            itemPrice: parse('<s>450€</s> 250€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/SS23/16_XL_SS23_Gathered_Pants/XL_SS23_Gathered_Pants_Size_Chart.jpg",

            shopifyDivId: "1673357918278",
            shopifyId: "8089668452616",
            link: "/gathered-pants",
            picture01: "../content/photos/eshop/SS23/16_XL_SS23_Gathered_Pants/XL_SS23_Gathered_Pants_01.jpg",
            picture02: "../content/photos/eshop/SS23/16_XL_SS23_Gathered_Pants/XL_SS23_Gathered_Pants_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/16_XL_SS23_Gathered_Pants/XL_SS23_Gathered_Pants_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/16_XL_SS23_Gathered_Pants/XL_SS23_Gathered_Pants_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/16_XL_SS23_Gathered_Pants/XL_SS23_Gathered_Pants_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/16_XL_SS23_Gathered_Pants/XL_SS23_Gathered_Pants_04.jpg",
                    loading: "eager"
                },

            ],
        },
        ChainlockPencilSkirt: {
            order: "?",
            itemName: "Chainlock Pencil skirt",
            itemPrice: parse('<s>560€</s> 300€'),
            itemFabric: "leather 100%",
            sizeChart: "../content/photos/eshop/SS23/XL_SS23_Chainlock_Pencil_Skirt/XL_SS23_Chainlock_Pencil_Skirt_Size_Chart.jpg",

            shopifyDivId: "1673691956792",
            shopifyId: "8089721176328",
            link: "/chainlock-pencil-skirt",
            picture01: "../content/photos/eshop/SS23/XL_SS23_Chainlock_Pencil_Skirt/XL_SS23_Chainlock_Pencil_Skirt_01.jpg",
            picture02: "../content/photos/eshop/SS23/XL_SS23_Chainlock_Pencil_Skirt/XL_SS23_Chainlock_Pencil_Skirt_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS23/XL_SS23_Chainlock_Pencil_Skirt/XL_SS23_Chainlock_Pencil_Skirt_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS23/XL_SS23_Chainlock_Pencil_Skirt/XL_SS23_Chainlock_Pencil_Skirt_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS23/XL_SS23_Chainlock_Pencil_Skirt/XL_SS23_Chainlock_Pencil_Skirt_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS23/XL_SS23_Chainlock_Pencil_Skirt/XL_SS23_Chainlock_Pencil_Skirt_04.jpg",
                    loading: "eager"
                },

            ],
        },

    }
}