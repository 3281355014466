import React from "react";
import GlobalMetaTags from "../components/globalMetaTags";
import BottomMenu from "../components/MenuBottom";

import setBodyColor from "../js/setBodyColor";

import LoaderFake from "../components/LoaderFake";

import Loader from "../components/loader";
import ConnectInfo from "../components/ConnectInfo";

const Connect = (props) => {
  setBodyColor({ color: "#ffffff" });

  return (
    <main>
      <GlobalMetaTags pageTitle={"Connect"} />
      <LoaderFake />
      <BottomMenu />

      <div id="connect">
        <ConnectInfo label="general" email="studio@ximonlee.com" />
        <ConnectInfo
          label="press & communication worldwide"
          email="press@ximonlee.com"
        />
        <ConnectInfo
          label="press & communication China"
          email="sx@ximonlee.com"
        />

        <div style={{ color: "white" }}>·</div>

        <ConnectInfo label="sales worldwide" email="sales@ximonlee.com" />
        {/*  <ConnectInfo label="sales China" email="menswear@xcommons.com" /> */}
      </div>

      <div id="credit" className="circle">
        <a
          href="https://ff87c3.eu/"
          rel="noreferrer"
          /*  style={{ textDecoration: "none" }} */
          target="_blank"
        >
          <p>
            site by
            <br />
            ff87c3.eu
          </p>
        </a>
      </div>
    </main>
  );
};

export default Connect;
