import React from "react";
import { sizeChart } from "../js/sizechart.js";

const SizeChart = (props) => {

    return <div>
        <div className="sizechart">
            <p className="close" onClick={sizeChart}>X</p>
            <img
                onClick={sizeChart}
                /*   className="sizechart" */
                src={props.src}
                loading="lazy"
                alt="Size chart"
            />

        </div>
        <div onClick={sizeChart} className="backdropblur02"></div>
    </div>

}


export default SizeChart;