
export function sizeChart() {
    const sizeChartPicture = document.querySelector(".sizechart");
    const backdropBlur02 = document.querySelector(".backdropblur02");
    const backdropBlur = document.querySelector(".backdropblur");
    const clothingContainer = document.querySelector("#detailsClothingContainer");

    if (sizeChartPicture.style.display === "block") {
        sizeChartPicture.style.display = "none";
        backdropBlur02.style.display = "none";

    } else {
        sizeChartPicture.style.display = "block";
        backdropBlur02.style.display = "block";
        clothingContainer.style.display = "none";
        backdropBlur.style.display = "none";

    }
}

