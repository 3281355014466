import React from "react";
import GlobalMetaTags from "../components/globalMetaTags";
import BottomMenu from "../components/MenuBottom";
import setBodyColor from "../js/setBodyColor";
import LoaderFake from "../components/LoaderFake";

const ShippingAndReturn = () => {
  setBodyColor({ color: "#ffffff" });

  return (
    <div>
      <GlobalMetaTags pageTitle={"Connect"} />
      <LoaderFake />
      <BottomMenu />

      <div id="shipping-return-container">
        <p>
          We ship your orders from our warehouse in Guangzhou, China with our
          customer service in Berlin, Germany.
          <br />
          <br />
        </p>
        <p>
          <span className="bold">
            Please allow 3-7 working days for order fulfillment notice and
            tracking information.
          </span>
          <br />
          Upon placing your order, you will receive an order confirmation email.
          Once your order is packed you will receive a second email with
          tracking information. During times of high volume such as new drops,
          please allow 7 working days to receive your tracking information
          email. <br />
          <br />
        </p>
        <p>
          We ship worldwide. All packages are insured with a tracking number at
          a flat rate listed below: <br />
          <br />
          <span className="bold">Shipping Rate</span> <br />
          <br />
          • China: €10 <br />
          • Asia (excl. China): €15 <br />
          • Europe: €20 <br />
          • Rest of the world: €25 <br /> <br />
          We offer free shipping on all orders above €300 and it will be
          automatically applied to your order at checkout.
          <br /> <br />
        </p>

        <p>
          <span className="bold">Delivery period</span>
          <br />
          <br />
          Once package is scanned out of the warehouse the estimated delivery
          period is as below:
          <br />
          <br />
          • China: 1-2 business days <br />
          • Asia (excl. China): 1-3 business days <br />
          • Europe: 3-5 business days <br />
          • Rest of the world: 3-7 business days <br />
          <br />
        </p>
        <p>
          The delivery period is an estimate and is not guaranteed. We will
          always aim to deliver your goods within the estimated delivery period,
          however, please take into account external issues that may arise
          outside of our control. <br />
          If you suspect your package is delayed, lost or stolen, please contact
          <a href="mailto:studio@ximonlee.com"> studio@ximonlee.com</a> with any
          supporting information so that we can best assist you.
          <br />
          <br />
        </p>
        <p>
          <span className="bold">Duties & Taxes</span>
          <br />
          <br />
          For any delivery destinations outside of the China PR, you are
          responsible for Customs/Duty and Import taxes to receive your order.
          Any delay in paying these customs fees will result in a delay of
          delivery of your order.
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </p>
        <p>
          <span className="bold">Return policy</span>
          <br />
          <br />
          You may return within <span className="bold">14 days</span> from the
          date of delivery for a full refund minus shipping cost, on the
          condition that the item is unused, unaltered, unwashed, undamaged and
          in the original packaging with tags still attached. The garments
          should not be missing any accessories such as belts, straps, laces, or
          logo pendant.
          <br />
          <br />
          Items purchased on sale or with any promotions are not eligible for
          return. We do not accept worn returns or underwear, socks, swimming
          wears, earrings and face masks for hygienic reasons. <br />
          <br />
          Where possible, we will either offer an exchange if there is a
          replacement available or offer to repair faulty items. When items
          deemed faulty cannot be repaired or the same item is not available, a
          full refund will be offered and we will arrange for return of the
          faulty item. <br />
          <br />
          To return any item(s) from your order, e-mail us at{" "}
          <a href="mailto:studio@ximonlee.com"> store@ximonlee.com </a>
          with your order number and reference of the item(s) you would like to
          return. Return can be initiated also via Shopify page.
          <br />
          <br />
          Once your return is received, it will undergo a quality check. Please
          allow up to 5 days for this to be completed. Once this process is
          completed, the refund will be automatically applied to your original
          method of payment within 5 working days. <br />
          <br />
          You are responsible for shipping costs associated with returning any
          item. You can either ship yourself directly or the cost of return
          shipping will be deducted from your refund. Customs and duties costs,
          as well as cost of original shipment will not be refunded.
        </p>
      </div>

      <div id="credit" className="circle">
        <a>shipping & returns</a>
      </div>
    </div>
  );
};

export default ShippingAndReturn;
