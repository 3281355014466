import React from "react";

import { SS22 } from "../../../../js/database/database-ss22.js";

import TopMenuProducts from "../../../../components/topmenuproducts";
import ButtonsPrevNext from "../../../../components/buttonsprevnext";
import InfoItem from "../../../../components/infoItem";
import Loader from "../../../../components/loader";
import ShopifyDiv from "../../../../components/shopify-product-component";
import SizeChart from "../../../../components/sizeChart.js";
import BottomMenu from "../../../../components/MenuBottom.js";
import LoaderSlider from "../../../../components/LoaderSlider.js";
class ChainlockLeatherShorts extends React.Component {
  componentDidMount() {
    (function () {
      var scriptURL =
        "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          ShopifyBuyInit();
        } else {
          loadScript();
        }
      } else {
        loadScript();
      }
      function loadScript() {
        var script = document.createElement("script");
        script.async = true;
        script.src = scriptURL;
        (
          document.getElementsByTagName("head")[0] ||
          document.getElementsByTagName("body")[0]
        ).appendChild(script);
        script.onload = ShopifyBuyInit;
      }
      function ShopifyBuyInit() {
        var client = window.ShopifyBuy.buildClient({
          domain: "ximonleestore.myshopify.com",
          storefrontAccessToken: "2f2501adacfa85056fcce3e5398a3ee5",
        });
        window.ShopifyBuy.UI.onReady(client).then(function (ui) {
          ui.createComponent("product", {
            id: `${SS22.pants.chainlockLeatherShorts.shopifyId}`,
            node: document.getElementById(
              `product-component-${SS22.pants.chainlockLeatherShorts.shopifyDivId}`
            ),

            moneyFormat: "%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D",
            options: {
              product: {
                styles: {
                  product: {
                    "@media (min-width: 601px)": {
                      "max-width": "calc(25% - 20px)",
                      "margin-left": "20px",
                      "margin-bottom": "50px",
                    },
                  },

                  button: {
                    "font-size": "13px",
                    padding: "7px",

                    "background-color": "transparent",
                    /*  'padding-left': '10px',
                                         'padding-right': '10px',
                                         'padding-top': '10px',
                                         'padding-bottom': '10px',  */
                    "font-family": "Arial",

                    /*    'border': '1px solid #e0e0e0', */
                    "background-color": "#000000",
                    color: "#ffffff",
                    "border-radius": "5px",

                    ":hover": {
                      "background-color": "#000000",

                      filter: "blur(2px)" /*
                              'color': 'transparent', */,
                    },

                    ":focus": {
                      "background-color": "#c3c3c3",
                    },
                  },
                  quantityInput: {
                    "font-size": "13px",
                    "padding-top": "14.5px",
                    "padding-bottom": "14.5px",
                  },
                },
                contents: {
                  img: false,
                  title: false,
                  price: false,
                },
                text: {
                  button: "Add to cart",
                },
              },
              productSet: {
                styles: {
                  products: {
                    "@media (min-width: 601px)": {
                      "margin-left": "-20px",
                    },
                  },
                },
              },
              modalProduct: {
                contents: {
                  img: false,
                  imgWithCarousel: true,
                  button: false,
                  buttonWithQuantity: true,
                },
                styles: {
                  product: {
                    "@media (min-width: 601px)": {
                      "max-width": "100%",
                      "margin-left": "0px",
                      "margin-bottom": "0px",
                    },
                  },
                  button: {
                    "font-size": "13px",
                    "padding-top": "14.5px",
                    "padding-bottom": "14.5px",
                    ":hover": {
                      "background-color": "#cacaca",
                    },
                    "background-color": "#e0e0e0",
                    ":focus": {
                      "background-color": "#cacaca",
                    },
                    "border-radius": "0px",
                  },
                  quantityInput: {
                    "font-size": "13px",
                    "padding-top": "14.5px",
                    "padding-bottom": "14.5px",
                  },
                },
                text: {
                  button: "Add to cart",
                },
              },

              option: {
                styles: {
                  wrapper: {
                    "border-radius": "5px",
                    "background-color": "#000000",
                  },
                  select: {
                    color: "#ffffff",
                  },
                },
              },

              cart: {
                styles: {
                  button: {
                    "font-size": "13px",
                    "padding-top": "14.5px",
                    "padding-bottom": "14.5px",
                    ":hover": {
                      "background-color": "#cacaca",
                    },
                    "background-color": "#7a7a7a",
                    ":focus": {
                      "background-color": "#cacaca",
                    },
                    "border-radius": "0px",
                  },
                },
                text: {
                  total: "Subtotal",
                  button: "Checkout",
                },
              },
              toggle: {
                styles: {
                  toggle: {
                    "background-color": "#000000",
                    ":hover": {
                      "background-color": "#cacaca",
                    },
                    ":focus": {
                      "background-color": "#cacaca",
                    },
                  },
                  count: {
                    "font-size": "13px",
                  },
                },
              },
            },
          });
        });
      }
    })();
  }

  render() {
    return (
      <div>
        <LoaderSlider />

        <TopMenuProducts name={SS22.pants.chainlockLeatherShorts.itemName} />

        <InfoItem
          price={SS22.pants.chainlockLeatherShorts.itemPrice}
          fabric={SS22.pants.chainlockLeatherShorts.itemFabric}
        />

        <SizeChart src={SS22.pants.chainlockLeatherShorts.sizeChart} />
        <BottomMenu />

        <div className="swiper-container swiper" id="swiper">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <img
                src={SS22.pants.chainlockLeatherShorts.picture01}
                alt={SS22.alt}
                loading="lazy"
              />
            </div>
            <div className="swiper-slide">
              <img
                src={SS22.pants.chainlockLeatherShorts.picture02}
                alt={SS22.alt}
                loading="lazy"
              />
            </div>
            <div className="swiper-slide">
              <img
                src={SS22.pants.chainlockLeatherShorts.picture03}
                alt={SS22.alt}
                loading="lazy"
              />
            </div>
            <div className="swiper-slide">
              <img
                src={SS22.pants.chainlockLeatherShorts.picture04}
                alt={SS22.alt}
                loading="lazy"
              />
            </div>
            <div className="swiper-slide">
              <img
                src={SS22.pants.chainlockLeatherShorts.picture05}
                alt={SS22.alt}
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <ShopifyDiv id={SS22.pants.chainlockLeatherShorts.shopifyDivId} />
        <ButtonsPrevNext />
      </div>
    );
  }
}

export default ChainlockLeatherShorts;
