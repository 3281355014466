import React, { useEffect, useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NavLink } from "react-router-dom"
/* import { eshopLoading } from "../js/eshopLoading";
 */
/* import { IntersectionObserver } from 'react-intersection-observer';
 */
const Cube = (props) => {


    /*   useEffect(() => {
          eshopLoading();
      }); */



    return (



        <NavLink to={props.itemLink}>
            <div className="content">

                {/*     <LazyLoadImage
                    src={props.picture01}
                    height="1250"
                    width="833"
                    effect="blur"
                    useIntersectionObserver="true"
                    alt={props.alt}
                />

                <LazyLoadImage
                    src={props.picture02}
                    height="1250"
                    width="833"
                    className="img-bottom"
                    useIntersectionObserver="true"

                    alt={props.alt}
                />
 */}


                <img src={props.picture01} loading="lazy" alt="Ximon Lee eshop" height="1250"
                    width="833" className="img-top" />
                <img src={props.picture02} loading="lazy" className="img-bottom" alt="Ximon Lee eshop" height="1250"
                    width="833" />

                <p className="text">{props.itemName} · {props.itemPrice}</p>
                {props.soldOut && <p className="sold-out">Sold Out</p>} {/* new */}
            </div>
        </NavLink>

    )
};


export default Cube;