import React, { useEffect } from "react";

import { sliderLoader } from "../js/sliderLoader";

const LoaderSlider = (props) => {

    useEffect(() => {
        sliderLoader();
    }, []);

    return (
        <div style={props.removeLoader} className="loader"><div className="textloader flicker-loader">loading</div></div>
    )
}

export default LoaderSlider;