const aw21CollectionImages = [
    {
        key: 1,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look1.JPG",
        loading: "eager"
    },
    {
        key: 2,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look2.JPG",
        loading: "eager"
    },
    {
        key: 3,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look3.JPG",
        loading: "eager"
    },
    {
        key: 4,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look4.JPG",
        loading: "eager"
    },
    {
        key: 5,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look5.JPG",
        loading: "lazy"
    },
    {
        key: 6,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look6.JPG",
        loading: "lazy"
    },
    {
        key: 7,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look7.JPG",
        loading: "lazy"
    },
    {
        key: 8,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look8.JPG",
        loading: "lazy"
    },
    {
        key: 9,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look9.JPG",
        loading: "lazy"
    },
    {
        key: 10,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look10.JPG",
        loading: "lazy"
    },
    {
        key: 11,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look11.JPG",
        loading: "lazy"
    },
    {
        key: 12,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look12.JPG",
        loading: "lazy"
    },
    {
        key: 13,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look13.JPG",
        loading: "lazy"
    },
    {
        key: 14,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look14.JPG",
        loading: "lazy"
    },
    {
        key: 15,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look15.JPG",
        loading: "lazy"
    },
    {
        key: 16,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look16.JPG",
        loading: "lazy"
    },
    {
        key: 17,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look17.JPG",
        loading: "lazy"
    },
    {
        key: 18,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look18.JPG",
        loading: "lazy"
    },
    {
        key: 19,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look19.JPG",
        loading: "lazy"
    },
    {
        key: 20,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look20.JPG",
        loading: "lazy"
    },
    {
        key: 21,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look21.JPG",
        loading: "lazy"
    },
    {
        key: 22,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look23.JPG",
        loading: "lazy"
    },
    {
        key: 24,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look24.JPG",
        loading: "lazy"
    },
    {
        key: 25,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look25.JPG",
        loading: "lazy"
    },
    {
        key: 26,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look26.JPG",
        loading: "lazy"
    },
    {
        key: 27,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look27.JPG",
        loading: "lazy"
    },
    {
        key: 28,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look28.JPG",
        loading: "lazy"
    },
    {
        key: 29,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look29.JPG",
        loading: "lazy"
    },
    {
        key: 31,
        srcJpeg: "../../content/photos/collections/AW21/ximonlee_aw21_look31.JPG",
        loading: "lazy"
    },


];

export default aw21CollectionImages
