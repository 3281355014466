// import React from "react";

// import { AW22 } from "../../../../js/database/database-aw22.js";

// import TopMenuProducts from "../../../../components/topmenuproducts";
// import SwiperInit from "../../../../components/SwiperInit";
// import InfoItem from "../../../../components/infoItem";
// import Loader from "../../../../components/loader";
// /* import ShopifyDiv from "../../../../components/shopify-product-component";
//  */
// import ShopifyMainComponent from "../../../../components/ShopifyMainComponent";

// import SizeChart from "../../../../components/sizeChart.js";

// class BomberWithRestrainedArms extends React.Component {
//   render() {
//     return (
//       <div>
//         <Loader />
//         <TopMenuProducts
//           name={AW22.jackets.BomberWithRestrainedArms.itemName}
//         />
//         <InfoItem
//           price={AW22.jackets.BomberWithRestrainedArms.itemPrice}
//           fabric={AW22.jackets.BomberWithRestrainedArms.itemFabric}
//         />
//         <SizeChart src={AW22.jackets.BomberWithRestrainedArms.sizeChart} />
//         <div className="swiper-container" id="swiper">
//           <div className="swiper-wrapper">
//             <div className="swiper-slide">
//               <img
//                 src={AW22.jackets.BomberWithRestrainedArms.picture01}
//                 alt={AW22.alt}
//                 loading="lazy"
//               />
//             </div>
//             <div className="swiper-slide">
//               <img
//                 src={AW22.jackets.BomberWithRestrainedArms.picture02}
//                 alt={AW22.alt}
//                 loading="lazy"
//               />
//             </div>
//             <div className="swiper-slide">
//               <img
//                 src={AW22.jackets.BomberWithRestrainedArms.picture03}
//                 alt={AW22.alt}
//                 loading="lazy"
//               />
//             </div>
//             <div className="swiper-slide">
//               <img
//                 src={AW22.jackets.BomberWithRestrainedArms.picture04}
//                 alt={AW22.alt}
//                 loading="lazy"
//               />
//             </div>
//             <div className="swiper-slide">
//               <img
//                 src={AW22.jackets.BomberWithRestrainedArms.picture05}
//                 alt={AW22.alt}
//                 loading="lazy"
//               />
//             </div>
//             <div className="swiper-slide">
//               <img
//                 src={AW22.jackets.BomberWithRestrainedArms.picture06}
//                 alt={AW22.alt}
//                 loading="lazy"
//               />
//             </div>
//           </div>
//         </div>
//         {/*         <ShopifyDiv id={AW22.jackets.BomberWithRestrainedArms.shopifyDivId} />
//          */}{" "}
//         <ShopifyMainComponent
//           divId={AW22.jackets.BomberWithRestrainedArms.shopifyDivId}
//           mainId={AW22.jackets.BomberWithRestrainedArms.shopifyId}
//         />
//         <SwiperInit />
//       </div>
//     );
//   }
// }

// export default BomberWithRestrainedArms;

import React from "react";

import { AW22 } from "../../../../js/database/database-aw22.js";

import ProductsContainer from "../../../../components/containers/ProductsContainer.js";
import { SwiperSlide } from "swiper/react";

const BomberWithRestrainedArms = () => {
  return (
    <ProductsContainer
      pageTitle={AW22.jackets.BomberWithRestrainedArms.itemName}
      name={AW22.jackets.BomberWithRestrainedArms.itemName}
      price={AW22.jackets.BomberWithRestrainedArms.itemPrice}
      fabric={AW22.jackets.BomberWithRestrainedArms.itemFabric}
      src={AW22.jackets.BomberWithRestrainedArms.sizeChart}
      divId={AW22.jackets.BomberWithRestrainedArms.shopifyDivId}
      mainId={AW22.jackets.BomberWithRestrainedArms.shopifyId}
    >
      {React.Children.toArray(
        AW22.jackets.BomberWithRestrainedArms.pictures.map(({ srcJpeg }) => (
          <SwiperSlide>
            <img
              src={srcJpeg}
              alt={AW22.jackets.BomberWithRestrainedArms.itemName}
              width={AW22.imgWidth}
              height={AW22.imgHeight}
              loading={AW22.loading}
            />
          </SwiperSlide>
        ))
      )}
    </ProductsContainer>
  );
};

export default BomberWithRestrainedArms;
