import React from "react";

import { AW22 } from "../../../../js/database/database-aw22.js";

import ProductsContainer from "../../../../components/containers/ProductsContainer.js";
import { SwiperSlide } from "swiper/react";

const BikerJacketWithArmStraps = () => {
  return (
    <ProductsContainer
      pageTitle={AW22.jackets.BikerJacketWithArmStraps.itemName}
      name={AW22.jackets.BikerJacketWithArmStraps.itemName}
      price={AW22.jackets.BikerJacketWithArmStraps.itemPrice}
      fabric={AW22.jackets.BikerJacketWithArmStraps.itemFabric}
      src={AW22.jackets.BikerJacketWithArmStraps.sizeChart}
      divId={AW22.jackets.BikerJacketWithArmStraps.shopifyDivId}
      mainId={AW22.jackets.BikerJacketWithArmStraps.shopifyId}
    >
      {React.Children.toArray(
        AW22.jackets.BikerJacketWithArmStraps.pictures.map(({ srcJpeg }) => (
          <SwiperSlide>
            <img
              src={srcJpeg}
              alt={AW22.jackets.BikerJacketWithArmStraps.itemName}
              width={AW22.imgWidth}
              height={AW22.imgHeight}
              loading={AW22.loading}
            />
          </SwiperSlide>
        ))
      )}
    </ProductsContainer>
  );
};

export default BikerJacketWithArmStraps;
