import React, { useEffect, useState } from "react";
import ss22CollectionImages from "../js/database/database-ss22-collection";

import setBodyColor from "../js/setBodyColor";
import setFontColor from "../js/setFontColor";

import { SwiperSlide } from "swiper/react";

import { sliderLoader } from "../js/sliderLoader";
import MainPagesContainer from "../components/containers/MainPagesContainer";

const alt = "SS22 runway";
const imgWidth = 1000;
const imgHeight = 1500;

const SS22 = (props) => {
  setBodyColor({ color: "#222222" });
  //setFontColor({ color: "#c3c3c3" });

  /*   useEffect(() => {
    const images = document.querySelectorAll("img[data-src]");
    const options = {
      root: null,
      threshold: 0,
      rootMargin: "0px 0px 0px 0px",
    };

    const observer = new IntersectionObserver(function (entries, observer) {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        }
        const img = entry.target;
        img.setAttribute("src", img.getAttribute("data-src"));
        img.removeAttribute("data-src");
        img.style.opacity = 0;
        img.style.filter = "blur(5px)";
        img.addEventListener("load", () => {
          setTimeout(() => {
            img.style.transition =
              "opacity 0.5s ease-in-out, filter 0.5s ease-in-out";
            img.style.opacity = 1;
            img.style.filter = "blur(0px)";
          }, 0);
        });
        observer.unobserve(img);
      });
    }, options);

    images.forEach((img) => {
      observer.observe(img);
    });
  }, []); */

  /*  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const createScript = (url) => {
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      script.onload = () => setScriptLoaded(true);
      document.body.appendChild(script);
    };

    createScript("../js/IntersectionObserverPictures.js");
  }, []); */

  /*   useEffect(() => {
    const scriptSwiper = document.createElement("script");
    scriptSwiper.src = "../js/IntersectionObserverPictures.js";
    scriptSwiper.async = true;
    document.body.appendChild(scriptSwiper);
  }, []); */

  return (
    <MainPagesContainer
      pageTitle={alt}
      title="Video"
      src={"../content/videos/Ximon_Lee_SPRING-SUMMER_2022_full.mp4"}
      collectionsClassName={"circle"}
      PDFshouldHide={true}
    >
      {React.Children.toArray(
        ss22CollectionImages.map(({ srcJpeg, srcWebp, loading }) => (
          <SwiperSlide>
            <picture>
              <source srcSet={srcWebp} type="image/webp" />

              <img
                src={srcJpeg}
                width={imgWidth}
                height={imgHeight}
                alt={alt}
                loading="eager"
              />
            </picture>
          </SwiperSlide>
        ))
      )}
    </MainPagesContainer>
  );
};

export default SS22;
