import React, { useEffect } from "react";

import aw23CollectionImages from "../js/database/database-aw23-collection";

import setBodyColor from "../js/setBodyColor";

import { SwiperSlide } from "swiper/react";

import MainPagesContainer from "../components/containers/MainPagesContainer";

const alt = "AW23 collection";
const imgWidth = 850;
const imgHeight = 1275;

const AW23 = (props) => {
  setBodyColor("#222222");

  const styles = {
    color: "#dddddd",
  };

  const collectionImages = aw23CollectionImages.map(
    ({ srcJpeg, srcWebp, loading }) => (
      <SwiperSlide key={srcJpeg}>
        <picture>
          <source srcSet={srcWebp} type="image/webp" />
          <img
            src={srcJpeg}
            width={imgWidth}
            height={imgHeight}
            alt={alt}
            loading="eager"
          />
        </picture>
      </SwiperSlide>
    )
  );

  return (
    <MainPagesContainer
      pageTitle={alt}
      title="Video"
      src="../content/videos/Ximon_Lee_AW23_runway_full.mp4"
      style={styles.color}
      collectionsClassName="circle"
      PDFshouldHide={true}
    >
      {React.Children.toArray(collectionImages)}
    </MainPagesContainer>
  );
};

export default AW23;
