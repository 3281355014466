import React from "react";

import { SS24 } from "../../../../js/database/database-ss24-eshop.js";

import ProductsContainer from "../../../../components/containers/ProductsContainer.js";
import { SwiperSlide } from "swiper/react";

const ChainlockVest = () => {
  const {
    itemName,
    itemPrice,
    itemFabric,
    sizeChart,
    shopifyDivId,
    shopifyId,
    pictures,
    itemDetails,
  } = SS24.tops.ChainlockVest;
  const { imgWidth, imgHeight, loading } = SS24;

  const renderPictures = () => {
    return pictures.map(({ srcJpeg }) => (
      <SwiperSlide>
        <img
          src={srcJpeg}
          alt={itemName}
          width={imgWidth}
          height={imgHeight}
          loading={loading}
        />
      </SwiperSlide>
    ));
  };

  return (
    <ProductsContainer
      pageTitle={itemName}
      name={itemName}
      price={itemPrice}
      fabric={itemFabric}
      src={sizeChart}
      divId={shopifyDivId}
      mainId={shopifyId}
      detailsclothingtextcontent={itemDetails}
    >
      {React.Children.toArray(renderPictures())}
    </ProductsContainer>
  );
};

export default ChainlockVest;
