import React from "react";
import { videoOpening } from "../js/videoOpening";

/* const pdfDownload = () => {
    const pdfUrl = "../../content/pdf/XIMONLEE_AW24_LOOKBOOK.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "XIMONLEE_AW24_LOOKBOOK.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
 */

const handlePdfDownload = () => {
    const pdfUrl = "../../content/pdf/XIMONLEE_AW24_LOOKBOOK.pdf";
    const anchor = document.createElement("a");
    anchor.href = pdfUrl;
    anchor.download = "XIMONLEE_AW24_LOOKBOOK.pdf";
    anchor.click();

};

const VideoCollection = (props) => {


    return <div style={props.videoDisplay}><video
        className="videoscollection"
        autoPlay
        loop
        muted
        playsInline
        alt="Ximon Lee AW21 video"
        src={props.src}
        type="video/mp4"
        onClick={videoOpening}
        preload="none"

    ></video>
        <div id="buttons__collection">
            <div className="button__video circle" onClick={videoOpening} style={{ display: props.shouldHide ? 'none' : 'block' }}>
                video
            </div>
            <div className="button__pdf circle" onClick={props.onPdfClick} style={{ display: props.PDFshouldHide ? 'none' : 'block' }}>
                pdf
            </div>
        </div>
    </div>

}

export default VideoCollection;