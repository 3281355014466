import React, { useEffect } from "react";

const LoaderFake = (props) => {
    /* 
        setTimeout(function () {
            const loader = document.querySelector(".loader");
            loader.className += ' hidden';
        }, 1000); */

    // will set a loader for 1,5s max
    useEffect(() => {
        const imgTop = document.querySelectorAll(".img-top");
        const loader = document.querySelector(".loader");
        let totalImages = imgTop.length;
        let imagesLoaded = 0;

        function onImageLoad() {
            imagesLoaded++;
            if (imagesLoaded === totalImages) {
                loader.classList.add("hidden");
            }
        }

        // Check if all images are already loaded
        let allImagesLoaded = true;
        imgTop.forEach((img) => {
            if (!img.complete) {
                allImagesLoaded = false;
                img.addEventListener('load', onImageLoad);
            } else {
                onImageLoad();
            }
        });

        // If all images are already loaded, hide the loader immediately
        if (allImagesLoaded) {
            loader.classList.add("hidden");
        } else {
            // Otherwise, set a timeout to hide the loader after 1 second
            setTimeout(() => {
                loader.classList.add("hidden");
            }, 1500);
        }

        // Cleanup function
        return () => {
            imgTop.forEach((img) => {
                img.removeEventListener('load', onImageLoad);
            });
        };
    }, []);

    return (
        <div className="loader"><div className="textloader flicker-loader">loading</div></div>
    )
}

export default LoaderFake;