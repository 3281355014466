import parse from 'html-react-parser';

export const AW21 = {

    alt: "Ximon Lee AW21 collection",
    imgWidth: "833",
    imgHeight: "1249",

    jackets: {
        CutUpChainedOvercoatBlack: {
            order: 1,
            itemName: "Cut Up Chained overcoat",
            itemPrice: parse('<s>1110€</s> 555€'),
            itemFabric: "body · wool 100%/lining · polyester 100%",
            sizeChart: "../content/photos/eshop/AW21/01_Cut-up_Chained_Overcoat_black/CutupChainedOvercoat_SizeChart.jpg",

            shopifyDivId: "1636537900619",
            shopifyId: "7438801535198",
            link: "/cut-up-chained-overcoat-black",
            picture01: "../content/photos/eshop/AW21/01_Cut-up_Chained_Overcoat_black/Cut_up_Chained_Overcoat_Black_Jacket_01.jpg",
            picture02: "../content/photos/eshop/AW21/01_Cut-up_Chained_Overcoat_black/Cut_up_Chained_Overcoat_Black_Jacket_02.jpg",
            picture03: "../content/photos/eshop/AW21/01_Cut-up_Chained_Overcoat_black/Cut_up_Chained_Overcoat_Black_Jacket_03.jpg",
            picture04: "../content/photos/eshop/AW21/01_Cut-up_Chained_Overcoat_black/Cut_up_Chained_Overcoat_Black_Jacket_04.jpg",
            picture05: "../content/photos/eshop/AW21/01_Cut-up_Chained_Overcoat_black/Cut_up_Chained_Overcoat_Black_Jacket_05.jpg",
        },
        CutUpChainedOvercoatStripe: {
            order: 14,
            itemName: "Cut Up Chained overcoat",
            itemPrice: parse('<s>810€</s> 410€'),
            itemFabric: "wool 65% · polyester 35%",
            sizeChart: "../content/photos/eshop/AW21/14_Cut-up_Chained_Overcoat_stripe/CutupChainedOvercoat_SizeChart.jpg",

            shopifyDivId: "1636538988234",
            shopifyId: "7438802059486",
            link: "/cut-up-chained-overcoat-stripe",
            picture01: "../content/photos/eshop/AW21/14_Cut-up_Chained_Overcoat_stripe/Cut_up_Chained_Overcoat_Stripe_Jacket_01.jpg",
            picture02: "../content/photos/eshop/AW21/14_Cut-up_Chained_Overcoat_stripe/Cut_up_Chained_Overcoat_Stripe_Jacket_02.jpg",
            picture03: "../content/photos/eshop/AW21/14_Cut-up_Chained_Overcoat_stripe/Cut_up_Chained_Overcoat_Stripe_Jacket_03.jpg",
            picture04: "../content/photos/eshop/AW21/14_Cut-up_Chained_Overcoat_stripe/Cut_up_Chained_Overcoat_Stripe_Jacket_04.jpg",
            picture05: "../content/photos/eshop/AW21/14_Cut-up_Chained_Overcoat_stripe/Cut_up_Chained_Overcoat_Stripe_Jacket_05.jpg",
            picture06: "../content/photos/eshop/AW21/14_Cut-up_Chained_Overcoat_stripe/Cut_up_Chained_Overcoat_Stripe_Jacket_06.jpg",
        },
        OStuddedLeatherJacket: {
            order: 2,
            itemName: "O Studded Leather jacket",
            itemPrice: parse('<s>770€</s> 385€'),
            itemFabric: "lambskin 100%",
            sizeChart: "../content/photos/eshop/AW21/02_O_Studded_Leather_Jacket/OStuddedLeather_Jacket_SizeChart.jpg",

            shopifyDivId: "1636547787054",
            shopifyId: "7438806941918",
            link: "/o-studded-leather-jacket",
            picture01: "../content/photos/eshop/AW21/02_O_Studded_Leather_Jacket/O_Studded_Leather_Jacket_01.jpg",
            picture02: "../content/photos/eshop/AW21/02_O_Studded_Leather_Jacket/O_Studded_Leather_Jacket_02.jpg",
            picture03: "../content/photos/eshop/AW21/02_O_Studded_Leather_Jacket/O_Studded_Leather_Jacket_03.jpg",
            picture04: "../content/photos/eshop/AW21/02_O_Studded_Leather_Jacket/O_Studded_Leather_Jacket_04.jpg",
            picture05: "../content/photos/eshop/AW21/02_O_Studded_Leather_Jacket/O_Studded_Leather_Jacket_05.jpg",
        },
        PeepholeUniformJacket: {
            order: 7,
            itemName: "Peephole Uniform jacket",
            itemPrice: parse('<s>570€</s> 285€'),
            itemFabric: "lambskin 41% · polyester 59%",
            sizeChart: "../content/photos/eshop/AW21/07_Peephole_Uniform_Jacket/PeepholeUniformJacket_SizeChart.jpg",

            shopifyDivId: "1636548074492",
            shopifyId: "7438806417630",
            link: "/peephole-uniform-jacket",
            picture01: "../content/photos/eshop/AW21/07_Peephole_Uniform_Jacket/Peephole_Uniform_Jacket_01.jpg",
            picture02: "../content/photos/eshop/AW21/07_Peephole_Uniform_Jacket/Peephole_Uniform_Jacket_02.jpg",
            picture03: "../content/photos/eshop/AW21/07_Peephole_Uniform_Jacket/Peephole_Uniform_Jacket_03.jpg",
            picture04: "../content/photos/eshop/AW21/07_Peephole_Uniform_Jacket/Peephole_Uniform_Jacket_04.jpg",
            picture05: "../content/photos/eshop/AW21/07_Peephole_Uniform_Jacket/Peephole_Uniform_Jacket_05.jpg",
        },
        DetachableCollarWindJacketBlack: {
            order: 12,
            itemName: "Detachable Collar Wind jacket",
            itemPrice: parse('<s>770€</s> 385€'),
            itemFabric: "wool 35% · polyester 65%",
            sizeChart: "../content/photos/eshop/AW21/12_Detachable_Collar_Wind_Jacket_black/DetachableCollarWindJacket_SizeChart.jpg",

            shopifyDivId: "1636727724924",
            shopifyId: "7438815985886",
            link: "/detachable-collar-wind-jacket-black",
            picture01: "../content/photos/eshop/AW21/12_Detachable_Collar_Wind_Jacket_black/Detachable_Collar_Wind_Jacket_Black_01.jpg",
            picture02: "../content/photos/eshop/AW21/12_Detachable_Collar_Wind_Jacket_black/Detachable_Collar_Wind_Jacket_Black_02.jpg",
            picture03: "../content/photos/eshop/AW21/12_Detachable_Collar_Wind_Jacket_black/Detachable_Collar_Wind_Jacket_Black_03.jpg",
            picture04: "../content/photos/eshop/AW21/12_Detachable_Collar_Wind_Jacket_black/Detachable_Collar_Wind_Jacket_Black_04.jpg",
            picture05: "../content/photos/eshop/AW21/12_Detachable_Collar_Wind_Jacket_black/Detachable_Collar_Wind_Jacket_Black_05.jpg",
        },
        DetachableCollarWindJacketArmyGreen: {
            order: 33,
            itemName: "Detachable Collar Wind jacket",
            itemPrice: parse('<s>675€</s> 440€'),
            itemFabric: "wool 65% · polyester 35%",
            sizeChart: "../content/photos/eshop/AW21/33_Detachable_Collar_Wind_Jacket_green/DetachableCollarWindJacket_SizeChart",

            shopifyDivId: "1636727637456",
            shopifyId: "7438816379102",
            link: "/detachable-collar-wind-jacket-army-green",
            picture01: "../content/photos/eshop/AW21/33_Detachable_Collar_Wind_Jacket_green/Detachable_Collar_Wind_Jacket_Army_Green_Jacket_01.jpg",
            picture02: "../content/photos/eshop/AW21/33_Detachable_Collar_Wind_Jacket_green/Detachable_Collar_Wind_Jacket_Army_Green_Jacket_02.jpg",
            picture03: "../content/photos/eshop/AW21/33_Detachable_Collar_Wind_Jacket_green/Detachable_Collar_Wind_Jacket_Army_Green_Jacket_03.jpg",
            picture04: "../content/photos/eshop/AW21/33_Detachable_Collar_Wind_Jacket_green/Detachable_Collar_Wind_Jacket_Army_Green_Jacket_04.jpg",
        },

        DrummerBomberJacketRed: {
            order: 16,
            itemName: "Drummer Bomber jacket",
            itemPrice: parse('<s>675€</s> 338€'),
            itemFabric: "satin 100%",
            sizeChart: "../content/photos/eshop/AW21/16_Drummer_Bomber_Jacket_red/DrummerBomberJacket_SizeChart.jpg",

            shopifyDivId: "1636547545778",
            shopifyId: "7438819655902",
            link: "/drummer-bomber-jacket-red",
            picture01: "../content/photos/eshop/AW21/16_Drummer_Bomber_Jacket_red/Drummer_Bomber_Jacket_Red_Jacket_01.jpg",
            picture02: "../content/photos/eshop/AW21/16_Drummer_Bomber_Jacket_red/Drummer_Bomber_Jacket_Red_Jacket_02.jpg",
            picture03: "../content/photos/eshop/AW21/16_Drummer_Bomber_Jacket_red/Drummer_Bomber_Jacket_Red_Jacket_03.jpg",
            picture04: "../content/photos/eshop/AW21/16_Drummer_Bomber_Jacket_red/Drummer_Bomber_Jacket_Red_Jacket_04.jpg",
        },
        DrummerBomberJacketArmyGreen: {
            order: 32,
            itemName: "Drummer Bomber jacket",
            itemPrice: parse('<s>675€</s> 338€'),
            itemFabric: "body · satin 100%/lining · polyester 100%",
            sizeChart: "../content/photos/eshop/AW21/32_Drummer_Bomber_Jacket_green/DrummerBomberJacket_SizeChart.jpg",

            shopifyDivId: "1636546981202",
            shopifyId: "7438819328222",
            link: "/drummer-bomber-jacket-army-green",
            picture01: "../content/photos/eshop/AW21/32_Drummer_Bomber_Jacket_green/Drummer_Bomber_Jacket_Army_Green_Jacket_01.jpg",
            picture02: "../content/photos/eshop/AW21/32_Drummer_Bomber_Jacket_green/Drummer_Bomber_Jacket_Army_Green_Jacket_02.jpg",
            picture03: "../content/photos/eshop/AW21/32_Drummer_Bomber_Jacket_green/Drummer_Bomber_Jacket_Army_Green_Jacket_03.jpg",
            picture04: "../content/photos/eshop/AW21/32_Drummer_Bomber_Jacket_green/Drummer_Bomber_Jacket_Army_Green_Jacket_04.jpg",
            picture05: "../content/photos/eshop/AW21/32_Drummer_Bomber_Jacket_green/Drummer_Bomber_Jacket_Army_Green_Jacket_05.jpg",
            picture06: "../content/photos/eshop/AW21/32_Drummer_Bomber_Jacket_green/Drummer_Bomber_Jacket_Army_Green_Jacket_06.jpg",
            picture07: "../content/photos/eshop/AW21/32_Drummer_Bomber_Jacket_green/Drummer_Bomber_Jacket_Army_Green_Jacket_07.jpg",
            picture08: "../content/photos/eshop/AW21/32_Drummer_Bomber_Jacket_green/Drummer_Bomber_Jacket_Army_Green_Jacket_08.jpg",
            picture09: "../content/photos/eshop/AW21/32_Drummer_Bomber_Jacket_green/Drummer_Bomber_Jacket_Army_Green_Jacket_09.jpg",
        },
        DrummerBomberJacketChampagne: {
            order: 44,
            itemName: "Drummer Bomber jacket",
            itemPrice: parse('<s>675€</s> 338€'),
            itemFabric: "satin 100%",
            sizeChart: "../content/photos/eshop/AW21/44_Drummer_Bomber_Jacket_champagne/Drummer_Bomber_Jacket_Champagne_Jacket_SizeChart.jpg",

            shopifyDivId: "1636547164339",
            shopifyId: "7438820507870",
            link: "/drummer-bomber-jacket-champagne",
            picture01: "../content/photos/eshop/AW21/44_Drummer_Bomber_Jacket_champagne/Drummer_Bomber_Jacket_Champagne_Jacket_01.jpg",
            picture02: "../content/photos/eshop/AW21/44_Drummer_Bomber_Jacket_champagne/Drummer_Bomber_Jacket_Champagne_Jacket_02.jpg",
            picture03: "../content/photos/eshop/AW21/44_Drummer_Bomber_Jacket_champagne/Drummer_Bomber_Jacket_Champagne_Jacket_03.jpg",
            picture04: "../content/photos/eshop/AW21/44_Drummer_Bomber_Jacket_champagne/Drummer_Bomber_Jacket_Champagne_Jacket_04.jpg",
            picture05: "../content/photos/eshop/AW21/44_Drummer_Bomber_Jacket_champagne/Drummer_Bomber_Jacket_Champagne_Jacket_05.jpg",
            picture06: "../content/photos/eshop/AW21/44_Drummer_Bomber_Jacket_champagne/Drummer_Bomber_Jacket_Champagne_Jacket_06.jpg",
            picture07: "../content/photos/eshop/AW21/44_Drummer_Bomber_Jacket_champagne/Drummer_Bomber_Jacket_Champagne_Jacket_07.jpg",
            picture08: "../content/photos/eshop/AW21/44_Drummer_Bomber_Jacket_champagne/Drummer_Bomber_Jacket_Champagne_Jacket_08.jpg",
            picture09: "../content/photos/eshop/AW21/44_Drummer_Bomber_Jacket_champagne/Drummer_Bomber_Jacket_Champagne_Jacket_09.jpg",
        },
        ScrapbookJacquardJacket: {
            order: 26,
            itemName: "Scrapbook Jacquard jacket",
            itemPrice: parse('<s>875€</s> 438€'),
            itemFabric: "wool 100%",
            sizeChart: "../content/photos/eshop/AW21/26_Scrapbook_Jacquard_Jacket/ScrapbookJacquardJacket_SizeChart.jpg",

            shopifyDivId: "1636548871877",
            shopifyId: "7438817755358",
            link: "/scrapbook-jacquard-jacket",
            picture01: "../content/photos/eshop/AW21/26_Scrapbook_Jacquard_Jacket/Scrapbook_Jacquard_Jacket_01.jpg",
            picture02: "../content/photos/eshop/AW21/26_Scrapbook_Jacquard_Jacket/Scrapbook_Jacquard_Jacket_02.jpg",
            picture03: "../content/photos/eshop/AW21/26_Scrapbook_Jacquard_Jacket/Scrapbook_Jacquard_Jacket_03.jpg",
            picture04: "../content/photos/eshop/AW21/26_Scrapbook_Jacquard_Jacket/Scrapbook_Jacquard_Jacket_04.jpg",
            picture05: "../content/photos/eshop/AW21/26_Scrapbook_Jacquard_Jacket/Scrapbook_Jacquard_Jacket_05.jpg",
        },



    },

    tops: {
        ShadowWaveJumper: {
            order: 4,
            itemName: "Shadow Wave jumper",
            itemPrice: parse('<s>390€</s> 195€'),
            itemFabric: "mohair 100%",
            sizeChart: "../content/photos/eshop/AW21/04_Shadow_Wave_Jumper/ShadowWaveJumper_SizeChart.jpg",

            shopifyDivId: "1636551210611",
            shopifyId: "7438583660766",
            link: "/shadow-wave-jumper",
            picture01: "../content/photos/eshop/AW21/04_Shadow_Wave_Jumper/Shadow_Wave_Jumper_01.jpg",
            picture02: "../content/photos/eshop/AW21/04_Shadow_Wave_Jumper/Shadow_Wave_Jumper_02.jpg",
            picture03: "../content/photos/eshop/AW21/04_Shadow_Wave_Jumper/Shadow_Wave_Jumper_03.jpg",
            picture04: "../content/photos/eshop/AW21/04_Shadow_Wave_Jumper/Shadow_Wave_Jumper_04.jpg",
            picture05: "../content/photos/eshop/AW21/04_Shadow_Wave_Jumper/Shadow_Wave_Jumper_05.jpg",
        },
        ShadowWaveZipupBlackWhite: {
            order: 5,
            itemName: "Shadow Jumper Zipup",
            itemPrice: parse('<s>390€</s> 263€'),
            itemFabric: "wool 50% · mohair 50%",
            sizeChart: "../content/photos/eshop/AW21/05_Shadow_Wave_Zipup_Black_White/ShadowWaveZipup_SizeChart.jpg",

            shopifyDivId: "1636551313178",
            shopifyId: "7438586740958",
            link: "/shadow-wave-zipup-black-white",
            picture01: "../content/photos/eshop/AW21/05_Shadow_Wave_Zipup_Black_White/Shadow_Wave_Zipup_Black_White_01.jpg",
            picture02: "../content/photos/eshop/AW21/05_Shadow_Wave_Zipup_Black_White/Shadow_Wave_Zipup_Black_White_02.jpg",
            picture03: "../content/photos/eshop/AW21/05_Shadow_Wave_Zipup_Black_White/Shadow_Wave_Zipup_Black_White_03.jpg",
            picture04: "../content/photos/eshop/AW21/05_Shadow_Wave_Zipup_Black_White/Shadow_Wave_Zipup_Black_White_04.jpg",
            picture05: "../content/photos/eshop/AW21/05_Shadow_Wave_Zipup_Black_White/Shadow_Wave_Zipup_Black_White_05.jpg",
        },
        ShadowWaveZipupCaramelRed: {
            order: 15,
            itemName: "Shadow Jumper Zipup",
            itemPrice: parse('<s>390€</s> 263€'),
            itemFabric: "wool 50% · mohair 50%",
            sizeChart: "../content/photos/eshop/AW21/15_Shadow_Wave_Zipup_Red/ShadowWaveZipup_SizeChart.jpg",

            shopifyDivId: "1636551365613",
            shopifyId: "7438588838110",
            link: "/shadow-wave-zipup-caramel-red",
            picture01: "../content/photos/eshop/AW21/15_Shadow_Wave_Zipup_Red/Shadow_Wave_Zipup_Caramel_Red_01.jpg",
            picture02: "../content/photos/eshop/AW21/15_Shadow_Wave_Zipup_Red/Shadow_Wave_Zipup_Caramel_Red_02.jpg",
            picture03: "../content/photos/eshop/AW21/15_Shadow_Wave_Zipup_Red/Shadow_Wave_Zipup_Caramel_Red_03.jpg",
            picture04: "../content/photos/eshop/AW21/15_Shadow_Wave_Zipup_Red/Shadow_Wave_Zipup_Caramel_Red_04.jpg",
            picture05: "../content/photos/eshop/AW21/15_Shadow_Wave_Zipup_Red/Shadow_Wave_Zipup_Caramel_Red_05.jpg",
            picture06: "../content/photos/eshop/AW21/15_Shadow_Wave_Zipup_Red/Shadow_Wave_Zipup_Caramel_Red_06.jpg",
            picture07: "../content/photos/eshop/AW21/15_Shadow_Wave_Zipup_Red/Shadow_Wave_Zipup_Caramel_Red_07.jpg",
        },
        PeepholeZipupJacketShirt: {
            order: 6,
            itemName: "Peephole Zipup jacket shirt",
            itemPrice: parse('<s>390€</s> 188€'),
            itemFabric: "lambskin 100%",
            sizeChart: "../content/photos/eshop/AW21/06_Peephole_Zipup_Jacket_Shirt/PeepholeZipupJacketShirt_SizeChart.jpg",

            shopifyDivId: "1636727484277",
            shopifyId: "7438594474206",
            link: "/peephole-zipup-jacket-shirt",
            picture01: "../content/photos/eshop/AW21/06_Peephole_Zipup_Jacket_Shirt/Peephole_Zipup_Jacket_Shirt_01.jpg",
            picture02: "../content/photos/eshop/AW21/06_Peephole_Zipup_Jacket_Shirt/Peephole_Zipup_Jacket_Shirt_02.jpg",
            picture03: "../content/photos/eshop/AW21/06_Peephole_Zipup_Jacket_Shirt/Peephole_Zipup_Jacket_Shirt_03.jpg",
            picture04: "../content/photos/eshop/AW21/06_Peephole_Zipup_Jacket_Shirt/Peephole_Zipup_Jacket_Shirt_04.jpg",
            picture05: "../content/photos/eshop/AW21/06_Peephole_Zipup_Jacket_Shirt/Peephole_Zipup_Jacket_Shirt_05.jpg",
            picture06: "../content/photos/eshop/AW21/06_Peephole_Zipup_Jacket_Shirt/Peephole_Zipup_Jacket_Shirt_06.jpg",
            picture07: "../content/photos/eshop/AW21/06_Peephole_Zipup_Jacket_Shirt/Peephole_Zipup_Jacket_Shirt_07.jpg",
        },
        KnitTankBlueGrey: {
            order: 8,
            itemName: "Knit Tank",
            itemPrice: parse('<s>290€</s> 145€'),
            itemFabric: "one size · wool 100%",
            sizeChart: "../content/photos/eshop/AW21/08_Knit_Tank_Blue_Grey/KnitTank_SizeChart.jpg",

            shopifyDivId: "1636550669593",
            shopifyId: "7438597325022",
            link: "/knit-tank-blue-grey",
            picture01: "../content/photos/eshop/AW21/08_Knit_Tank_Blue_Grey/Knit_Tank_Blue_Grey_01.jpg",
            picture02: "../content/photos/eshop/AW21/08_Knit_Tank_Blue_Grey/Knit_Tank_Blue_Grey_02.jpg",
            picture03: "../content/photos/eshop/AW21/08_Knit_Tank_Blue_Grey/Knit_Tank_Blue_Grey_03.jpg",
            picture04: "../content/photos/eshop/AW21/08_Knit_Tank_Blue_Grey/Knit_Tank_Blue_Grey_04.jpg",
        },
        KnitTankBrownCream: {
            order: 11,
            itemName: "Knit Tank",
            itemPrice: parse('<s>290€</s> 145€'),
            itemFabric: "one size · wool 100%",
            sizeChart: "../content/photos/eshop/AW21/13_Knit_Tank_Brown_Cream/KnitTank_SizeChart.jpg",

            shopifyDivId: "1636550720817",
            shopifyId: "7438598471902",
            link: "/knit-tank-brown-cream",
            picture01: "../content/photos/eshop/AW21/13_Knit_Tank_Brown_Cream/Knit_Tank_Brown_Cream_01.jpg",
            picture02: "../content/photos/eshop/AW21/13_Knit_Tank_Brown_Cream/Knit_Tank_Brown_Cream_02.jpg",
            picture03: "../content/photos/eshop/AW21/13_Knit_Tank_Brown_Cream/Knit_Tank_Brown_Cream_03.jpg",
            picture04: "../content/photos/eshop/AW21/13_Knit_Tank_Brown_Cream/Knit_Tank_Brown_Cream_04.jpg",
        },
        KnitTankRedOlive: {
            order: 18,
            itemName: "Knit Tank",
            itemPrice: parse('<s>290€</s> 145€'),
            itemFabric: "one size · wool 100%",
            sizeChart: "../content/photos/eshop/AW21/18_Knit_Tank_Red_Olive/KnitTank_SizeChart.jpg",

            shopifyDivId: "1636550804843",
            shopifyId: "7438600143070",
            link: "/knit-tank-red-olive",
            picture01: "../content/photos/eshop/AW21/18_Knit_Tank_Red_Olive/Knit_Tank_Red_Olive_01.jpg",
            picture02: "../content/photos/eshop/AW21/18_Knit_Tank_Red_Olive/Knit_Tank_Red_Olive_02.jpg",
            picture03: "../content/photos/eshop/AW21/18_Knit_Tank_Red_Olive/Knit_Tank_Red_Olive_03.jpg",
            picture04: "../content/photos/eshop/AW21/18_Knit_Tank_Red_Olive/Knit_Tank_Red_Olive_04.jpg",
            picture05: "../content/photos/eshop/AW21/18_Knit_Tank_Red_Olive/Knit_Tank_Red_Olive_05.jpg",
            picture06: "../content/photos/eshop/AW21/18_Knit_Tank_Red_Olive/Knit_Tank_Red_Olive_06.jpg",
            picture07: "../content/photos/eshop/AW21/18_Knit_Tank_Red_Olive/Knit_Tank_Red_Olive_07.jpg",
        },
        KnitTankYellow: {
            order: 40,
            itemName: "Knit Tank",
            itemPrice: parse('<s>290€</s> 145€'),
            itemFabric: "one size · wool 100%",
            sizeChart: "../content/photos/eshop/AW21/40_Knit_Tank_Yellow/KnitTank_SizeChart.jpg",

            shopifyDivId: "1636550871366",
            shopifyId: "7438601126110",
            link: "/knit-tank-yellow",
            picture01: "../content/photos/eshop/AW21/40_Knit_Tank_Yellow/Knit_Tank_Yellow_01.jpg",
            picture02: "../content/photos/eshop/AW21/40_Knit_Tank_Yellow/Knit_Tank_Yellow_02.jpg",
            picture03: "../content/photos/eshop/AW21/40_Knit_Tank_Yellow/Knit_Tank_Yellow_03.jpg",
            picture04: "../content/photos/eshop/AW21/40_Knit_Tank_Yellow/Knit_Tank_Yellow_04.jpg",
        },
        KnitTankWhiteGrey: {
            order: 42,
            itemName: "Knit Tank",
            itemPrice: parse('<s>290€</s> 145€'),
            itemFabric: "one size · wool 100%",
            sizeChart: "../content/photos/eshop/AW21/42_Knit_Tank_White_Grey/KnitTank_SizeChart.jpg",

            shopifyDivId: "1636550837256",
            shopifyId: "7438602436830",
            link: "/knit-tank-white-grey",
            picture01: "../content/photos/eshop/AW21/42_Knit_Tank_White_Grey/Knit_Tank_White_Grey_01.jpg",
            picture02: "../content/photos/eshop/AW21/42_Knit_Tank_White_Grey/Knit_Tank_White_Grey_02.jpg",
            picture03: "../content/photos/eshop/AW21/42_Knit_Tank_White_Grey/Knit_Tank_White_Grey_03.jpg",
            picture04: "../content/photos/eshop/AW21/42_Knit_Tank_White_Grey/Knit_Tank_White_Grey_04.jpg",
            picture05: "../content/photos/eshop/AW21/42_Knit_Tank_White_Grey/Knit_Tank_White_Grey_05.jpg",
        },
        KnitTankOliveBlack: {
            order: 42,
            itemName: "Knit Tank",
            itemPrice: parse('<s>290€</s> 145€'),
            itemFabric: "one size · wool 100%",
            sizeChart: "../content/photos/eshop/AW21/46_Knit_Tank_Olive_Black/KnitTank_SizeChart.jpg",

            shopifyDivId: "1636550771853",
            shopifyId: "7438626193630",
            link: "/knit-tank-olive-black",
            picture01: "../content/photos/eshop/AW21/46_Knit_Tank_Olive_Black/Knit_Tank_Olive_Black_01.jpg",
            picture02: "../content/photos/eshop/AW21/46_Knit_Tank_Olive_Black/Knit_Tank_Olive_Black_02.jpg",
            picture03: "../content/photos/eshop/AW21/46_Knit_Tank_Olive_Black/Knit_Tank_Olive_Black_03.jpg",
        },
        UniformShirtSand: {
            order: 8,
            itemName: "Uniform shirt",
            itemPrice: parse('<s>505€</s> 253€'),
            itemFabric: "wool 41% · polyester 59%",
            sizeChart: "../content/photos/eshop/AW21/09_Uniform_Shirt_Sand/UniformShirt_SizeChart.jpg",

            shopifyDivId: "1637157371678",
            shopifyId: "7438631567582",
            link: "/uniform-shirt-sand",
            picture01: "../content/photos/eshop/AW21/09_Uniform_Shirt_Sand/Uniform_Shirt_Sand_Top_01.jpg",
            picture02: "../content/photos/eshop/AW21/09_Uniform_Shirt_Sand/Uniform_Shirt_Sand_Top_02.jpg",
            picture03: "../content/photos/eshop/AW21/09_Uniform_Shirt_Sand/Uniform_Shirt_Sand_Top_03.jpg",
            picture04: "../content/photos/eshop/AW21/09_Uniform_Shirt_Sand/Uniform_Shirt_Sand_Top_04.jpg",
        },
        UniformShirtCheckered: {
            order: 47,
            itemName: "Uniform shirt",
            itemPrice: parse('<s>505€</s> 253€'),
            itemFabric: "wool 50% · polyester 50%",
            sizeChart: "../content/photos/eshop/AW21/47_Uniform_Shirt_Checkered/UniformShirt_SizeChart.jpg",

            shopifyDivId: "1637158123816",
            shopifyId: "7438633730270",
            link: "/uniform-shirt-checkered",
            picture01: "../content/photos/eshop/AW21/47_Uniform_Shirt_Checkered/Uniform_Shirt_Checkered_Top_01.jpg",
            picture02: "../content/photos/eshop/AW21/47_Uniform_Shirt_Checkered/Uniform_Shirt_Checkered_Top_02.jpg",
            picture03: "../content/photos/eshop/AW21/47_Uniform_Shirt_Checkered/Uniform_Shirt_Checkered_Top_03.jpg",
        },
        UniformShirtBlackBrownCombo: {
            order: 53,
            itemName: "Uniform shirt",
            itemPrice: parse('<s>375€</s> 188€'),
            itemFabric: "cotton 35% · polyester 65%",
            sizeChart: "../content/photos/eshop/AW21/53_Uniform_Shirt_Black_Brown_Combo/UniformShirt_SizeChart.jpg",

            shopifyDivId: "1637158367968",
            shopifyId: "7438636450014",
            link: "/uniform-shirt-black-brown-combo",
            picture01: "../content/photos/eshop/AW21/53_Uniform_Shirt_Black_Brown_Combo/Uniform_Shirt_Black_Brown_Combo_Top_01.jpg",
            picture02: "../content/photos/eshop/AW21/53_Uniform_Shirt_Black_Brown_Combo/Uniform_Shirt_Black_Brown_Combo_Top_02.jpg",
            picture03: "../content/photos/eshop/AW21/53_Uniform_Shirt_Black_Brown_Combo/Uniform_Shirt_Black_Brown_Combo_Top_03.jpg",
            picture04: "../content/photos/eshop/AW21/53_Uniform_Shirt_Black_Brown_Combo/Uniform_Shirt_Black_Brown_Combo_Top_04.jpg",

        },
        BodyTankBlack: {
            order: 10,
            itemName: "Body tank",
            itemPrice: parse('<s>125€</s> 63€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW21/10_Body_Tank_Black/BodyTank_SizeChart.jpg",

            shopifyDivId: "1637159051821",
            shopifyId: "7427848601822",
            link: "/body-tank-black",
            picture01: "../content/photos/eshop/AW21/10_Body_Tank_Black/Body_Tank_Black_Top_01.jpg",
            picture02: "../content/photos/eshop/AW21/10_Body_Tank_Black/Body_Tank_Black_Top_02.jpg",
            picture03: "../content/photos/eshop/AW21/10_Body_Tank_Black/Body_Tank_Black_Top_03.jpg",
            picture04: "../content/photos/eshop/AW21/10_Body_Tank_Black/Body_Tank_Black_Top_04.jpg",
        },
        BodyTankGreen: {
            order: 19,
            itemName: "Body tank",
            itemPrice: parse('<s>125€</s> 63€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW21/19_Body_Tank_Green/BodyTank_SizeChart.jpg",

            shopifyDivId: "1637159226472",
            shopifyId: "7427847717086",
            link: "/body-tank-green",
            picture01: "../content/photos/eshop/AW21/19_Body_Tank_Green/Body_Tank_Green_Top_01.jpg",
            picture02: "../content/photos/eshop/AW21/19_Body_Tank_Green/Body_Tank_Green_Top_02.jpg",
            picture03: "../content/photos/eshop/AW21/19_Body_Tank_Green/Body_Tank_Green_Top_03.jpg",
        },
        BodyTankRed: {
            order: 23,
            itemName: "Body tank",
            itemPrice: parse('<s>125€</s> 63€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW21/23_Body_Tank_Red/BodyTank_SizeChart.jpg",

            shopifyDivId: "1637159337685",
            shopifyId: "7427848143070",
            link: "/body-tank-red",
            picture01: "../content/photos/eshop/AW21/23_Body_Tank_Red/Body_Tank_Red_Top_01.jpg",
            picture02: "../content/photos/eshop/AW21/23_Body_Tank_Red/Body_Tank_Red_Top_02.jpg",
            picture03: "../content/photos/eshop/AW21/23_Body_Tank_Red/Body_Tank_Red_Top_03.jpg",
            picture04: "../content/photos/eshop/AW21/23_Body_Tank_Red/Body_Tank_Red_Top_04.jpg",
            picture05: "../content/photos/eshop/AW21/23_Body_Tank_Red/Body_Tank_Red_Top_05.jpg",
            picture06: "../content/photos/eshop/AW21/23_Body_Tank_Red/Body_Tank_Red_Top_06.jpg",
            picture07: "../content/photos/eshop/AW21/23_Body_Tank_Red/Body_Tank_Red_Top_07.jpg",
        },
        BodyTankWhite: {
            order: 49,
            itemName: "Body tank",
            itemPrice: parse('<s>125€</s> 63€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW21/49_Body_Tank_White/BodyTank_SizeChart.jpg",

            shopifyDivId: "1637159473311",
            shopifyId: "7356551332062",
            link: "/body-tank-white",
            picture01: "../content/photos/eshop/AW21/49_Body_Tank_White/Body_Tank_White_Top_01.jpg",
            picture02: "../content/photos/eshop/AW21/49_Body_Tank_White/Body_Tank_White_Top_02.jpg",
            picture03: "../content/photos/eshop/AW21/49_Body_Tank_White/Body_Tank_White_Top_03.jpg",
            picture04: "../content/photos/eshop/AW21/49_Body_Tank_White/Body_Tank_White_Top_04.jpg",
        },
        SunsetPrintTshirtBlackI: {
            order: 11,
            itemName: "Sunset Print tshirt",
            itemPrice: parse('<s>165€</s> 83€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW21/11_Sunset_Print_T-shirt_Black/SunsetPrintTshirt_SizeChart.jpg",

            shopifyDivId: "1636551482682",
            shopifyId: "7438641365214",
            link: "/sunset-print-tshirt-black-i",
            picture01: "../content/photos/eshop/AW21/11_Sunset_Print_T-shirt_Black/Sunset_Print_Tshirt_Black_01_01.jpg",
            picture02: "../content/photos/eshop/AW21/11_Sunset_Print_T-shirt_Black/Sunset_Print_Tshirt_Black_01_02.jpg",
            picture03: "../content/photos/eshop/AW21/11_Sunset_Print_T-shirt_Black/Sunset_Print_Tshirt_Black_01_03.jpg",
            picture04: "../content/photos/eshop/AW21/11_Sunset_Print_T-shirt_Black/Sunset_Print_Tshirt_Black_01_04.jpg",
            picture05: "../content/photos/eshop/AW21/11_Sunset_Print_T-shirt_Black/Sunset_Print_Tshirt_Black_01_05.jpg",
        },
        SunsetPrintTshirtBlackII: {
            order: 29,
            itemName: "Sunset Print tshirt",
            itemPrice: parse('<s>165€</s> 83€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW21/29_Sunset_Print_T-shirt_Black_II/SunsetPrintTshirt_SizeChart.jpg",

            shopifyDivId: "1636552239831",
            shopifyId: "7438691598558",
            link: "/sunset-print-tshirt-black-ii",
            picture01: "../content/photos/eshop/AW21/29_Sunset_Print_T-shirt_Black_II/Sunset_Print_Tshirt_Black_02_01.jpg",
            picture02: "../content/photos/eshop/AW21/29_Sunset_Print_T-shirt_Black_II/Sunset_Print_Tshirt_Black_02_02.jpg",
            picture03: "../content/photos/eshop/AW21/29_Sunset_Print_T-shirt_Black_II/Sunset_Print_Tshirt_Black_02_03.jpg",
            picture04: "../content/photos/eshop/AW21/29_Sunset_Print_T-shirt_Black_II/Sunset_Print_Tshirt_Black_02_04.jpg",
        },
        SunsetPrintTshirtBlackIII: {
            order: 38,
            itemName: "Sunset Print tshirt",
            itemPrice: parse('<s>165€</s> 83€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW21/38_Sunset_Print_T-shirt_Black_III/SunsetPrintTshirt_SizeChart.jpg",

            shopifyDivId: "1636552345369",
            shopifyId: "7438662664414",
            link: "/sunset-print-tshirt-black-iii",
            picture01: "../content/photos/eshop/AW21/38_Sunset_Print_T-shirt_Black_III/Sunset_Print_Tshirt_Black_03_01.jpg",
            picture02: "../content/photos/eshop/AW21/38_Sunset_Print_T-shirt_Black_III/Sunset_Print_Tshirt_Black_03_02.jpg",
            picture03: "../content/photos/eshop/AW21/38_Sunset_Print_T-shirt_Black_III/Sunset_Print_Tshirt_Black_03_03.jpg",
        },
        SunsetPrintTshirtBlackIV: {
            order: 50,
            itemName: "Sunset Print tshirt",
            itemPrice: parse('<s>165€</s> 83€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW21/50_Sunset_Print_T-shirt_Black_IV/SunsetPrintTshirt_SizeChart.jpg",

            shopifyDivId: "1636552488888",
            shopifyId: "7438691598558",
            link: "/sunset-print-tshirt-black-iv",
            picture01: "../content/photos/eshop/AW21/50_Sunset_Print_T-shirt_Black_IV/Sunset_Print_Tshirt_Black_04_01.jpg",
            picture02: "../content/photos/eshop/AW21/50_Sunset_Print_T-shirt_Black_IV/Sunset_Print_Tshirt_Black_04_02.jpg",
            picture03: "../content/photos/eshop/AW21/50_Sunset_Print_T-shirt_Black_IV/Sunset_Print_Tshirt_Black_04_03.jpg",
            picture04: "../content/photos/eshop/AW21/50_Sunset_Print_T-shirt_Black_IV/Sunset_Print_Tshirt_Black_04_04.jpg",
            picture05: "../content/photos/eshop/AW21/50_Sunset_Print_T-shirt_Black_IV/Sunset_Print_Tshirt_Black_04_05.jpg",
        },
        SunsetPrintTshirtWhiteI: {
            order: 30,
            itemName: "Sunset Print tshirt",
            itemPrice: parse('<s>165€</s> 83€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW21/30_Sunset_Print_T-shirt_White/SunsetPrintTshirt_SizeChart.jpg",

            shopifyDivId: "1636552617546",
            shopifyId: "7438693073118",
            link: "/sunset-print-tshirt-white-i",
            picture01: "../content/photos/eshop/AW21/30_Sunset_Print_T-shirt_White/Sunset_Print_Tshirt_White_01_01.jpg",
            picture02: "../content/photos/eshop/AW21/30_Sunset_Print_T-shirt_White/Sunset_Print_Tshirt_White_01_02.jpg",
            picture03: "../content/photos/eshop/AW21/30_Sunset_Print_T-shirt_White/Sunset_Print_Tshirt_White_01_03.jpg",
            picture04: "../content/photos/eshop/AW21/30_Sunset_Print_T-shirt_White/Sunset_Print_Tshirt_White_01_04.jpg",
            picture05: "../content/photos/eshop/AW21/30_Sunset_Print_T-shirt_White/Sunset_Print_Tshirt_White_01_05.jpg",
            picture06: "../content/photos/eshop/AW21/30_Sunset_Print_T-shirt_White/Sunset_Print_Tshirt_White_01_06.jpg",
        },
        SunsetPrintTshirtWhiteII: {
            order: 43,
            itemName: "Sunset Print tshirt",
            itemPrice: parse('<s>165€</s> 83€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW21/43_Sunset_Print_T-shirt_White_II/SunsetPrintTshirt_SizeChart.jpg",

            shopifyDivId: "1636552645690",
            shopifyId: "7438693499102",
            link: "/sunset-print-tshirt-white-ii",
            picture01: "../content/photos/eshop/AW21/43_Sunset_Print_T-shirt_White_II/Sunset_Print_Tshirt_White_02_01.jpg",
            picture02: "../content/photos/eshop/AW21/43_Sunset_Print_T-shirt_White_II/Sunset_Print_Tshirt_White_02_02.jpg",
            picture03: "../content/photos/eshop/AW21/43_Sunset_Print_T-shirt_White_II/Sunset_Print_Tshirt_White_02_03.jpg",
            picture04: "../content/photos/eshop/AW21/43_Sunset_Print_T-shirt_White_II/Sunset_Print_Tshirt_White_02_04.jpg",
        },
        SunsetPrintTshirtWhiteIII: {
            order: 50,
            itemName: "Sunset Print tshirt",
            itemPrice: parse('<s>165€</s> 83€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW21/50_Sunset_Print_T-shirt_White_III/SunsetPrintTshirt_SizeChart.jpg",

            shopifyDivId: "1668249182367",
            shopifyId: "7454372167902",
            link: "/sunset-print-tshirt-white-iii",
            picture01: "../content/photos/eshop/AW21/50_Sunset_Print_T-shirt_White_III/Sunset_Print_Tshirt_White_03_01.jpg",
            picture02: "../content/photos/eshop/AW21/50_Sunset_Print_T-shirt_White_III/Sunset_Print_Tshirt_White_03_02.jpg",
            picture03: "../content/photos/eshop/AW21/50_Sunset_Print_T-shirt_White_III/Sunset_Print_Tshirt_White_03_03.jpg",
        },

        ScrapbookJacquardShirt: {
            order: 27,
            itemName: "Scrapbook Jacquard shirt",
            itemPrice: parse('<s>770€</s> 385€'),
            itemFabric: "wool 100%",
            sizeChart: "../content/photos/eshop/AW21/27_Scrapbook_Jacquard_Shirt/ScrapbookJacquardShirt_SizeChart.jpg",

            shopifyDivId: "1636551365613",
            shopifyId: "7438588838110",
            link: "/scrapbook-jacquard-shirt",
            picture01: "../content/photos/eshop/AW21/27_Scrapbook_Jacquard_Shirt/Scrapbook_Jacquard_Shirt_01.jpg",
            picture02: "../content/photos/eshop/AW21/27_Scrapbook_Jacquard_Shirt/Scrapbook_Jacquard_Shirt_02.jpg",
            picture03: "../content/photos/eshop/AW21/27_Scrapbook_Jacquard_Shirt/Scrapbook_Jacquard_Shirt_03.jpg",
            picture04: "../content/photos/eshop/AW21/27_Scrapbook_Jacquard_Shirt/Scrapbook_Jacquard_Shirt_04.jpg",
            picture05: "../content/photos/eshop/AW21/27_Scrapbook_Jacquard_Shirt/Scrapbook_Jacquard_Shirt_05.jpg",
        },
        ScrapbookPeepholeTank: {
            order: 28,
            itemName: "Scrapbook Peephole tank",
            itemPrice: parse('<s>440€</s> 220€'),
            itemFabric: "wool 100%",
            sizeChart: "../content/photos/eshop/AW21/28_Scrapbook_Peephole_Tank/ScrapbookPeepholeTank_SizeChart.jpg",

            shopifyDivId: "1636551168285",
            shopifyId: "7438713061598",
            link: "/scrapbook-peephole-tank",
            picture01: "../content/photos/eshop/AW21/28_Scrapbook_Peephole_Tank/Scrapbook_Peephole_Tank_01.jpg",
            picture02: "../content/photos/eshop/AW21/28_Scrapbook_Peephole_Tank/Scrapbook_Peephole_Tank_02.jpg",
            picture03: "../content/photos/eshop/AW21/28_Scrapbook_Peephole_Tank/Scrapbook_Peephole_Tank_03.jpg",
            picture04: "../content/photos/eshop/AW21/28_Scrapbook_Peephole_Tank/Scrapbook_Peephole_Tank_04.jpg",
            picture05: "../content/photos/eshop/AW21/28_Scrapbook_Peephole_Tank/Scrapbook_Peephole_Tank_05.jpg",
            picture06: "../content/photos/eshop/AW21/28_Scrapbook_Peephole_Tank/Scrapbook_Peephole_Tank_06.jpg",
            picture07: "../content/photos/eshop/AW21/28_Scrapbook_Peephole_Tank/Scrapbook_Peephole_Tank_07.jpg",
        },
        JigsawPlantSweater: {
            order: 28,
            itemName: "Jigsaw Plant sweater",
            itemPrice: parse('<s>390€</s> 195€'),
            itemFabric: "mohair 100%",
            sizeChart: "../content/photos/eshop/AW21/31_Jigsaw_Plant_Sweater/JigsawPlantSweater_SizeChart.jpg",

            shopifyDivId: "1636550021646",
            shopifyId: "7438714929374",
            link: "/jigsaw-plant-sweater",
            picture01: "../content/photos/eshop/AW21/31_Jigsaw_Plant_Sweater/Jigsaw_Plant_Sweater_01.jpg",
            picture02: "../content/photos/eshop/AW21/31_Jigsaw_Plant_Sweater/Jigsaw_Plant_Sweater_02.jpg",
            picture03: "../content/photos/eshop/AW21/31_Jigsaw_Plant_Sweater/Jigsaw_Plant_Sweater_03.jpg",
            picture04: "../content/photos/eshop/AW21/31_Jigsaw_Plant_Sweater/Jigsaw_Plant_Sweater_04.jpg",
            picture05: "../content/photos/eshop/AW21/31_Jigsaw_Plant_Sweater/Jigsaw_Plant_Sweater_05.jpg",
            picture06: "../content/photos/eshop/AW21/31_Jigsaw_Plant_Sweater/Jigsaw_Plant_Sweater_06.jpg",
        },
        JigsawPlantVest: {
            order: 41,
            itemName: "Jigsaw Plant vest",
            itemPrice: parse('<s>335€</s> 168€'),
            itemFabric: "one size · mohair 100%",
            sizeChart: "../content/photos/eshop/AW21/41_Jigsaw_Plant_Vest/JigsawPlantVest_SizeChart.jpg",

            shopifyDivId: "1636551168285",
            shopifyId: "7438713061598",
            link: "/jigsaw-plant-vest",
            picture01: "../content/photos/eshop/AW21/41_Jigsaw_Plant_Vest/Jigsaw_Plant_Vest_01.jpg",
            picture02: "../content/photos/eshop/AW21/41_Jigsaw_Plant_Vest/Jigsaw_Plant_Vest_02.jpg",
            picture03: "../content/photos/eshop/AW21/41_Jigsaw_Plant_Vest/Jigsaw_Plant_Vest_03.jpg",
            picture04: "../content/photos/eshop/AW21/41_Jigsaw_Plant_Vest/Jigsaw_Plant_Vest_04.jpg",
            picture05: "../content/photos/eshop/AW21/41_Jigsaw_Plant_Vest/Jigsaw_Plant_Vest_05.jpg",
        },
        MixedCheckeredShirt: {
            order: 34,
            itemName: "Mixed Checkered shirt",
            itemPrice: parse('<s>505€</s> 253€'),
            itemFabric: "wool 50% · polyester 50%",
            sizeChart: "../content/photos/eshop/AW21/34_Mixed_Checkered_Shirt/MixedCheckeredShirt_SizeChart.jpg",

            shopifyDivId: "1636550909810",
            shopifyId: "7438716535006",
            link: "/mixed-checkered-shirt",
            picture01: "../content/photos/eshop/AW21/34_Mixed_Checkered_Shirt/Mixed_Checkered_Shirt_01.jpg",
            picture02: "../content/photos/eshop/AW21/34_Mixed_Checkered_Shirt/Mixed_Checkered_Shirt_02.jpg",
            picture03: "../content/photos/eshop/AW21/34_Mixed_Checkered_Shirt/Mixed_Checkered_Shirt_03.jpg",
            picture04: "../content/photos/eshop/AW21/34_Mixed_Checkered_Shirt/Mixed_Checkered_Shirt_04.jpg",
        },
        CheckeredVest: {
            order: 48,
            itemName: "Checkered vest",
            itemPrice: parse('<s>390€</s> 195€'),
            itemFabric: "wool 50% · polyester 50%",
            sizeChart: "../content/photos/eshop/AW21/48_Checkered_Vest/CheckeredVest_SizeChart.jpg",

            shopifyDivId: "1636549969551",
            shopifyId: "7355839873246",
            link: "/checkered-vest",
            picture01: "../content/photos/eshop/AW21/48_Checkered_Vest/Checkered_Vest_Top_01.jpg",
            picture02: "../content/photos/eshop/AW21/48_Checkered_Vest/Checkered_Vest_Top_02.jpg",
            picture03: "../content/photos/eshop/AW21/48_Checkered_Vest/Checkered_Vest_Top_03.jpg",
        },
        PeepholeLeatherTank: {
            order: 51,
            itemName: "Peephole Leather tank",
            itemPrice: parse('<s>390€</s> 195€'),
            itemFabric: "lambskin 100%",
            sizeChart: "../content/photos/eshop/AW21/51_Peephole_Leather_Tank/PeepholeLeatherTank_SizeChart.jpg",

            shopifyDivId: "1636550960679",
            shopifyId: "7438721745118",
            link: "/peephole-leather-tank",
            picture01: "../content/photos/eshop/AW21/51_Peephole_Leather_Tank/Peephole_Leather_Tank_01.jpg",
            picture02: "../content/photos/eshop/AW21/51_Peephole_Leather_Tank/Peephole_Leather_Tank_02.jpg",
            picture03: "../content/photos/eshop/AW21/51_Peephole_Leather_Tank/Peephole_Leather_Tank_03.jpg",
            picture04: "../content/photos/eshop/AW21/51_Peephole_Leather_Tank/Peephole_Leather_Tank_04.jpg",

        },





    },

    pants: {
        PeepholeLeatherTrousers: {
            order: 3,
            itemName: "Peephole Leather trousers",
            itemPrice: parse('<s>570€</s> 285€'),
            itemFabric: "lambskin 100%",
            sizeChart: "../content/photos/eshop/AW21/03_Peephole_Leather_Trousers/PeepholeLeatherTrousers_SizeChart.jpg",

            shopifyDivId: "1636549642538",
            shopifyId: "7438728167646",
            link: "/peephole-leather-trousers",
            picture01: "../content/photos/eshop/AW21/03_Peephole_Leather_Trousers/Peephole_Leather_Trousers_01.jpg",
            picture02: "../content/photos/eshop/AW21/03_Peephole_Leather_Trousers/Peephole_Leather_Trousers_01.jpg",
            picture03: "../content/photos/eshop/AW21/03_Peephole_Leather_Trousers/Peephole_Leather_Trousers_03.jpg",
            picture04: "../content/photos/eshop/AW21/03_Peephole_Leather_Trousers/Peephole_Leather_Trousers_04.jpg",
        },
        PeepholeVTrousers: {
            order: 17,
            itemName: "Peephole V trousers",
            itemPrice: parse('<s>570€</s> 285€'),
            itemFabric: "lambskin 100%",
            sizeChart: "../content/photos/eshop/AW21/17_Peephole_V_Trousers/PeepholeVTrousers_SizeChart.jpg",

            shopifyDivId: "1636549805528",
            shopifyId: "7438736654558",
            link: "/peephole-v-trousers",
            picture01: "../content/photos/eshop/AW21/17_Peephole_V_Trousers/Peephole_V_Trousers_01.jpg",
            picture02: "../content/photos/eshop/AW21/17_Peephole_V_Trousers/Peephole_V_Trousers_02.jpg",
            picture03: "../content/photos/eshop/AW21/17_Peephole_V_Trousers/Peephole_V_Trousers_03.jpg",

        },
        BikerPanelledLeatherTrousers: {
            order: 21,
            itemName: "Biker Panelled Leather trousers",
            itemPrice: parse('<s>570€</s> 145€'),
            itemFabric: "lambskin 100%",
            sizeChart: "../content/photos/eshop/AW21/21_Biker_Panelled_Leather_Trousers/BikerPanelledLeatherTrousers_SizeChart.jpg",

            shopifyDivId: "1636549229087",
            shopifyId: "7438738424030",
            link: "/biker-panelled-leather-trousers",
            picture01: "../content/photos/eshop/AW21/21_Biker_Panelled_Leather_Trousers/Biker_Panelled_Leather_Trousers_01.jpg",
            picture02: "../content/photos/eshop/AW21/21_Biker_Panelled_Leather_Trousers/Biker_Panelled_Leather_Trousers_02.jpg",
            picture03: "../content/photos/eshop/AW21/21_Biker_Panelled_Leather_Trousers/Biker_Panelled_Leather_Trousers_03.jpg",
            picture04: "../content/photos/eshop/AW21/21_Biker_Panelled_Leather_Trousers/Biker_Panelled_Leather_Trousers_04.jpg",
        },
        BikerPanelledTrousersRed: {
            order: 21,
            itemName: "Biker Panelled trousers",
            itemPrice: parse('<s>290€</s> 145€'),
            itemFabric: "cotton 100% ",
            sizeChart: "../content/photos/eshop/AW21/22_Biker_Panelled_Trousers_red/BikerPanelledTrousers_SizeChart.jpg",

            shopifyDivId: "1636549298476",
            shopifyId: "7438739865822",
            link: "/biker-panelled-trousers-red",
            picture01: "../content/photos/eshop/AW21/22_Biker_Panelled_Trousers_red/Biker_Panelled_Trousers_Red_01.jpg",
            picture02: "../content/photos/eshop/AW21/22_Biker_Panelled_Trousers_red/Biker_Panelled_Trousers_Red_02.jpg",
            picture03: "../content/photos/eshop/AW21/22_Biker_Panelled_Trousers_red/Biker_Panelled_Trousers_Red_03.jpg",
            picture04: "../content/photos/eshop/AW21/22_Biker_Panelled_Trousers_red/Biker_Panelled_Trousers_Red_04.jpg",
            picture05: "../content/photos/eshop/AW21/22_Biker_Panelled_Trousers_red/Biker_Panelled_Trousers_Red_05.jpg",
        },
        BikerPanelledTrousersCreamWhite: {
            order: 24,
            itemName: "Biker Panelled trousers",
            itemPrice: parse('<s>290€</s> 145€'),
            itemFabric: "cotton 100% ",
            sizeChart: "../content/photos/eshop/AW21/24_Biker_Panelled_Trousers_cream_white/BikerPanelledTrousers_SizeChart.jpg",

            shopifyDivId: "1636549018516",
            shopifyId: "7438744158430",
            link: "/biker-panelled-trousers-cream-white",
            picture01: "../content/photos/eshop/AW21/24_Biker_Panelled_Trousers_cream_white/Biker_Panelled_Trousers_Cream_White_01.jpg",
            picture02: "../content/photos/eshop/AW21/24_Biker_Panelled_Trousers_cream_white/Biker_Panelled_Trousers_Cream_White_02.jpg",
            picture03: "../content/photos/eshop/AW21/24_Biker_Panelled_Trousers_cream_white/Biker_Panelled_Trousers_Cream_White_03.jpg",
            picture04: "../content/photos/eshop/AW21/24_Biker_Panelled_Trousers_cream_white/Biker_Panelled_Trousers_Cream_White_04.jpg",
            picture05: "../content/photos/eshop/AW21/24_Biker_Panelled_Trousers_cream_white/Biker_Panelled_Trousers_Cream_White_05.jpg",
        },
        ScrapbookComboBikerPants: {
            order: 25,
            itemName: "Scrapbook Combo Biker pants",
            itemPrice: parse('<s>440€</s> 220€'),
            itemFabric: "Body - bamboo fiber 21.5% · cotton 53.1% · polyester 17.7% · rayon 6.8% · silk 0.9% / Knee - wool 100%",
            sizeChart: "../content/photos/eshop/AW21/25_Scrapbook_Combo_Biker_Pants/ScrapbookComboBikerPants_SizeChart.jpg",

            shopifyDivId: "1636549864910",
            shopifyId: "7438753104094",
            link: "/scrapbook-combo-biker-pants",
            picture01: "../content/photos/eshop/AW21/25_Scrapbook_Combo_Biker_Pants/Scrapbook_Combo_Biker_Pants_01.jpg",
            picture02: "../content/photos/eshop/AW21/25_Scrapbook_Combo_Biker_Pants/Scrapbook_Combo_Biker_Pants_02.jpg",
            picture03: "../content/photos/eshop/AW21/25_Scrapbook_Combo_Biker_Pants/Scrapbook_Combo_Biker_Pants_03.jpg",
            picture04: "../content/photos/eshop/AW21/25_Scrapbook_Combo_Biker_Pants/Scrapbook_Combo_Biker_Pants_04.jpg",
            picture05: "../content/photos/eshop/AW21/25_Scrapbook_Combo_Biker_Pants/Scrapbook_Combo_Biker_Pants_05.jpg",
            picture06: "../content/photos/eshop/AW21/25_Scrapbook_Combo_Biker_Pants/Scrapbook_Combo_Biker_Pants_06.jpg",
            picture07: "../content/photos/eshop/AW21/25_Scrapbook_Combo_Biker_Pants/Scrapbook_Combo_Biker_Pants_07.jpg",
        },
        MixedCheckeredTrousers: {
            order: 35,
            itemName: "Mixed Checkered trousers",
            itemPrice: parse('<s>505€</s> 253€'),
            itemFabric: "wool 50% · cotton 50% / cotton 64% · acrylic 14% · polyester 12% · wool 7% · AF 2% · elastane 1%",
            sizeChart: "../content/photos/eshop/AW21/35_Mixed_Checkered_Trousers/MixedCheckeredTrousers_SizeChart.jpg",

            shopifyDivId: "1636549469243",
            shopifyId: "7438784102622",
            link: "/mixed-checkered-trousers",
            picture01: "../content/photos/eshop/AW21/35_Mixed_Checkered_Trousers/Mixed_Checkered_Trousers_01.jpg",
            picture02: "../content/photos/eshop/AW21/35_Mixed_Checkered_Trousers/Mixed_Checkered_Trousers_02.jpg",
            picture03: "../content/photos/eshop/AW21/35_Mixed_Checkered_Trousers/Mixed_Checkered_Trousers_03.jpg",
        },
        PeepholeJeans: {
            order: 36,
            itemName: "Peephole jeans",
            itemPrice: parse('<s>290€</s> 145€'),
            itemFabric: "bamboo fiber 21.5 % · cotton 53.1% · polyester 17.7% ·  rayon 6.8% · silk 0.9%",
            sizeChart: "../content/photos/eshop/AW21/36_Peephole_Jeans/PeepholeJeans_SizeChart.jpg",

            shopifyDivId: "1636549573051",
            shopifyId: "7438787805406",
            link: "/peephole-jeans",
            picture01: "../content/photos/eshop/AW21/36_Peephole_Jeans/Peephole_Jeans_01.jpg",
            picture02: "../content/photos/eshop/AW21/36_Peephole_Jeans/Peephole_Jeans_02.jpg",
            picture03: "../content/photos/eshop/AW21/36_Peephole_Jeans/Peephole_Jeans_03.jpg",
            picture04: "../content/photos/eshop/AW21/36_Peephole_Jeans/Peephole_Jeans_04.jpg",
            picture05: "../content/photos/eshop/AW21/36_Peephole_Jeans/Peephole_Jeans_05.jpg",
        },
        PeepholeVJogger: {
            order: 37,
            itemName: "Peephole V jogger",
            itemPrice: parse('<s>250€</s> 125€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW21/37_Peephole_V_Jogger/PeepholeVJogger_SizeChart.jpg",

            shopifyDivId: "1636549711318",
            shopifyId: "7438792163550",
            link: "/peephole-v-jogger",
            picture01: "../content/photos/eshop/AW21/37_Peephole_V_Jogger/Peephole_V_Jogger_01.jpg",
            picture02: "../content/photos/eshop/AW21/37_Peephole_V_Jogger/Peephole_V_Jogger_02.jpg",
            picture03: "../content/photos/eshop/AW21/37_Peephole_V_Jogger/Peephole_V_Jogger_03.jpg",
            picture04: "../content/photos/eshop/AW21/37_Peephole_V_Jogger/Peephole_V_Jogger_04.jpg",
        },
        CheckeredPeepholeTrousers: {
            order: 45,
            itemName: "Checkered Peephole trousers",
            itemPrice: parse('<s>505€</s> 390€'),
            itemFabric: "wool 50% · polyester 50%",
            sizeChart: "../content/photos/eshop/AW21/45_Checkered_Peephole_Trousers/CheckeredPeepholeTrousers_SizeChart.jpg",

            shopifyDivId: "1636549355744",
            shopifyId: "7438793605342",
            link: "/checkered-peephole-trousers",
            picture01: "../content/photos/eshop/AW21/45_Checkered_Peephole_Trousers/Checkered_Peephole_Trousers_01.jpg",
            picture02: "../content/photos/eshop/AW21/45_Checkered_Peephole_Trousers/Checkered_Peephole_Trousers_02.jpg",
            picture03: "../content/photos/eshop/AW21/45_Checkered_Peephole_Trousers/Checkered_Peephole_Trousers_03.jpg",
            picture04: "../content/photos/eshop/AW21/45_Checkered_Peephole_Trousers/Checkered_Peephole_Trousers_04.jpg",
        },
        BikerPanelledTrousersDarkBlue: {
            order: 52,
            itemName: "Biker Panelled trousers",
            itemPrice: parse('<s>290€</s> 190€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW21/52_Biker_Panelled_Trousers_dark_blue/BikerPanelledTrousers_SizeChart.jpg",

            shopifyDivId: "1636549160085",
            shopifyId: "7438742290654",
            link: "/biker-panelled-trousers-dark-blue",
            picture01: "../content/photos/eshop/AW21/52_Biker_Panelled_Trousers_dark_blue/Biker_Panelled_Trousers_Blue_01.jpg",
            picture02: "../content/photos/eshop/AW21/52_Biker_Panelled_Trousers_dark_blue/Biker_Panelled_Trousers_Blue_02.jpg",
            picture03: "../content/photos/eshop/AW21/52_Biker_Panelled_Trousers_dark_blue/Biker_Panelled_Trousers_Blue_03.jpg",
            picture04: "../content/photos/eshop/AW21/52_Biker_Panelled_Trousers_dark_blue/Biker_Panelled_Trousers_Blue_04.jpg",
            picture05: "../content/photos/eshop/AW21/52_Biker_Panelled_Trousers_dark_blue/Biker_Panelled_Trousers_Blue_05.jpg",

        },



    },

}