// import React from "react";

// import { AW22 } from "../../../../js/database/database-aw22.js";

// import TopMenuProducts from "../../../../components/topmenuproducts";
// import SwiperInit from "../../../../components/SwiperInit";
// import InfoItem from "../../../../components/infoItem";
// import Loader from "../../../../components/loader";
// import ShopifyMainComponent from "../../../../components/ShopifyMainComponent";
// import SizeChart from "../../../../components/sizeChart.js";

// class PanelledMacCoat extends React.Component {
//   render() {
//     return (
//       <div>
//         <Loader />
//         <TopMenuProducts name={AW22.jackets.PanelledMacCoat.itemName} />

//         <InfoItem
//           price={AW22.jackets.PanelledMacCoat.itemPrice}
//           fabric={AW22.jackets.PanelledMacCoat.itemFabric}
//         />

//         <SizeChart src={AW22.jackets.PanelledMacCoat.sizeChart} />

//         <div className="swiper-container" id="swiper">
//           <div className="swiper-wrapper">
//             <div className="swiper-slide">
//               <img
//                 src={AW22.jackets.PanelledMacCoat.picture01}
//                 alt={AW22.alt}
//                 loading="lazy"
//               />
//             </div>
//             <div className="swiper-slide">
//               <img
//                 src={AW22.jackets.PanelledMacCoat.picture02}
//                 alt={AW22.alt}
//                 loading="lazy"
//               />
//             </div>
//             <div className="swiper-slide">
//               <img
//                 src={AW22.jackets.PanelledMacCoat.picture03}
//                 alt={AW22.alt}
//                 loading="lazy"
//               />
//             </div>
//           </div>
//         </div>

//         <ShopifyMainComponent
//           divId={AW22.jackets.PanelledMacCoat.shopifyDivId}
//           mainId={AW22.jackets.PanelledMacCoat.shopifyId}
//         />
//         <SwiperInit />
//       </div>
//     );
//   }
// }

// export default PanelledMacCoat;

import React from "react";

import { AW22 } from "../../../../js/database/database-aw22.js";

import ProductsContainer from "../../../../components/containers/ProductsContainer.js";
import { SwiperSlide } from "swiper/react";

const PanelledMacCoat = () => {
  return (
    <ProductsContainer
      pageTitle={AW22.jackets.PanelledMacCoat.itemName}
      name={AW22.jackets.PanelledMacCoat.itemName}
      price={AW22.jackets.PanelledMacCoat.itemPrice}
      fabric={AW22.jackets.PanelledMacCoat.itemFabric}
      src={AW22.jackets.PanelledMacCoat.sizeChart}
      divId={AW22.jackets.PanelledMacCoat.shopifyDivId}
      mainId={AW22.jackets.PanelledMacCoat.shopifyId}
    >
      {React.Children.toArray(
        AW22.jackets.PanelledMacCoat.pictures.map(({ srcJpeg }) => (
          <SwiperSlide>
            <img
              src={srcJpeg}
              alt={AW22.jackets.PanelledMacCoat.itemName}
              width={AW22.imgWidth}
              height={AW22.imgHeight}
              loading={AW22.loading}
            />
          </SwiperSlide>
        ))
      )}
    </ProductsContainer>
  );
};

export default PanelledMacCoat;
