import React from "react";

import { NavLink, Link } from "react-router-dom"

class TopMenuEshop extends React.Component {

  render() {
    return (


      <ul id="menutop">
        <li>
          <NavLink activeClassName="circle" to="/eshop">
            all</NavLink>
        </li>
        <li>
          <NavLink className="link" activeClassName="circle" to="/jackets">
            outerwear</NavLink></li>
        <li> <NavLink className="link" activeClassName="circle" to="/tops">tops</NavLink></li>
        <li> <NavLink className="link" activeClassName="circle" to="/pants">bottoms</NavLink></li>
        <li> <NavLink className="link" activeClassName="circle" to="/accessories">accessories</NavLink></li>
        <li> <NavLink className="link" activeClassName="circle" to="/sales">on sale</NavLink></li>

      </ul>


    )

  }
}

export default TopMenuEshop;