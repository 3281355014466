import React from "react";
/* import "swiper/css";
import 'swiper/css/lazy' */

import Swiper, {
    Navigation,
    Pagination,
    Mousewheel,
    Keyboard,
    FreeMode,
    Lazy,
    Zoom
} from "swiper";

import "swiper/css/zoom";

Swiper.use([Mousewheel, Pagination, Navigation, Keyboard, FreeMode, Lazy, Zoom]);

class SwiperInit extends React.Component {

    componentDidMount() {

        /* const scriptSwiper = document.createElement("script");
        scriptSwiper.src = "../js/swiper-init.js";
        scriptSwiper.async = true;
        document.body.appendChild(scriptSwiper); */


        const swiper = new Swiper('.swiper-container', {
            slidesPerView: 'auto',
            freeMode: true,
            /* lazy: true, */
            /* loadPrevNext: true, */

            grabCursor: true,
            loop: true,
            mousewheel: {
                invert: true,
            },
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            /*  zoom: true, */
            /*    zoom: {
                   maxRatio: 2,
                   minRatio: 1
               }, */



        });
    }

    render() {
        return (
            <div>
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
            </div>
        )
    }
}

export default SwiperInit;


{/* <div className="swiper-container" id="swiper">
    <div className="swiper-wrapper">
        <div className="swiper-slide">
            <picture>
                <source
                    srcSet="../content/photos/collections/PRE23/webp/XimonLee_PRE23_LOOK_1.webp"
                    type="image/webp"
                    alt={alt}
                    width={imgWidth}
                    height={imgHeight}
                />
                <img
                    src="../content/photos/collections/PRE23/XimonLee_PRE23_LOOK_1.jpg"
                    alt={alt}
                    width={imgWidth}
                    height={imgHeight}
                />
            </picture>
        </div>
    </div>
</div> */}