import React from "react";
import { NavLink, Link } from "react-router-dom"

const TopMenu = (props) => {

  /*   const styles = {
      lightGrey: {
        color: "#dddddd",
      },
    }; */

  return (

    <div style={props.topMenuDisplay}>
      {/*  <ul id="menutop">
      <li>
          <NavLink activeClassName="circle" to="/current">SS24</NavLink>
        </li>
        <li>
          <NavLink activeClassName="circle" to="/aw23">AW23</NavLink>
        </li>
        <li>
          <NavLink activeClassName="circle" to="/ss23">SS23</NavLink>
        </li>
        <li>
          <NavLink activeClassName="circle" to="/pre23">PRE23</NavLink>
        </li>
        <li>
          <NavLink id="ss22button" activeClassName="circle" to="/ss22">SS22</NavLink>
        </li>
        <li>
          <NavLink id="aw21button" className="link" activeClassName="circle" to="/aw21">AW21</NavLink>
        </li>
      </ul> */}

      <ul id="menutop">

        <li>
          <NavLink activeClassName="circle" to="/current">AW24</NavLink>
        </li>
        <li>
          <NavLink activeClassName="circle" to="/ss24">SS24</NavLink>
        </li>
        <li>
          <NavLink activeClassName="circle" to="/aw23">AW23</NavLink>
        </li>


        <li className="dropbtn">ARCHIVES
          <div className="dropdown-content">
            <li>
              <NavLink activeClassName="circle" to="/ss23">SS23</NavLink>
            </li>
            <li>
              <NavLink activeClassName="circle" to="/pre23">PRE23</NavLink>
            </li>
            <li>
              <NavLink activeClassName="circle" to="/ss22">SS22</NavLink>
            </li>
            <li>
              <NavLink activeClassName="circle" to="/aw21">AW21</NavLink>
            </li>
          </div>
        </li>

      </ul>



    </div>
  )
}

export default TopMenu;