import React from "react";


const ConnectInfo = ({ label, email }) => {

    return (

        <div>
            {label}{" "}
            <a href={`mailto:${email}`} className="serif">
                {email}
            </a>
        </div>

    )
}

export default ConnectInfo;