const galleryImages = [
    {
        key: 1,
        srcJpeg: "../../content/photos/gallery/PRE23/01.jpg",
        srcWebp: "../../content/photos/gallery/PRE23/webp/01.webp",
        srcBlur: "../../content/photos/gallery/PRE23/blur/01.jpg",
        loading: "eager"

    },
    {
        key: 2,
        srcJpeg: "../../content/photos/gallery/PRE23/02.jpg",
        srcWebp: "../../content/photos/gallery/PRE23/webp/02.webp",
        srcBlur: "../../content/photos/gallery/PRE23/blur/02.jpg",
        loading: "eager"

    },
    {
        key: 3,
        srcJpeg: "../../content/photos/gallery/PRE23/03.jpg",
        srcWebp: "../../content/photos/gallery/PRE23/webp/03.webp",
        srcBlur: "../../content/photos/gallery/PRE23/blur/03.jpg",
        loading: "eager"

    }, {
        key: 4,
        srcJpeg: "../../content/photos/gallery/PRE23/04.jpg",
        srcWebp: "../../content/photos/gallery/PRE23/webp/04.webp",
        srcBlur: "../../content/photos/gallery/PRE23/blur/04.jpg",
        loading: "eager"

    },
    {
        key: 5,
        srcJpeg: "../../content/photos/gallery/PRE23/05.jpg",
        srcWebp: "../../content/photos/gallery/PRE23/webp/05.webp",
        srcBlur: "../../content/photos/gallery/PRE23/blur/05.jpg",
        loading: "lazy"


    },
    {
        key: 6,
        srcJpeg: "../../content/photos/gallery/PRE23/06.jpg",
        srcWebp: "../../content/photos/gallery/PRE23/webp/06.webp",
        srcBlur: "../../content/photos/gallery/PRE23/blur/06.jpg",
        loading: "lazy"


    },
    {
        key: 7,
        srcJpeg: "../../content/photos/gallery/PRE23/07.jpg",
        srcWebp: "../../content/photos/gallery/PRE23/webp/07.webp",
        srcBlur: "../../content/photos/gallery/PRE23/blur/07.jpg",
        loading: "lazy"


    },
    {
        key: 8,
        srcJpeg: "../../content/photos/gallery/PRE23/08.jpg",
        srcWebp: "../../content/photos/gallery/PRE23/webp/08.webp",
        srcBlur: "../../content/photos/gallery/PRE23/blur/08.jpg",
        loading: "lazy"


    },
    {
        key: 9,
        srcJpeg: "../../content/photos/gallery/PRE23/09.jpg",
        srcWebp: "../../content/photos/gallery/PRE23/webp/09.webp",
        srcBlur: "../../content/photos/gallery/PRE23/blur/09.jpg",
        loading: "lazy"


    }
];



export default galleryImages;


