
export function sliderLoader() {

    const allSlides = document.querySelectorAll(".swiper img");

    console.log(allSlides);
    const loader = document.querySelector(".loader");

    let totalImages = allSlides.length;
    let imagesLoaded = 0;


    function onImageLoad() {
        imagesLoaded++;
        //console.log(imagesLoaded);
        if (imagesLoaded === totalImages) {
            loader.classList.add("hidden");
            console.log("all images loaded");
        }
    }

    for (let i = 0; i < allSlides.length; i++) {
        allSlides[i].addEventListener('load', onImageLoad);
    }

}

