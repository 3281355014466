import React from "react";
import { clothingDetailsOpening } from "../js/clothingDetailsOpening.js";

const DetailsClothingPopUp = (props) => {

    return <>

        <div id="detailsClothingButton" onClick={clothingDetailsOpening} style={{ display: props.buttonShouldHide ? 'none' : 'block' }}>
            <p>Details</p>
        </div>

        <div id="detailsClothingContainer">
            <p className="close" onClick={clothingDetailsOpening}>X</p>
            <p className="detailsClothing"
            >{props.textcontent}</p>
        </div>

        <div onClick={clothingDetailsOpening} className="backdropblur"></div>

    </>

}


export default DetailsClothingPopUp;